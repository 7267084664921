
import api from './api';

const route = '/customer_notification_types';

export default {
  getCustomerNotificationTypes: () => api.get(route),
  getSingleCustomerNotificationType: (CustomerNotificationType_id) => api.get(`${route}/${CustomerNotificationType_id}`),
  postCustomerNotificationType: (customer_notification_type) => api.post(route, { ...customer_notification_type}),
  putCustomerNotificationType: (customer_notification_type) => api.put(route, { ...customer_notification_type}),
  deleteCustomerNotificationType: (customer_notification_type) => api.delete(`${route}/${customer_notification_type}`),
};
