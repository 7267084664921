import React from "react";
import ProgressBar from "../common/ProgressBar";
import useWorkoutPlanner from "../../hooks/useWorkoutPlanner";

const PlannerProgressBar = () => {
  const { progress } = useWorkoutPlanner();

  return (
    <div className="px-0 mx-0">
      {progress !== null && (
        <div className="row mb-3 px-0 align-items-center position-relative">
  
          <div className="col-12 ">
            <ProgressBar progress={progress} color={"secondary"} />
          </div>
        </div>
      )}
    </div>
  );
};
export default PlannerProgressBar;
