import { RESERVATIONS_RECIBIDAS } from "../types";
import { SET_MAX } from "../types/posts";

const ReservationsReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_MAX:
      return { ...state, max: payload };
    case RESERVATIONS_RECIBIDAS:
      return { ...state, reservations: payload };
    default:
      return { ...state };
  }
};

export default ReservationsReducer;
