import React, { useContext } from "react";
import { TracksContext } from "../../context/TracksContext";
import { ModalContext } from "../../context/ModalContext";
import TextareaInput from "../common/TextAreaInput";
import ImageInput from "../common/ImageInput";
import { getValue } from "../../utils";

const TrackForm = ({ handleCancel }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { track, spinner, saveTrack, setPropertyTrack } =
    useContext(TracksContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveTrack(track);
  };

  const handleInstructions = () => {
    modalComponent(
      "¿Cómo Medirme?",
      <div>
        <img
          src="/img/como-medirte.png"
          className="mw-100 w-100 d-block m-auto"
        />
        <button className="btn btn-primary w-100" onClick={clearModal}>
          Entendido
        </button>
      </div>,
      {
        size: "lg",
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-xl-6">
          <label>Fecha</label>
          <input
            type="date"
            value={getValue(track, "date")}
            className="form-control mb-3"
            onChange={(e) => setPropertyTrack("date", e.target.value)}
          />
          <div className="row">
            <div className="col-6">
              <label>Peso (kg)</label>
              <input
                type="number"
                value={getValue(track, "weight")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("weight", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>IMC</label>
              <input
                type="number"
                value={getValue(track, "imc")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("imc", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Grasa Corporal (%)</label>
              <input
                type="number"
                value={getValue(track, "fat")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("fat", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Masa Muscular (%)</label>
              <input
                type="number"
                value={getValue(track, "muscle")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("muscle", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="row align-items-center mb-3">
            <div className="col-6">
              <label className="bold">Circunferencias</label>
            </div>
            <div className="col-6 text-end">
              <button
                type="button"
                onClick={handleInstructions}
                className="btn btn-sm py-0 px-0 btn-link text-dark"
              >
                ¿Cómo medirme?
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md-4">
              <label>Pecho (cm)</label>
              <input
                type="number"
                value={getValue(track, "chest")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("chest", e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4">
              <label>Cintura (cm)</label>
              <input
                type="number"
                value={getValue(track, "waist")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("waist", e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4">
              <label>Abdomen (cm)</label>
              <input
                type="number"
                value={getValue(track, "abs")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("abs", e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4">
              <label>Cadera (cm)</label>
              <input
                type="number"
                value={getValue(track, "hips")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("hips", e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4">
              <label>Brazo (cm)</label>
              <input
                type="number"
                value={getValue(track, "arms")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("arms", e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4">
              <label>Muslo (cm)</label>
              <input
                type="number"
                value={getValue(track, "thigh")}
                className="form-control mb-3"
                onChange={(e) => setPropertyTrack("thigh", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="mb-3">
            <TextareaInput
              rows="4"
              valid={true}
              handleChange={(value) => setPropertyTrack("comment", value)}
              value={getValue(track, "comment")}
              label="¿Cómo te sentiste?"
              placeholder="Escribe a detalle, solo tú lo verás"
            />
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <label className="bold me-2 d-inline-block">Fotos</label>
          <span className="form-text">(solo tú las puedes ver)</span>
          <ImageInput
            multiple
            modifier={(files) => setPropertyTrack("files", files)}
          />
        </div>
      </div>
      <div className="show-mobile">
        <div className="row mt-4">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              disabled={spinner}
              className="btn w-100 btn-primary"
            >
              {spinner ? <div className="spinner-border" /> : "Guardar"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TrackForm;
