import React from "react";
import PlannerPregoTips from "./PlannerPregoTips";
import PlannerNutritionTips from "./PlannerNutritionTips";

const PlannerTips = ({ workout_plan }) => {
  const renderTips = () => {
    if (workout_plan && workout_plan !== null) {
      if (workout_plan.pregnant) {
        return <PlannerPregoTips workout_plan={workout_plan} />;
      }
      return <PlannerNutritionTips workout_plan={workout_plan} />;
    }
  };

  return <div>{renderTips()}</div>;
};

export default PlannerTips;
