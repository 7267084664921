import React from "react";
import moment from "moment";

const ScheduleMonthSelect = ({ start_date, handlePrev, handleNext }) => {
  const renderPrev = () => {
    return (
      <button className="btn btn-light border" onClick={handlePrev}>
        <i className="fa fa-chevron-left"></i>
      </button>
    );
  };

  return (
    <div className="row align-items-center">
      <div className="col-3 text-left">{renderPrev()}</div>
      <div className="col-6 text-center">
        <h2 className="mb-0">
          {moment(start_date).format("MMM")}{" "}
          <span className="small">{moment(start_date).format("YYYY")}</span>
        </h2>
      </div>
      <div className="col-3 text-right">
        <button className="btn btn-light border" onClick={handleNext}>
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default ScheduleMonthSelect;
