import moment from "moment";
import React from "react";

const PlannerRestart = ({ handleCancel, handleRestart }) => {
  return (
    <div>
      <p>Lo sentimos, no puedes completar días anteriores.</p>
      <p className="bold">¿Deseas reiniciar tu Workout Planner a hoy?</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            No, continuar así
          </button>
        </div>
        <div className="col-6">
          <button onClick={handleRestart} className="btn w-100 btn-primary">
            Sí, reiniciar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlannerRestart;
