import React, { useContext, useEffect } from "react";
import WaitlistsTable from "../components/waitlist/WaitlistsTable";
import WaitlistsList from "../components/waitlist/WaitlistList";
import { WaitlistsContext } from "../context/WaitlistsContext";
import { ModalContext } from "../context/ModalContext";
import Waitlist from "../components/clases/Waitlist";
import useBranch from "../hooks/useBranch";
import { Link } from "@reach/router";

const Waitlists = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { waitlists, getWaitlists } = useContext(WaitlistsContext);

  const { branch, branch_id } = useBranch();

  useEffect(() => {
    getWaitlists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, branch_id]);

  const confirmCancel = (single_class) => {
    modalComponent(
      "Lista de Espera",
      <Waitlist
        single_class_id={single_class.single_class_id}
        handleCancel={clearModal}
      />
    );
  };

  const displayInfo = () => {
    modalComponent(
      "Lista de Espera",
      <div>
        <h4>¿Cómo funciona la lista de espera?</h4>
        <p>
          Si algún cliente cancela, recibirás un mensaje por WhatsApp para tomar
          su lugar.
        </p>
        <p>
          Todos los clientes en lista de espera reciben el mensaje al mismo
          tiempo, el primero en reservar se quedará con el lugar.
        </p>
        <button onClick={clearModal} className="btn btn-primary w-100">OK</button>
      </div>
    );
  };

  const renderWaitlists = () => {
    if (Array.isArray(waitlists)) {
      if (waitlists.length === 0)
        return <p>No te has anotado a ninguna lista de espera.</p>;
      return (
        <div className="mb-2">
          <WaitlistsTable confirmCancel={confirmCancel} waitlists={waitlists} />
          <WaitlistsList confirmCancel={confirmCancel} waitlists={waitlists} />
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-4 mx-md-3 pe-md-5">
      <div className="row align-items-center my-3">
        <div className="col-12 col-md-8">
          <h1 className="mb-0">Mis Listas de Espera</h1>
        </div>
        <div className="col-12 col-md-4 text-end">
          <button
            onClick={displayInfo}
            className="btn btn-outline-primary me-2"
          >
            <i className="fa fa-question" />
          </button>
          <Link to="/mytbm/presencial" className="btn btn-primary">
            Calendario
          </Link>
        </div>
      </div>
      {renderWaitlists()}
    </div>
  );
};

export default Waitlists;
