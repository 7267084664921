import React from "react";
import PurchaseRow from "./PurchaseRow";

const PurchasesTable = ({ purchases, confirmCancel }) => {
  const renderOrdenes = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return purchases.map((purchase) => (
        <PurchaseRow
          key={purchase.purchase_id}
          purchase={purchase}
          cancelSubscription={confirmCancel}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table table-hover br-15">
        <thead className="bg-light bold mx-0 py-2">
          <tr>
            <td className="bold">Folio</td>
            <td className="bold">Paquete</td>
            <td className="bold">Total</td>
            <td className="bold">Fecha</td>
            <td className="bold">Estado</td>
            <td className="bold">Sig. Cargo</td>
            <td className="bold">Acciones</td>
          </tr>
        </thead>
        <tbody>{renderOrdenes()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
