import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: () => api.get(route),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  cancelSubscription: (purchase_id, cancel_reason) =>
    api.post(`${route}/cancel`, {
      purchase_id,
      cancel_reason,
    }),
  redeemCancelOffer: (purchase_id, cancel_reason) =>
    api.post(`${route}/cancel-offer`, {
      purchase_id,
      cancel_reason,
    }),
};

export default PurchasesService;
