import {
  SET_CUSTOMERGOAL,
  CREATE_CUSTOMERGOAL,
  CUSTOMERGOALS_RECEIVED,
  SET_PROPERTY_CUSTOMERGOAL,
} from "../types/customer_goals";

const schema = {};

const customerGoalsReducer = (state, { type, payload }) => {
  switch (type) {
    case CUSTOMERGOALS_RECEIVED:
      return { ...state, customer_goals: payload };
    case SET_CUSTOMERGOAL:
      return { ...state, customer_goal: payload };
    case CREATE_CUSTOMERGOAL:
      return { ...state, customer_goal: schema };
    case SET_PROPERTY_CUSTOMERGOAL: {
      const { key, value } = payload;
      const customer_goal = { ...state.customer_goal };
      customer_goal[key] = value;
      return { ...state, customer_goal };
    }
    default:
      return { ...state };
  }
};

export default customerGoalsReducer;
