import React, { useContext, useEffect } from "react";
import { TracksContext } from "../context/TracksContext";
import TrackForm from "../components/track/TrackForm";
import { navigate } from "@reach/router";
import { ModalContext } from "../context/ModalContext";

const EditProgreso = ({ track_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    track,
    spinner,
    saveTrack,
    createTrack,
    deleteTrack,
    getSingleTrack,
  } = useContext(TracksContext);

  useEffect(() => {
    if (isNaN(parseInt(track_id))) {
      createTrack();
    } else {
      getSingleTrack(track_id);
    }
  }, [track_id]);

  const handleSave = () => {
    saveTrack(track);
  };

  const handleCancel = () => {
    navigate("/mytbm/progreso");
  };

  const handleDelete = () => {
    deleteTrack(track_id, () => navigate("/mytbm/tracks"));
  };

  const confirmDelete = () => {
    modalComponent(
      "ELiminar Entrada",
      <div>
        <p>
          ¿Estás segura que deseas eliminar esta entrada? Esta acción NO puede
          deshacerse
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button onClick={handleDelete} className="btn w-100 btn-danger">
              <i className="fa fa-trash me-1" /> Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderDelete = () => {
    if (!isNaN(parseInt(track_id))) {
      return (
        <button onClick={confirmDelete} className="btn btn-outline-danger mt-4">
          <i className="fa fa-trash me-2" />
          Eliminar
        </button>
      );
    }
  };

  return (
    <div className="container-fluid px-4">
      <div className="row align-items-center">
        <div className="col-12 col-md-9">
          <h1 className="mb-0">Entrada</h1>
        </div>
        <div className="col-12 col-md-3">
          <div className="hide-mobile">
            <div className="row">
              <div className="col-6">
                <button
                  onClick={handleCancel}
                  className="btn w-100 btn-outline-secondary"
                >
                  Cancelar
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={handleSave}
                  disabled={spinner}
                  className="btn w-100 btn-primary"
                >
                  {spinner ? <div className="spinner-border" /> : "Guardar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card no-scale shadow p-3">
        <TrackForm />
      </div>
      {renderDelete()}
    </div>
  );
};

export default EditProgreso;
