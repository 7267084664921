import React from "react";
import WaitlistsCard from "./WaitlistCard";

const WaitlistsList = ({ waitlists, confirmCancel }) => {
  const renderWaitlists = () => {
    if (Array.isArray(waitlists)) {
      return waitlists.map((waitlist) => (
        <WaitlistsCard
          waitlist={waitlist}
          key={waitlist.waitlist_id}
          handleCancel={confirmCancel}
        />
      ));
    }
  };
  return <div className="show-mobile">{renderWaitlists()}</div>;
};

export default WaitlistsList;
