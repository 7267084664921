import React, { useState } from "react";

const AppLink = ({
  src,
  href,
  mobile,
  changeBaseUrl,
  title,
  logo,
  handleClick,
}) => {
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const renderOverlay = () => {
    if (displayOverlay) {
      return <div className="app-link-overlay"></div>;
    }
  };

  return (
    <div
      className="col-12 col-md-3 px-0 app-link "
      onClick={() => {
        if (typeof handleClick === "function") handleClick();

        if (changeBaseUrl) {
          window.open(href);
        } else {
          window.open(href, "_self");
        }
      }}
      onMouseEnter={() => setDisplayOverlay(true)}
      onMouseLeave={() => setDisplayOverlay(false)}
    >
      <div
        className="w-100 h-100 home-img"
        style={{
          backgroundImage: `url(${
            window.innerWidth < 767 && mobile && mobile !== null ? mobile : src
          })`,
        }}
      >
        {title && <h1 className="app-link-title">{title}</h1>}
        {logo && <img src={logo} className="app-link-logo" alt="logo" />}
        {renderOverlay()}
      </div>
    </div>
  );
};

export default AppLink;
