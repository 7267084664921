import React from "react";
import InvoiceRow from "./InvoiceRow";

const InvoicesTable = ({ invoices }) => {
  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return invoices.map((purchase) => (
        <InvoiceRow key={purchase.invoice_id} invoice={purchase} />
      ));
    }
  };
  return (
    <td className="table-responsive">
      <table className="table">
        <thead className="bg-light p-2 border bold border-0">
          <tr>
            <td>Folio</td>
            <td>Compra</td>
            <td>Paquete</td>
            <td>Total</td>
            <td>Fecha</td>
            <td>Próximo</td>
            <td>Estado</td>
          </tr>
        </thead>
        <tbody>{renderInvoices()}</tbody>
      </table>
    </td>
  );
};

export default InvoicesTable;
