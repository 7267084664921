
import api from './api';

const route = '/waitlist';

const WaitlistService = {
  getWaitlists: () => api.get(route),
  getSingleClassWaitlist: (single_class_id) => api.get(`${route}/${single_class_id}`),
  postWaitlist: (waitlist) => api.post(route, { ...waitlist}),
  putWaitlist: (waitlist) => api.put(route, { ...waitlist}),
  deleteWaitlist: (Waitlist) => api.delete(`${route}/${Waitlist}`),
};

export default WaitlistService;