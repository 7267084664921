
import React, { createContext, useReducer, useContext } from 'react';
import TestimonialsService from '../services/TestimonialsService';
import TestimonialsReducer from '../reducers/TestimonialsReducer';
import {
  TESTIMONIALS_RECEIVED,
  SET_TESTIMONIAL,
  CREATE_TESTIMONIAL,
  SET_PROPERTY_TESTIMONIAL,
} from "../types/testimonials";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  testimonials: null,
  testimonial: null,
};

export const TestimonialsContext = createContext(initialState);

export const TestimonialsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TestimonialsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getTestimonials = () => {
    TestimonialsService.getTestimonials()
      .then((response) => {
        const { testimonials } = response.data;
        dispatch({ type: TESTIMONIALS_RECEIVED, payload: testimonials });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleTestimonial = (testimonial_id) => {
    TestimonialsService.getSingleTestimonial(testimonial_id)
      .then((response) => {
        const { testimonial } = response.data;
        dispatch({ type: SET_TESTIMONIAL, payload: testimonial });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setTestimonial = (testimonial) => {
    dispatch({ type: SET_TESTIMONIAL, payload: testimonial });
  };

  const createTestimonial = () => {
    dispatch({ type: CREATE_TESTIMONIAL });
  };

  const setPropertyTestimonial = (key, value) => {
    dispatch({ type: SET_PROPERTY_TESTIMONIAL, payload: { key, value } });
  };

  const saveTestimonial = (testimonial, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = TestimonialsService.putTestimonial;
      if(isNaN(parseInt(testimonial.testimonial_id))) {
        service = TestimonialsService.postTestimonial;
      }
      service(testimonial).then(() => {
        success("Testimonial saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteTestimonial = (testimonial_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    TestimonialsService.deleteTestimonial(testimonial_id).then(() => {
      success("Testimonial deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <TestimonialsContext.Provider
    value={{
      ...state,
      setTestimonial,
      getTestimonials,
      saveTestimonial,
      deleteTestimonial,
      createTestimonial,
      getSingleTestimonial,
      setPropertyTestimonial,

    }}
  >
    {children}
  </TestimonialsContext.Provider>
);
};
