import React, { useContext, useEffect, useState } from "react";
import { VideosContext } from "../../context/VideosContext";
import VideoTeaser from "./VideoTeaser";
import { ModalContext } from "../../context/ModalContext";
import moment from "moment";
import SingleEstreno from "./SingleEstreno";
import EstrenoCard from "./EstrenoCard";
import CustomerUpgrade from "../customers/CustomerUpgrade";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "@reach/router";
import { AppConfigContext } from "../../context/AppConfigContext";

const Estrenos = () => {
  const [firstVideo, setFirstVideo] = useState(null);
  const { user, getUsuario } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { favoritos, getVideos, videos } = useContext(VideosContext);
  const { online_popup_src } = useContext(AppConfigContext);

  useEffect(() => {
    getVideos({ program_id: "estrenos" });
    getUsuario();
  }, []);

  useEffect(() => {
    if (Array.isArray(videos)) {
      const currentFirst = getFirstVideo();
      setFirstVideo(currentFirst);
    }
  }, [videos]);

  useEffect(() => {
    if (firstVideo !== null) {
      handlePremierePopUp();
    }
  }, [firstVideo]);

  useEffect(() => {
    if (!user.has_access) {
      modalComponent(
        "Suscríbete a TBM",
        <CustomerUpgrade feature="Clases Online" />
      );
    }
  }, [user]);

  const getFirstVideo = () => {
    let videosRender = [...videos];
    videosRender = videosRender.filter((video) =>
      moment(video.available_at).isSameOrBefore(moment().startOf("day"))
    );
    videosRender = videosRender.sort((a, b) =>
      moment(a.available_at).isBefore(b.available_at) ? 1 : -1
    );
    return videosRender[0];
  };

  const handlePremierePopUp = () => {
    const currentDate = moment().startOf("day").format("YYYY-MM-DD");
    const firstVideoDate = moment(firstVideo?.available_at)
      .startOf("day")
      .format("YYYY-MM-DD");
    if (firstVideoDate === currentDate) {
      let lastPremiere = window.localStorage.getItem("premiere");
      if (lastPremiere !== currentDate) {
        window.localStorage.setItem("premiere", currentDate);
        return modalComponent(
          "Nuevo Estreno",
          <VideoTeaser video={firstVideo} hideInfo />
        );
      }
    }
    return handleCustomPopUp();
  };

  const handleCustomPopUp = () => {
    if(online_popup_src && online_popup_src !== null) {
      const currentDate = moment().startOf("day").format("YYYY-MM-DD");
      const lastPopup = window.localStorage.getItem("popup");
      if (lastPopup !== currentDate) {
        window.localStorage.setItem("popup", currentDate);
        return modalComponent(
          "",
          <Link to="/mytbm/comunidad" onClick={clearModal}>
            <img
              src={online_popup_src}
              className="mw-100 w-100 d-block m-auto"
              alt="Interactúa en la comunidad"
            />
          </Link>,
          {
            no_padding: true,
            size: "xl",
          }
        );
      }
    }
    
  };

  const renderLatest = () => {
    if (favoritos && favoritos !== null) {
      const video = favoritos[0];
      if (video) {
        return <SingleEstreno video={video} />;
      }
    }
    if (Array.isArray(videos)) {
      if (firstVideo && firstVideo !== null) {
        return <SingleEstreno video={firstVideo} />;
      }
    }
  };

  const renderUpcoming = () => {
    if (Array.isArray(videos)) {
      let videosRender = [...videos];
      videosRender = videosRender.sort((a, b) =>
        moment(a.available_at).isAfter(b.available_at) ? -1 : 1
      );
      if (firstVideo && firstVideo !== null) {
        const index = videosRender.findIndex(
          (video) => video.video_id === firstVideo.video_id
        );
        if (index !== -1) videosRender.splice(index, 1);
      } else {
        videosRender = videosRender.slice(1);
      }
      videosRender = videosRender.filter((video) =>
        moment(video.available_at).isAfter(moment().startOf("day"))
      );
      return (
        <div
          className="row align-items-center estrenos-row"
          style={{
            width: `${videosRender.length * 310}px`,
          }}
        >
          {videosRender.map((video) => (
            <EstrenoCard key={video.video_id} video={video} />
          ))}
        </div>
      );
    }
  };

  return (
    <div id="estrenos">
      <div className="row bg-gray h-100 align-items-center">
        <div className="col-12 col-md-6 bg-gradient" style={{ minHeight: 400 }}>
          {renderLatest()}
        </div>
        <div className="col-12 col-md-6 text-primary bg-gray">
          <div className="px-4 py-3">
            <h3 className="my-1 font-poppins mt-3 mb-1 text-sentence">
              Próximos estrenos
            </h3>
            <p className="small text-muted">
              Los estrenos pueden cambiar sin previo aviso
            </p>
            <div className="estrenos-wrapper mb-2">{renderUpcoming()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Estrenos;
