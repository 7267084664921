import {
  SET_WORKOUTPLAN,
  CREATE_WORKOUTPLAN,
  WORKOUTPLANS_RECEIVED,
  SET_PROPERTY_WORKOUTPLAN,
} from "../types/workout_plans";

const schema = {};

const workout_plansReducer = (state, { type, payload }) => {
  switch (type) {
    case WORKOUTPLANS_RECEIVED:
      return { ...state, workout_plans: payload };
    case SET_WORKOUTPLAN:
      return { ...state, workout_plan: payload };
    case CREATE_WORKOUTPLAN:
      return { ...state, workout_plan: schema };
    case SET_PROPERTY_WORKOUTPLAN: {
      const { key, value } = payload;
      const workout_plan = { ...state.workout_plan };
      workout_plan[key] = value;
      return { ...state, workout_plan };
    }
    default:
      return { ...state };
  }
};

export default workout_plansReducer;
