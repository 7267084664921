import React, { useContext, useEffect } from "react";
import PurchasesTable from "../components/purchases/PurchasesTable";
import { PurchasesContext } from "../context/PurchasesContext";
import PurchasesList from "../components/purchases/PurchasesList";
import { ModalContext } from "../context/ModalContext";
import CancelNotice from "../components/purchases/CancelNotice";
import { navigate } from "@reach/router";

const Purchases = () => {
  const { purchases, getPurchases } = useContext(PurchasesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelNotice
        purchase={purchase}
        handleCancel={clearModal}
        handleContinue={(purchase) => {
          navigate(`/mytbm/cancel/${purchase.purchase_id}`);
          clearModal();
        }}
      />
    );
  };

  return (
    <div className="container-fluid px-4 mx-md-3 pe-md-5">
      <h2 className="my-2">Mis Compras</h2>
      <div className="card border-0 p-0 no-scale shadow hide-mobile">
        <PurchasesTable purchases={purchases} confirmCancel={confirmCancel} />
      </div>
      <div className="show-mobile">
        <PurchasesList purchases={purchases} confirmCancel={confirmCancel} />
      </div>
    </div>
  );
};

export default Purchases;
