import React, { useContext, useEffect } from "react";
import "./MonterreyLandingPackages.css";
import { PackagesContext } from "../../context/PackageContext";
import MonterreyPackagesCarousel from "../paquetes/MonterreyPackagesCarousel";

const MonterreyLandingPackages = () => {
  const { paquetes, getPaquetes } = useContext(PackagesContext);

  useEffect(() => {
    getPaquetes();
  }, []);

  return (
    <div className="container-fluid py-5 bg-white">
      <h2 className="h1 text-center text-black packages-carousel__title">ELIGE TU MEMBRESIA</h2>
      <p className="text-black text-center packages-carousel__subtitle">
        Todos nuestros paquetes presenciales incluyen TBM ONLINE de forma
        ilimitada.
      </p>

      <div className="container-fluid packages-carousel__container">
        <MonterreyPackagesCarousel packages={paquetes?.filter(({ class_amount}) => class_amount > 0)} />
      </div>

    </div>
  );
};

export default MonterreyLandingPackages;
