import React, { useContext, useEffect } from "react";
import MetodoCard from "../components/payment_sources/MetodoCard";
import { ModalContext } from "../context/ModalContext";
import { PaymentSourcesContext } from "../context/PaymentSourcesContext";
import { hideModal } from "../utils";
import StripeForm from "../components/payment_sources/StripeForm";

const MisMetodos = () => {
  const { modalComponent } = useContext(ModalContext);
  const { 
    getPaymentSources, 
    payment_sources,
    deletePaymentSource 
  } = useContext(PaymentSourcesContext);

  useEffect(() => {
    getPaymentSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteMetodo = (metodo) => {
    modalComponent(
      "Eliminar Método de Pago",
      <div className="container-fluid">
        <p>
          ¿Estás seguro que quieres eliminar el método de pago ****{" "}
          {metodo.last_digits}?
        </p>
        <p>Esta acción NO puede deshacerse</p>
        <div className="row">
          <div className="col col-md-6">
            <button
              className="btn btn-primary"
              onClick={() => deletePaymentSource(metodo.payment_source_id)}
            >
              Eliminar
            </button>
          </div>
          <div className="col col-md-6 text-end">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderMetodosPago = () => {
    if (payment_sources && payment_sources !== null) {
      if (payment_sources.length === 0) {
        return <p>No hay metodos de pago registrados</p>;
      }

      return payment_sources.map((source) => (
        <MetodoCard
          key={source.payment_source_id}
          metodo={source}
          deleteMetodo={deleteMetodo}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  const agregarMetodo = () => {
    modalComponent(
      "Agregar Método de Pago",
      <StripeForm/>
    );
  };

  return (
    <div className="container px-4 px-md-0 py-4">
     <div className="row mx-0 align-items-center mb-3 pb-3 border-bottom">
        <div className="col col-md-6 px-0">
          <h1 className="h2 bold mb-0">Mis Métodos de Pago</h1>
        </div>
        <div className="col col-md-6 px-0 text-end">
          <button className="btn btn-primary" onClick={agregarMetodo}>
            + Agregar
          </button>
        </div>
      </div>

      {renderMetodosPago()}
    </div>
  );
};

export default MisMetodos;
