import React from "react";

const StarsRating = ({ stars, setStars }) => {
  return (
    <div className="container-fluid px-0">
      {new Array(5).fill(1).map((one, index) => (
        <button
          key={`clase-${index + 1}`}
          type="button"
          onClick={() => setStars(index + 1)}
          className={`btn btn-sm d-inline-block ${
            stars > index ? "text-warning" : "text-dark"
          }`}
        >
          <i className="fa fa-star"></i>
        </button>
      ))}
    </div>
  );
};

export default StarsRating;
