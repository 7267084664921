import React, { createContext, useReducer, useContext } from "react";
import PostsService from "../services/PostsService";
import PostsReducer from "../reducers/PostsReducer";
import {
  POSTS_RECEIVED,
  SET_POST,
  CREATE_POST,
  SET_PROPERTY_POST,
  APPEND_POST,
  REMOVE_POST,
  SET_MAX,
  LIKE_POST,
  UNLIKE_POST,
} from "../types/posts";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  posts: null,
  post: null,
};

export const PostsContext = createContext(initialState);

export const PostsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PostsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    alert(error);
  };

  const getPosts = (filters) => {
    PostsService.getPosts(filters)
      .then((response) => {
        const { posts, max } = response.data;
        dispatch({ type: SET_MAX, payload: max });
        dispatch({ type: POSTS_RECEIVED, payload: posts });
      })
      .catch(handleError);
  };

  const getSinglePost = (post_id) => {
    PostsService.getSinglePost(post_id)
      .then((response) => {
        const { post } = response.data;
        dispatch({ type: SET_POST, payload: post });
      })
      .catch(handleError);
  };

  const setPost = (post) => {
    dispatch({ type: SET_POST, payload: post });
  };

  const createPost = () => {
    dispatch({ type: CREATE_POST });
  };

  const setPropertyPost = (key, value) => {
    dispatch({ type: SET_PROPERTY_POST, payload: { key, value } });
  };

  const appendPost = (message) => {
    dispatch({ type: APPEND_POST, payload: message });
  };

  const likePost = ({ post_id, customer_id }) => {
    dispatch({ type: LIKE_POST, payload: { post_id, customer_id } });
  };

  const unlikePost = ({ post_id, customer_id }) => {
    dispatch({ type: UNLIKE_POST, payload: { post_id, customer_id } });
  };

  const savePostLike = (post_id, customer_id) => {
    PostsService.likePost(post_id, customer_id);
  };

  const deletePostLike = (post_id, customer_id) => {
    PostsService.deletePostLike(post_id, customer_id);
  };

  const savePost = (post, callback) => {
    const promises = [];
    const file_ids = [];
    if (post.files !== null && post.files && post.files.length > 0) {
      for (let i = 0; i < post.files.length; i++) {
        promises.push(
          new Promise((resolve, reject) => {
            AdjuntosService.postAdjunto(
              AdjuntosService.getFormData(post.files[i])
            )
              .then((res) => {
                file_ids.push(res.data.file_id);
                resolve();
              })
              .catch(reject);
          })
        );
      }
    } else {
      dispatch({ type: SHOW_SPINNER });
    }
    Promise.all(promises).then(() => {
      post.files = file_ids;
      let service = PostsService.putPost;
      if (isNaN(parseInt(post.post_id))) {
        service = PostsService.postPost;
      }
      service(post)
        .then(() => {
          dispatch({ type: HIDE_SPINNER });
          clearModal();
          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(handleError);
    });
  };

  const removePost = (post_id) => {
    dispatch({ type: REMOVE_POST, payload: post_id });
  };

  const deletePost = (post_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    PostsService.deletePost(post_id)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(handleError);
  };

  const reportPost = (post_id, customer_id, comment) => {
    dispatch({ type: SHOW_SPINNER });
    PostsService.reportPost({ post_id, customer_id, comment })
      .then(() => {
        success("Comentario reportado");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
      })
      .catch(handleError);
  };

  return (
    <PostsContext.Provider
      value={{
        ...state,
        setPost,
        likePost,
        getPosts,
        savePost,
        deletePost,
        createPost,
        appendPost,
        reportPost,
        removePost,
        unlikePost,
        savePostLike,
        getSinglePost,
        deletePostLike,
        setPropertyPost,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};
