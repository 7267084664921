import { Link } from "@reach/router";
import React from "react";
import Page from "../../components/global/Page";
import { S3_ENDPOINT } from "../../utils";

const Reto = () => {
  return (
    <Page title="HOTTEST CHALLENGE Vol. 6">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div className="p-4">
            <img
              src={`${S3_ENDPOINT}/hottest_challenge_v6.jpeg`}
              className="mw-100 w-100"
              alt="Hottest Challenge"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card no-scale shadow p-5">
            <h2>RETO DE 28 DÍAS</h2>
            <h3>Inicia en cualquier momento</h3>
            <p>
              Consiste en 28 días SUPER INTENSOS de un plan de alimentación y de
              entrenamientos de acuerdo a TUS OBJETIVOS y JUNTAS lograremos la
              transformación que siempre has querido!
            </p>
            <h4>¿Qué Incluye?</h4>
            <ul>
              <li>
                Plan de alimentación de acuerdo a tus objetivos con recetas
                deliciosas y prácticas.
              </li>
              <li>
                Programa de entrenamientos de acuerdo a tus objetivos. (Perder
                peso, aumentar musculo o compactar y tonificar).
              </li>
              <li>Lista de súper.</li>
              <li>Marcas recomendadas.</li>
            </ul>
            <a href="#inscribete" className="btn btn-primary">
              Inscríbete
            </a>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <h4>PASOS A SEGUIR:</h4>
          <ol>
            <li>
              Realiza la compra del reto (asegúrate de incluir todos tus datos
              correctamente mail y celular)
            </li>

            <li>
              Ingresa a tu cuenta de TBM Online y navega a "Mis Mebresías".
            </li>
            <li>
              Haz click en el botón
              <button className="btn btn-outline-dark btn-sm mx-2">
                <i className="fa fa-file"></i>
              </button>
              de tu compra de Hottest Challenge Vol. 4
            </li>
            <li>
              Descarga el archivo adjunto tu Historial Clínico, que debes de
              llenar y enviarnos de regreso a thehottestchallenge@gmail.com (En
              base a los datos que nos proporciones crearemos tu plan de
              alimentación y tu calendario de workouts)
            </li>
            <li>
              Enviaremos a tu mail todo lo que necesitas para prepararte e
              iniciar el programa, además de que tendrás atención personalizada
              vía WhatsApp.
            </li>
          </ol>
        </div>
        <div className="row my-4" id="inscribete">
          <div className="col-12 col-md-6 text-center">
            <Link
              to="/checkout/19273"
              className="btn btn-primary w-100 p-4 my-2"
            >
              $1,850 Hottest Challenge Presencial (16 clases + TBM Online)
            </Link>
          </div>
          <div className="col-12 col-md-6 text-center">
            <Link
              to="/checkout/19272"
              className="btn btn-primary w-100 p-4 my-2"
            >
              $850 Hottest Challenge Online (Solo TBM Online)
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Reto;
