
import React, { createContext, useReducer, useContext } from 'react';
import TagsService from '../services/TagsService';
import TagsReducer from '../reducers/TagsReducer';
import {
  TAGS_RECEIVED,
  SET_TAG,
  CREATE_TAG,
  SET_PROPERTY_TAG,
} from "../types/tags";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  tags: null,
  tag: null,
};

export const TagsContext = createContext(initialState);

export const TagsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TagsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getTags = () => {
    TagsService.getTags()
      .then((response) => {
        const { tags } = response.data;
        dispatch({ type: TAGS_RECEIVED, payload: tags });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleTag = (tag_id) => {
    TagsService.getSingleTag(tag_id)
      .then((response) => {
        const { tag } = response.data;
        dispatch({ type: SET_TAG, payload: tag });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setTag = (tag) => {
    dispatch({ type: SET_TAG, payload: tag });
  };

  const createTag = () => {
    dispatch({ type: CREATE_TAG });
  };

  const setPropertyTag = (key, value) => {
    dispatch({ type: SET_PROPERTY_TAG, payload: { key, value } });
  };

  const saveTag = (tag, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = TagsService.putTag;
      if(isNaN(parseInt(tag.tag_id))) {
        service = TagsService.postTag;
      }
      service(tag).then(() => {
        success("Tag saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteTag = (tag_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    TagsService.deleteTag(tag_id).then(() => {
      success("Tag deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <TagsContext.Provider
    value={{
      ...state,
      setTag,
      getTags,
      saveTag,
      deleteTag,
      createTag,
      getSingleTag,
      setPropertyTag,

    }}
  >
    {children}
  </TagsContext.Provider>
);
};
