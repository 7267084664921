export const isCustomerInfoComplete = (customer) => {
  const completedAddress =
    customer.address.city !== null && customer.address.country !== null;
  const completeInfo =
    customer.birthdate !== null &&
    customer.last_name !== null &&
    customer.phone !== null &&
    customer.signup_reason !== null;
  return completedAddress && completeInfo;
};
