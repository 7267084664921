
import {
  SET_PLAYBACK,
  CREATE_PLAYBACK,
  PLAYBACKS_RECEIVED,
  SET_PROPERTY_PLAYBACK,
} from "../types/playbacks";

const schema = {

}

const playbacksReducer = (state, { type, payload }) => {
  switch (type) {
    case PLAYBACKS_RECEIVED:
      return { ...state, playbacks: payload };
    case SET_PLAYBACK:
      return { ...state, playback: payload };
    case CREATE_PLAYBACK:
      return { ...state, playback: schema };
    case SET_PROPERTY_PLAYBACK: {
      const { key, value } = payload;
      const playback = { ...state.playback };
      playback[key] = value;
      return { ...state, playback };
    }
    default:
      return { ...state};
  }
};

export default playbacksReducer;
