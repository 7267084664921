import React, { useState, useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import CancelForm from "../components/purchases/CancelForm";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import { navigate } from "@reach/router";
import CancelOffer from "./CancelOffer";
import CancelBenefits from "../components/purchases/CancelBenefits";
import CancelWorkoutPlanner from "../components/purchases/CancelWorkoutPlanner";

const CancelView = ({ purchase_id }) => {
  const [reason, setReason] = useState("");
  const [detail, setDetail] = useState("");
  const [showOffer, setShowOffer] = useState(false);

  const { user } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  const { purchase, getPurchase, cancelSubscription } =
    useContext(PurchasesContext);

  useEffect(() => {
    if(user !== null) {
      getPurchase(purchase_id);
    }
  }, [user, purchase_id]);

  useEffect(() => {
    if (purchase && purchase !== null) {
      if (purchase.status !== "active") {
        alert(
          "No puedes cancelar esta compra, no tiene un cargo recurrente activo."
        );
        navigate("/mytbm/membresias");
      }
    }
  }, [purchase]);

  const validReason =
    String(reason).length > 10 ||
    (reason === "otro" && String(detail).length > 10);

  const handleSubmit = () => {
    if (reason === "otro") {
      if (detail === "" || String(detail).length < 10) {
        return alert("Debes indicar un motivo de cancelación.");
      }
    } else if (reason === "" || String(reason).length < 10) {
      return alert("Debes indicar un motivo de cancelación.");
    }
    if ([19271, 149].includes(purchase.class_package_id)) {
      if (user.cancel_attempt === null && !showOffer) {
        if (
          !String(reason).includes("embaraz") &&
          !String(reason).includes("lesión")
        ) {
          return setShowOffer(true);
        }
      }
    }
    cancelSubscription(
      purchase.purchase_id,
      reason === "otro" ? detail : reason
    );
  };

  const renderForm = () => {
    if (purchase && purchase !== null) {
      if (showOffer) {
        return (
          <CancelOffer
            reason={reason === "otro" ? detail : reason}
            purchase={purchase}
          />
        );
      }
      return (
        <CancelForm
          detail={detail}
          reason={reason}
          purchase={purchase}
          setReason={setReason}
          setDetail={setDetail}
          validReason={validReason}
          purchase_id={purchase_id}
          handleSubmit={handleSubmit}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-0">
      <div className="container px-0 shadow mt-5" style={{ maxWidth: 1000 }}>
        <CancelBenefits />
        <CancelWorkoutPlanner />
        <div className="bg-white p-5">
          <div style={{ maxWidth: 500 }} className="m-auto">
            {renderForm()}
          </div>
        </div>
        <div className="bg-light p-md-5 p-4">
          <h2 className="bold text-center">Información Importante</h2>
          <p className="text-primary bold">
            Lee detenidamente la información antes de continuar:
          </p>
          <p>
            Si estás en período de prueba gratuita perderás acceso a tus
            beneficios inmediatamente.
          </p>
          <p>
            De lo contrario, perderás acceso a tus beneficios cuando termine tu
            ciclo actual de facturación.
          </p>
          <p>
            Por ejemplo, si tu suscripción renueva mensualmente cada día 30,
            perderás los beneficios el día 30 del siguiente mes.
          </p>
          <p>
            No se hará ningún reembolso por el último cargo efectuado aún y
            cuando solo haya pasado un minuto de haberse realizado.
          </p>
          <p>
            Una vez que canceles tu suscripción, deberás volver a suscribirte si
            quieres volver a disfrutrar de los beneficios.
          </p>
          <p>
            Si en un futuro deseas volver a suscribirte, el precio de tu
            membresía podría aumentar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancelView;
