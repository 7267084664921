import React, { createContext, useReducer } from "react";
import PlaybacksService from "../services/PlaybacksService";
import PlaybacksReducer from "../reducers/PlaybacksReducer";
import {
  SET_PLAYBACK,
  CREATE_PLAYBACK,
  PLAYBACKS_RECEIVED,
  SET_PROPERTY_PLAYBACK,
} from "../types/playbacks";

const initialState = {
  playbacks: null,
  playback: null,
};

export const PlaybacksContext = createContext(initialState);

export const PlaybacksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PlaybacksReducer, initialState);

  const getPlaybacks = () => {
    PlaybacksService.getPlaybacks().then((response) => {
      const { playbacks } = response.data;
      dispatch({ type: PLAYBACKS_RECEIVED, payload: playbacks });
    });
  };

  const getPlaybacksByDate = (start_date, end_date) => {
    PlaybacksService.getPlaybacksByDate({ start_date, end_date }).then(
      (response) => {
        const { playbacks } = response.data;
        dispatch({ type: PLAYBACKS_RECEIVED, payload: playbacks });
      }
    );
  };

  const getSinglePlayback = (playback_id) => {
    PlaybacksService.getSinglePlayback(playback_id).then((response) => {
      const { playback } = response.data;
      dispatch({ type: SET_PLAYBACK, payload: playback });
    });
  };

  const setPlayback = (playback) => {
    dispatch({ type: SET_PLAYBACK, payload: playback });
  };

  const createPlayback = () => {
    dispatch({ type: CREATE_PLAYBACK });
  };

  const setPropertyPlayback = (key, value) => {
    dispatch({ type: SET_PROPERTY_PLAYBACK, payload: { key, value } });
  };

  const savePlayback = (playback) => {
    let service = PlaybacksService.putPlayback;
    if (isNaN(parseInt(playback.playback_id))) {
      service = PlaybacksService.postPlayback;
    }
    service(playback).then((res) => {
      playback = res.data.playback;
      dispatch({ type: SET_PLAYBACK, payload: playback });
    });
  };

  return (
    <PlaybacksContext.Provider
      value={{
        ...state,
        setPlayback,
        getPlaybacks,
        savePlayback,
        createPlayback,
        getSinglePlayback,
        getPlaybacksByDate,
        setPropertyPlayback,
      }}
    >
      {children}
    </PlaybacksContext.Provider>
  );
};
