
import React, { createContext, useReducer, useContext } from 'react';
import NotificationTypesService from '../services/NotificationTypesService';
import NotificationTypesReducer from '../reducers/NotificationTypesReducer';
import {
  NOTIFICATIONTYPES_RECEIVED,
  SET_NOTIFICATIONTYPE,
  CREATE_NOTIFICATIONTYPE,
  SET_PROPERTY_NOTIFICATIONTYPE,
} from "../types/notification_types";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  notification_types: null,
  notification_type: null,
};

export const NotificationTypesContext = createContext(initialState);

export const NotificationTypesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationTypesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getNotificationTypes = () => {
    NotificationTypesService.getNotificationTypes()
      .then((response) => {
        const { notification_types } = response.data;
        dispatch({ type: NOTIFICATIONTYPES_RECEIVED, payload: notification_types });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleNotificationType = (notificationType_id) => {
    NotificationTypesService.getSingleNotificationType(notificationType_id)
      .then((response) => {
        const { notification_type } = response.data;
        dispatch({ type: SET_NOTIFICATIONTYPE, payload: notification_type });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setNotificationType = (notification_type) => {
    dispatch({ type: SET_NOTIFICATIONTYPE, payload: notification_type });
  };

  const createNotificationType = () => {
    dispatch({ type: CREATE_NOTIFICATIONTYPE });
  };

  const setPropertyNotificationType = (key, value) => {
    dispatch({ type: SET_PROPERTY_NOTIFICATIONTYPE, payload: { key, value } });
  };

  const saveNotificationType = (notification_type, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = NotificationTypesService.putNotificationType;
      if(isNaN(parseInt(notification_type.notificationType_id))) {
        service = NotificationTypesService.postNotificationType;
      }
      service(notification_type).then(() => {
        success("NotificationType saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteNotificationType = (notificationType_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    NotificationTypesService.deleteNotificationType(notificationType_id).then(() => {
      success("NotificationType deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <NotificationTypesContext.Provider
    value={{
      ...state,
      setNotificationType,
      getNotificationTypes,
      saveNotificationType,
      deleteNotificationType,
      createNotificationType,
      getSingleNotificationType,
      setPropertyNotificationType,

    }}
  >
    {children}
  </NotificationTypesContext.Provider>
);
};
