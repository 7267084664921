import React, { useState, useContext } from "react";
import { VideosContext } from "../../context/VideosContext";
import { AuthContext } from "../../context/AuthContext";
import StarsRating from "../common/StarsRating";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";

const VideoRating = ({ video_id, handleCancel }) => {
  const [coachStars, setCoachStars] = useState(null);
  const [claseStars, setStars] = useState(null);
  const [comment, setComment] = useState(null);
  const [share, setShare] = useState(false);

  const { saveRating } = useContext(VideosContext);
  const { user } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(claseStars === null) {
      return alert('Debes agregar estrellas para la clase');
    }
    if(coachStars === null) {
      return alert('Debes agregar estrellas para la coach');
    }
    saveRating({
      customer_id: user.customer_id,
      class_stars: claseStars,
      coach_stars: coachStars,
      video_id,
      comment,
      share,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col-12 col-md-8">
          <label>Elige una calificación para esta clase</label>
        </div>
        <div className="col-12 col-md-4 text-md-end text-start">
          <StarsRating stars={claseStars} setStars={setStars} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-8">
          <label>Elige una calificación para la coach</label>
        </div>
        <div className="col-12 col-md-4 text-md-end text-start">
          <StarsRating stars={coachStars} setStars={setCoachStars} />
        </div>
      </div>
      <div className="my-3">
        <label className="mb-2 bold">Comentarios (opcional)</label>
        <textarea
          className="form-control mb-3"
          value={getValue({ comment }, "comment")}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="row mb-4">
        <div className="col-6">
          <label>Compartir este comentario con la comunidad</label>
        </div>
        <div className="col-6 text-end">
          <ReactSwitch checked={share} onChange={setShare} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            disabled={coachStars === null || claseStars === null}
            type="submit"
            className="btn w-100 btn-primary"
          >
            Calificar
          </button>
        </div>
      </div>
    </form>
  );
};

export default VideoRating;
