import React from "react";

const PaqueteFeatures = ({ features }) => {
  return (
    <div>
      <ul style={{ listStyleType: "none" }} className="ps-0 ms-0">
        {features.map((feature, index) => (
          <li key={index}>
            <i className="fa fa-check me-1 text-primary" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaqueteFeatures;
