import React from "react";
import useBranch from "../../hooks/useBranch";
import { Link, navigate } from "@reach/router";

const LandingQuote = () => {
  const { branch } = useBranch();

  const handleFreeTrial = () => {
    if(branch?.slug === "tijuana") {
      return navigate("/checkout/19310");
    }
    navigate("/checkout/19271");
  };

  return (
    <div
      id="quote"
      className="container-fluid text-center bg-dark-gray text-white py-5"
    >
      <p className="mb-0 h2 font-poppins text-normal">
        Únete a nuestra comunidad en donde todas estamos unidas por un mismo
        objetivo:
      </p>
      <h2 className="font-poppins bold text-blue h1 my-3">
        ALCANZAR NUESTRO MÁXIMO POTENCIAL!
      </h2>
      <div id="buttons" className="container my-5">
        <div className="row">
          <div className="col-12 col-md-6">
            <button
              onClick={handleFreeTrial}
              className="btn w-100 btn-secondary btn-lg br-10 py-3 mb-2"
            >
              Pruébalo 7 días GRATIS
            </button>
          </div>
          <div className="col-12 col-md-6">
            <Link
              to="/pages/workout-planner"
              className="btn w-100 btn-outline-light btn-lg br-10 py-3 mb-2"
            >
              Conoce tu plan ideal
            </Link>
          </div>
        </div>
      </div>
      <h5 className="text-center font-header-secondary">THE BODY METHOD</h5>
    </div>
  );
};

export default LandingQuote;
