
import api from './api';

const route = '/tracks';

export default {
  getTracks: () => api.get(route),
  getSingleTrack: (track_id) => api.get(`${route}/${track_id}`),
  postTrack: (track) => api.post(route, { ...track}),
  putTrack: (track) => api.put(route, { ...track}),
  deleteTrack: (Track) => api.delete(`${route}/${Track}`),
};
