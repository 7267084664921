import React, { createContext, useReducer } from "react";
import CartService from "../services/CartService";
import CartReducer from "../reducers/CartReducer";
import { CARTS_RECEIVED, SET_CART } from "../types/cart";

const initialState = {
  cart_items: null,
  cart: null,
};

export const CartContext = createContext(initialState);

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const getCart = (email) => {
    CartService.getCart(email).then((response) => {
      const { cart_items } = response.data;
      dispatch({ type: CARTS_RECEIVED, payload: cart_items });
    });
  };

  const postCart = (data) => {
    CartService.postCart(data);
  };

  const setCart = (cart) => {
    dispatch({ type: SET_CART, payload: cart });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        setCart,
        getCart,
        postCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
