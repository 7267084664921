import { Link } from "@reach/router";
import React from "react";
import { S3_ENDPOINT } from "../../utils";
import "./MonterreyCoachCard.css";

const MonterreyCoachCard = ({ coach, color }) => {
  const { name, last_name, file } = coach;

  const renderFile = () => {
    if (file && file !== null) {
      return (
        <img
          alt={coach.name}
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="monterrey__coach-img"
        />
      );
    }
  };


  const renderFounder = () => {
    if (coach.instructor_id === 2) {
      return (
        <p className="text-center text-dark mb-0 w-100">
          TBM Founder
        </p>
      );
    }
  };

  return (
    <Link
      to={`/coach/${coach.instructor_id}`}
      className={` monterrey__coach-card position-relative no-decoration px-0 d-block mx-3 bottom-0`}
    
    >
      <div className="home-coach-img-wrapper mt-0 position-relative">
        <div className={`bg-image bg-coach-${color}`} />
        {renderFile()}
      </div>      

      <div className="text-center mb-0 px-4 mt-0 text-dark bg-white border coach-card__desc">
        <h3 className="mb-0 text-black">
          {name} {last_name}
        </h3>
        {renderFounder()}
        <p className="coach-quote">"{coach.quote}"</p>

        <button
          target="_blank"
          className="btn btn-sm text-primary position-absolute bottom-0 end-0 start-0 mx-auto"
          onClick={() =>
            window.open(`https://instagram.com/${coach.instagram !== null ? coach.instagram : "thebodymethodmx"}`)
          }
        >
          <i className="fab fa-instagram fa-2x" />
        </button>
      </div>
    </Link>
  );
};

export default MonterreyCoachCard;
