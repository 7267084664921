import { navigate } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";
import React from "react";

const ProgramVideoCard = ({ program, customURL }) => {
  const { icon, name, color } = program;
  return (
    <div
      className="col text-center p-md-4 pb-3 px-4"
      onClick={() =>
        navigate(customURL ? customURL : `/mytbm/online/program/${program.url}`)
      }
    >
      <div
        className="text-center p-2 card program-video-card row align-items-center"
        style={{ backgroundColor: color }}
      >
        <div className="p-2 text-white">
          <img src={`${S3_ENDPOINT}/${icon}`} className="program-icon" />
          <p className="h5 text-shadow bold">{name}</p>
        </div>
      </div>
    </div>
  );
};
export default ProgramVideoCard;
