import api from "./api";

const route = "/checkout";

const CheckoutService = {
  attempt: (class_package_id, discountCode, payment_method, cart) =>
    api.post(`${route}/attempt`, {
      class_package_id,
      discountCode,
      payment_method,
      cart_id: cart && cart !== null ? cart.cart_id : null,
    }),
  postCheckout: (class_package_id, discountCode, payment_source_id, cart) =>
    api.post(`${route}/attempt`, {
      class_package_id, 
      discountCode,
      payment_source_id ,
      cart_id: cart && cart !== null ? cart.cart_id : null,
    }),
  postPayPal: (class_package_id, discountCode, cart) =>
    api.post(`${route}/paypal`, {
      class_package_id,
      discountCode,
      cart_id: cart && cart !== null ? cart.cart_id : null,
    }),
  capturePayPal: (data) => api.post(`${route}/capture`, { ...data }),
};

export default CheckoutService;
