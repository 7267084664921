import { navigate } from "@reach/router";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const CustomerCompleteInfo = () => {
    const { clearModal } = useContext(ModalContext)

    const handleInfo = () => {
        navigate("/mytbm/informacion");
        clearModal();
      };

    return (
        <div>
            <p>
                Debes completar tu información personal antes de acceder a tus
                clases de TBM Online o TBM Presencial.
            </p>
            <button onClick={handleInfo} className="btn btn-primary w-100">
                Completar Información
            </button>
        </div>
    )
}

export default CustomerCompleteInfo;