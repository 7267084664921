/**
 * User
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_CREATED = "USER_CREATED";
export const SET_PROPIEDAD_USER = "SET_PROPIEDAD_USER";
export const SET_PROPIEDAD_LOGIN = "SET_PROPIEDAD_LOGIN";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const GUARDAR_USUARIO = "GUARDAR_USUARIO";
export const CUENTA_RECIBIDA = "CUENTA_RECIBIDA";
export const SET_FIREBASE_UNSUBSCRIBE = "SET_FIREBASE_UNSUBSCRIBE";
/**
 * Modal
 */
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR = "CLEAR";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const MODAL_COMPONENT = "MODAL_COMPONENT";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
/**
 * Spinners
 */
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_SPINNER = "SHOW_SPINNER";
/**
 * Updates
 */
export const SET_UPDATED = "SET_UPDATED";
/**
 * Paquetes
 */
export const PAQUETES_RECIBIDOS = "PAQUETES_RECIBIDOS";
export const SET_PROPIEDAD_PAQUETE = "SET_PROPIEDAD_PAQUETE";
export const CREATE_PAQUETE = "CREATE_PAQUETE";
/**
 * Home
 */
export const ONLINE_HOME_RECIBIDOS = "ONLINE_HOME_RECIBIDOS";
export const PRESENCIALES_HOME_RECIBIDOS = "PRESENCIALES_HOME_RECIBIDOS";
export const ESPECIALES_HOME_RECIBIDOS = "ESPECIALES_HOME_RECIBIDOS";
/**
 * Schedule
 */
export const SET_MONTH = "SET_MONTH";
export const SET_WEEK = "SET_WEEK";
export const SEMANAS_RECIBIDAS = "SEMANAS_RECIBIDAS";
export const RESERVATIONS_RECIBIDAS = "RESERVATIONS_RECIBIDAS";
export const SET_CLASE = "SET_CLASE";
export const SET_PROPIEDAD_CLASE = "SET_PROPIEDAD_CLASE";
export const CREATE_CLASE = "CREATE_CLASE";
export const CLASES_RECIBIDAS = "CLASES_RECIBIDAS";
export const TOTAL_RECIBIDO = "TOTAL_RECIBIDO";
export const SET_END_DATE = "SET_END_DATE";
export const SET_START_DATE = "SET_START_DATE";
/**
 * Locations
 */
export const LOCATIONS_RECIBIDAS = "LOCATIONS_RECIBIDAS";
export const SET_LOCATION = "SET_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const SET_PROPIEDAD_LOCATION = "SET_PROPIEDAD_LOCATION";
/**
 * Checkout
 */
export const SET_PAQUETE = "SET_PAQUETE";
/**
 * Metodos Pago
 */
export const METODOS_RECIBIDOS = "METODOS_RECIBIDOS";
/**
 * Ordenes
 */
export const ORDENES_RECIBIDAS = "ORDENES_RECIBIDAS";
export const SET_ORDEN = "SET_ORDEN";
/**
 * Favoritos
 */
export const AGREGAR_FAVORITO = "AGREGAR_FAVORITO";
export const ELIMINAR_FAVORITO = "ELIMINAR_FAVORITO";
export const CLEAR_RELOAD = "CLEAR_RELOAD";
/**
 * Class Category
 */
export const CLASS_CATEGORIES_RECIBIDAS = "CLASS_CATEGORIES_RECIBIDAS";
export const CLASS_CATEGORY_RECIBIDA = "CLASS_CATEGORY_RECIBIDA";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const SET_PROPIEDAD_CATEGORY = "SET_PROPIEDAD_CATEGORY";
/**
 * Video Type
 */
export const VIDEO_TYPE_RECIBIDOS = "VIDEO_TYPE_RECIBIDOS";
/**
 * Customers
 */
export const CUSTOMERS_RECIBIDOS = "CUSTOMERS_RECIBIDOS";
export const LEGACY_CUSTOMER = "LEGACY_CUSTOMER";
export const SET_CUSTOMER = "SET_CUSTOMER";
/**
 * Coaches
 */
export const COACHES_RECIBIDAS = "COACHES_RECIBIDAS";
export const SET_PROPIEDAD_COACH = "SET_PROPIEDAD_COACH";
export const CREATE_COACH = "CREATE_COACH";
export const SET_COACH = "SET_COACH";
/**
 * Analiticas
 */
export const INSCRITOS_RECIBIDOS = "INSCRITOS_RECIBIDOS";
export const INGRESOS_RECBIDOS = "INGRESOS_RECBIDOS";
export const FAVORITOS_RECIBIDOS = "FAVORITOS_RECIBIDOS";
/**
 * Class Types
 */
export const CLASS_TYPES_RECIBIDOS = "CLASS_TYPES_RECIBIDOS";
export const SET_CLASS_TYPE = "SET_CLASS_TYPE";
export const SET_PROPIEDAD_CLASS_TYPE = "SET_PROPIEDAD_CLASS_TYPE";
export const CREATE_CLASS_TYPE = "CREATE_CLASS_TYPE";
/**
 * Descuentos
 */
export const SET_DESCUENTO = "SET_DESCUENTO";
export const HIDE_SPINNER_DESCUENTO = "HIDE_SPINNER_DESCUENTO";
export const SHOW_SPINNER_DESCUENTO = "SHOW_SPINNER_DESCUENTO";
export const CREATE_DESCUENTO = "CREATE_DESCUENTO";
export const DESCUENTOS_RECIBIDOS = "DESCUENTOS_RECIBIDOS";
export const SET_PROPIEDAD_DESCUENTO = "SET_PROPIEDAD_DESCUENTO";
/**
 * Extras
 */
export const SET_FILTER = "SET_FILTER";
export const SET_MANY_FILTERS = "SET_MANY_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const TAGS_RECIBIDOS = "TAGS_RECIBIDOS";
export const PROGRAMS_RECIBIDOS = "PROGRAMS_RECIBIDOS";
export const INVOICES_RECIBIDOS = "INVOICES_RECIBIDOS";
export const SINGLE_PROGRAM_RECIBIDO = "SINGLE_PROGRAM_RECIBIDO";
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";