import api from "./api";

const route = "/customer_workout_plans";

export default {
  getCustomerWorkoutPlans: () => api.get(route),
  getActiveCustomerWorkoutPlan: () => api.get(`${route}/active/single`),
  getSingleCustomerWorkoutPlan: (customer_workout_plan_id) =>
    api.get(`${route}/${customer_workout_plan_id}`),
  postCustomerWorkoutPlan: (customerWorkoutPlan) =>
    api.post(route, { ...customerWorkoutPlan }),
  putCustomerWorkoutPlan: (customerWorkoutPlan) =>
    api.put(route, { ...customerWorkoutPlan }),
  deleteCustomerWorkoutPlan: (customer_workout_plan_id) =>
    api.delete(`${route}/${customer_workout_plan_id}`),
};
