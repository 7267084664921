import React, { useContext, useEffect } from "react";
import Footer from "../components/global/Footer";
import { CheckoutContext } from "../context/CheckoutContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import CheckoutBillingDisclaimer from "../components/checkout/CheckoutBillingDisclaimer";
import { getValue } from "../utils";
import { CartContext } from "../context/CartContext";
import { AuthContext } from "../context/AuthContext";

const Checkout = ({ class_package_id }) => {
  //Checkout
  const { setDescuento, class_package, payment_source_id } =
    useContext(CheckoutContext);
  const { user } = useContext(AuthContext);
  const { cart_items, setCart, getCart } = useContext(CartContext);

  useEffect(() => {
    const email = window.localStorage.getItem("email");
    if (email && email !== "" && email !== null) {
      getCart(email);
    }
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDescuento(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_package_id]);

  useEffect(() => {
    if (user !== null) {
      getCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (Array.isArray(cart_items)) {
      let items = [...cart_items].filter(
        (item) => String(item.class_package_id) === String(class_package_id)
      );
      if (items.length > 0) setCart(items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_items]);

  const renderBillingDisclaimer = () => {
    if (getValue(class_package, "is_subscription")) {
      return <CheckoutBillingDisclaimer />;
    }
  };

  return (
    <div className="container-fluid px-0">
      <CheckoutHeader />
      <div className="container-fluid">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-6 col-xl-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-6 col-xl-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              class_package_id={class_package_id}
              paymentMethod={payment_source_id}
            />
          </div>
        </div>
        {renderBillingDisclaimer()}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
