import React from "react";

const VideoSingleTag = ({ value, label }) => {
  return (
    <div className="row mb-3">
      <div className="col-4 bold">{label}</div>
      <div className="col-8">
        <span className="badge bg-success-light text-dark">
          {value}
        </span>
      </div>
    </div>
  );
};

export default VideoSingleTag;