import React from "react";
import AppLink from "../components/home/AppLink";
import useBranch from "../hooks/useBranch";
import { S3_ENDPOINT } from "../utils";

const MainLanding = () => {
  const { branches, selectBranch } = useBranch();

  return (
    <div id="main-landing">
      <div className="row align-items-center">
        <AppLink
          src={`${S3_ENDPOINT}/cover-tbm-online.jpg`}
          mobile={`${S3_ENDPOINT}/mobile-tbm-online.jpg`}
          logo={`${S3_ENDPOINT}/tbm-logo-online.png`}
          href="https://web.thebodymethod.mx"
        />
        <AppLink
          src={`${S3_ENDPOINT}/cover-tbm-certifications.jpg`}
          href="https://tbmcertifications.com/"
          changeBaseUrl
          logo={`${S3_ENDPOINT}/tbm-logo-certis.png`}
        />
        <AppLink
          src={`${S3_ENDPOINT}/cover-tbm-monterrey.jpg`}
          mobile={`${S3_ENDPOINT}/mobile-tbm-monterrey.jpg`}
          href="/monterrey"
          handleClick={() => {
            selectBranch(branches[0].branch_id);
          }}
          logo={`${S3_ENDPOINT}/tbm-logo-monterrey.png`}
        />
        <AppLink
          src={`${S3_ENDPOINT}/cover-tbm-tijuana.jpeg`}
          href="/tijuana"
          handleClick={() => {
            selectBranch(branches[1].branch_id);
          }}
          logo={`${S3_ENDPOINT}/tbm-logo-tijuana.png`}
        />
      </div>
    </div>
  );
};

export default MainLanding;
