import React, { useState, useContext, useEffect } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import HomeClassTypeCard from "../classTypes/HomeClassTypeCard";
import { FiltersContext } from "../../context/FiltersContext";

const LandingClassTypes = () => {
  const [components, setComponents] = useState([]);
  const [mobileComponents, setMobileComponents] = useState([]);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { class_category_id } = useContext(FiltersContext);

  useEffect(() => {
    getClassTypes({
      class_category_id
    });

    let classInterval = setInterval(() => {
      setMobileComponents((prevState) => {
        let current = [...prevState];
        let first = current.shift();
        return [...current, first];
      });
    }, 3000);

    return () => {
      clearInterval(classInterval);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(class_types)) {
      const class_types_render = getClasTypesWithFile(class_types);
      handleClassTypes(class_types_render);
    }
  }, [class_types]);

  const handlePrev = () => {
    let current = [...mobileComponents];
    let last = current.pop();
    setMobileComponents([last, ...current]);
    current = [...components];
    last = current.pop();
    setComponents([last, ...current]);
  };

  const handleNext = () => {
    let current = [...mobileComponents];
    let first = current.shift();
    setMobileComponents([...current, first]);
    current = [...components];
    first = current.shift();
    setComponents([...current, first]);
  };

  const getClasTypesWithFile = (class_types) =>
    class_types.filter((class_type) => class_type.file !== null);

  const handleClassTypes = (class_types) => {
    let current = [];
    let mobile = [];
    let column = [];
    for (let i = 0; i < class_types.length; i++) {
      let class_type = class_types[i];
      if (i === 0 || i % 3 === 0) {
        current.push(
          <div
            key={class_type.class_type_id}
            className="col-12 mb-3 class-type-column py-3"
          >
            <HomeClassTypeCard class_type={class_type} size="lg" />
          </div>
        );
      } else {
        column.push(
          <HomeClassTypeCard
            key={class_type.class_type_id}
            class_type={class_type}
          />
        );
      }
      if (column.length == 2) {
        current.push(
          <div key={`col-${i}`} className="col-6 mb-3 class-type-column py-3">
            {column}
          </div>
        );
        column = [];
      }
      mobile.push(
        <div key={i} className="col-6 mb-3 class-type-column py-3">
          <HomeClassTypeCard class_type={class_type} size="lg" />
        </div>
      );
    }
    setMobileComponents(mobile);
    setComponents(current);
  };

  return (
    <div id="class_types" className="bg-light py-5 px-3 overflow-hidden">
      <h2 className="h1 mb-5 text-center unstyled">Tipos de Clases</h2>
      <div className="class-type-wrapper hide-mobile">
        <div className="row class-type-row align-items-center">
          {components}
        </div>
      </div>
      <div className="class-type-wrapper show-mobile">
        <div className="row class-type-row align-items-center">
          {mobileComponents}
        </div>
      </div>
      <div className="container-fluid text-end">
        <button
          onClick={handlePrev}
          className="btn btn-light btn-round me-1 shadow"
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          onClick={handleNext}
          className="btn btn-light btn-round ms-1 shadow"
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default LandingClassTypes;
