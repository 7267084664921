import React, { useContext, useEffect, useRef, useState } from "react";
import "./MonterreyLandingPackages.css";
import "./MonterreyLandingLocations.css";

const MonterreyLandingLocations = () => {
  const [studioOpen, setStudioOpen] = useState(false);
  const [premisesOpen, setPremisesOpen] = useState(false);

  const studioBtn = useRef();
  const premisesBtn = useRef();

  const handleToggle = () => {
    const studioValue =
      studioBtn.current?.attributes["aria-expanded"].value === "true";
    const premisesValue =
      premisesBtn.current?.attributes["aria-expanded"].value === "true";

    setStudioOpen(studioValue);
    setPremisesOpen(premisesValue);
  };

  return (
    <div className="monterrey-locations bg-white position-relative">
      <div className="locations__container">
        <div className="card no-scale p-0 monterrey-location__card">
          <div className="card__content position-relative">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950.3890811236857!2d-100.3806848783276!3d25.697377453126542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629646f0530ea7%3A0x78a7a68096a32786!2sThe%20Body%20Method!5e0!3m2!1ses-419!2smx!4v1726253771180!5m2!1ses-419!2smx"
              width="100%"
              height="100%"
              style={{
                border: 0,
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="accordion w-100 p-0" id="accordionStudio">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  ref={studioBtn}
                  className="accordion-button d-flex justify-content-between align-items-center"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseStudio"
                  aria-expanded="false"
                  aria-controls="collapseStudio"
                  onClick={handleToggle}
                >
                  <h2 className="bold">ESTUDIO MONTERREY</h2>
                  <i
                    className={`fas ${
                      studioOpen ? "fa-minus" : "fa-plus"
                    } accordion-btn-icon`}
                  />
                </button>
              </h2>
              <div
                id="collapseStudio"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStudio"
              >
                <div className="accordion-body">
                  <p style={{ width: '280px'}} className="mb-0">
                    Blvd. Rogelio Cantú Gómez 1000 Las Colinas Residencial,
                    64638 Monterrey, N.L. Local #79. Estamos en el 3er piso.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card no-scale p-0 monterrey-location__card">
          <div className="card__content">
            <img
              className="premises-img"
              alt="TBM Instalaciones"
              src="/img/instalaciones.png"
            />
          </div>
          <div className="accordion w-100 p-0" id="accordionPremises">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  ref={premisesBtn}
                  className="accordion-button d-flex justify-content-between align-items-center"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePremises"
                  aria-expanded="false"
                  aria-controls="collapsePremises"
                  onClick={handleToggle}
                >
                  <h2 className="bold">INSTALACIONES</h2>
                  <i
                    className={`fas ${
                      premisesOpen ? "fa-minus" : "fa-plus"
                    } accordion-btn-icon`}
                  />
                </button>
              </h2>
              <div
                id="collapsePremises"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionPremises"
              >
                <div className="accordion-body">
                  <p className="mb-0">
                    Disfruta de nuestras amplias instalaciones que incluyen: regadera, baño, cubículos para tus pertenencias, salón de entrenamiento para 25 personas, recepción y consultorio de nutrición con descuento exclusivo para nuestros miembros.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default MonterreyLandingLocations;
