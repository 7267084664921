import React, { useState, useContext } from "react";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";
import moment from "moment";

const today = moment().format("yyyy-MM-DD");


const ApplyWorkoutPlanner = ({
  handleCancel,
  handleCallback,
  workout_plan_id,
}) => {
  const [startDate, setStartDate] = useState(today);
  const { saveCustomerWorkoutPlan } = useContext(CustomerWorkoutPlansContext);

  const callback = () => {
    if (typeof handleCallback === "function") {
      handleCallback();
    }
    handleCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCustomerWorkoutPlan(
      {
        workout_plan_id,
        end_date: moment(startDate).add(4, "weeks").format("YYYY-MM-DD"),
        start_date: startDate,
      },
      callback
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>¿Cuándo deseas empezar este Workout Planner?</p>
      <p>
        El Workout Planner tiene una duración de 1 mes a partir de la fecha
        que lo apliques.
      </p>
      <p>Puedes cambiarlo en cualquier momento.</p>
      <input
        type="date"
        min={today}
        value={startDate}
        className="form-control mb-4"
        onChange={(e) => {
          const todayStart = moment().startOf("day");
          const value = moment(e.target.value).startOf("day");
          if (value.isSameOrAfter(todayStart)) {
            setStartDate(value.format("YYYY-MM-DD"));
          }
        }}
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Aplicar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ApplyWorkoutPlanner;
