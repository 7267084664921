import React, { useContext, useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import TimeInput from "../common/TimeInput";
import { CustomerNotificationTypesContext } from "../../context/CustomerNotificationTypesContext";
import { AuthContext } from "../../context/AuthContext";

const NotificationTypeRow = ({ notification_type }) => {
  const [emailActive, setEmailActive] = useState(null);
  const [desiredTime, setDesiredTime] = useState(null);
  const [whatsAppActive, setWhatsAppActive] = useState(null);
  const [customerNotificationType, setCustomerNotificationType] =
    useState(null);

  const { user } = useContext(AuthContext);
  const { customer_notification_types, saveCustomerNotificationType } =
    useContext(CustomerNotificationTypesContext);

  useEffect(() => {
    getCustomerNotificationTypes();
  }, [customer_notification_types, notification_type]);

  useEffect(() => {
    if (customerNotificationType !== null) {
      setWhatsAppActive(customerNotificationType.whatsapp_active);
      setEmailActive(customerNotificationType.email_active);
      setDesiredTime(customerNotificationType.preferred_time);
    }
  }, [customerNotificationType]);

  const handleUpdate = () => {
    let data = customerNotificationType;
    if (data === null) {
      data = {
        notification_type_id: notification_type.notification_type_id,
        customer_id: user.customer_id,
        preferred_time: desiredTime,
        active: true,
      };
    } else {
      data.preferred_time = desiredTime;
      data.email_active = emailActive;
      data.whatsapp_active = whatsAppActive;
    }
    saveCustomerNotificationType(data);
  };

  const getCustomerNotificationTypes = () => {
    if (Array.isArray(customer_notification_types)) {
      const index = customer_notification_types.findIndex(
        (current) =>
          current.notification_type_id ===
          notification_type.notification_type_id
      );
      if (index !== -1) {
        const current = customer_notification_types[index];
        setCustomerNotificationType(current);
      } else {
        setCustomerNotificationType({
          notification_type_id: notification_type.notification_type_id,
          customer_id: user.customer_id,
          whatsapp_active: true,
          email_active: true,
          time: null,
        });
      }
    }
  };

  const renderButtons = () => {
    if (customerNotificationType !== null) {
      if (
        (customerNotificationType.preferred_time !== desiredTime ||
          customerNotificationType.whatsapp_active !== whatsAppActive ||
          customerNotificationType.email_active !== emailActive) &&
        (whatsAppActive !== null &&
        emailActive !== null)
      ) {
        return (
          <div className="container-fluid text-end">
            <button
              onClick={() => setDesiredTime(null)}
              className="btn text-muted me-2"
            >
              Cancelar
            </button>
            <button onClick={handleUpdate} className="btn btn-sm btn-primary">
              Guardar
            </button>
          </div>
        );
      }
    }
  };

  return (
    <div className="row mb-3">
      <div className="col-lg-8">
        <p className="large bold">{notification_type.name}</p>
        <p>{notification_type.description}</p>
      </div>
      <div className="col-lg-1">
        {emailActive !== null && (
          <ReactSwitch checked={emailActive} onChange={setEmailActive} />
        )}
      </div>
      <div className="col-lg-1">
        {whatsAppActive !== null && (
          <ReactSwitch checked={whatsAppActive} onChange={setWhatsAppActive} />
        )}
      </div>
      <div className="col-lg-2">
        <TimeInput hideLabel value={desiredTime} modifier={setDesiredTime} />
      </div>
      {renderButtons()}
    </div>
  );
};

export default NotificationTypeRow;
