import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { WorkoutPlansContext } from "../../context/WorkoutPlansContext";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";

const cumplido = [
  "¡Felicidades! Haz completado tu workout planner",
  "Tu constancia, esfuerzo y dedicación han dado resultado.",
  "Sigue así, conquistando tus metas.",
  "Ahora tienes dos opciones, puedes repetir tu mismo Workout Planner o puedes Empezar Uno Nuevo"
];

const nocumplido = [
  "Lo sentimos, tu workout planner ha terminado.",
  "Tus metas no tienen fecha de caducidad. Respira hondo y vuelve a intentarlo.",
  "Ahora tienes dos opciones, puedes repetir tu mismo Workout Planner o puedes Empezar Uno Nuevo"
]

const PlannerFinished = ({ handleApply }) => {
  const { clearModal } = useContext(ModalContext);
  const { setWorkoutPlan } = useContext(WorkoutPlansContext);
  const { setCustomerWorkoutPlan } = useContext(CustomerWorkoutPlansContext);
  
  const handleStartOver = () => {
    navigate("/pages/workout-planner");
    setWorkoutPlan(null);
    setCustomerWorkoutPlan(null);
    clearModal();
  };

  return (
    <div>
      <p>Tu Workout Planner ha terminado.</p>
      <p>
        Esperamos que hayas disfrutado tus clases y hayas sentido progreso en tu
        salud física y mental.
      </p>
      <p>
        Ahora tienes dos opciones, puedes repetir tu mismo Workout Planner o
        puedes Empezar Uno Nuevo
      </p>
      <div className="row mb-3">
        <div className="col-6">
          <button className="btn btn-primary w-100" onClick={handleApply}>Repetir Planner</button>
        </div>
        <div className="col-6">
          <button onClick={handleStartOver} className="btn btn-outline-primary w-100">
            Empezar Uno Nuevo
          </button>
        </div>
      </div>
      <div className="row">
        <button className="btn btn-link text-muted" onClick={clearModal}>
          No lo sé, decidiré después
        </button>
      </div>
    </div>
  );
};

export default PlannerFinished;