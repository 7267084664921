import React, { useContext, useEffect } from "react";
import { QuotesContext } from "../../context/QuotesContext";
import { S3_ENDPOINT, randomIntFromInterval } from "../../utils";

const Quote = () => {
  const { quote, getSingleQuote } = useContext(QuotesContext);

  useEffect(() => {
    getSingleQuote();
  }, []);

  const imageIndex = randomIntFromInterval(1, 3);

  const renderQuote = () => {
    if (quote === null) {
      return <div className="spinner-border" />;
    }
    return (
      <div>
        <h3>{quote.content}</h3>
        <p>{quote.author}</p>
      </div>
    );
  };

  return (
    <div
      className="container-fluid bg-image quote"
      style={{
        backgroundImage: `url("${S3_ENDPOINT}/frase-${imageIndex}.jpg")`,
      }}
    >
      <div className="row h-100 align-items-center">
        <div className="container-fluid text-center text-white text-shadow px-5">
          {renderQuote()}
        </div>
      </div>
    </div>
  );
};

export default Quote;
