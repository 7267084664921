import React, { useContext } from "react";
import { CustomerGoalsContext } from "../../context/CustomerGoalsContext";
import moment from "moment";

const SingleCustomerGoal = ({
  handleEdit,
  handleDelete,
  customer_goal,
  handleCallback,
}) => {
  const { saveCustomerGoal } = useContext(CustomerGoalsContext);

  const handleComplete = (e, customer_goal) => {
    const { checked } = e.target;
    const completedAt = checked ? moment().format("YYYY-MM-DD HH:mm") : null;
    saveCustomerGoal(
      {
        ...customer_goal,
        completedAt,
      },
      handleCallback
    );
  };

  return (
    <div className="row align-items-center mb-2">
      <div className="col-1">
        <input
          type="checkbox"
          checked={customer_goal.completedAt !== null}
          onChange={(e) => handleComplete(e, customer_goal)}
        />
      </div>
      <div className="col-9">
        <p className="small mb-0">{customer_goal.name}</p>
      </div>
      <div className="col-2 text-end">
        <button
          onClick={() => handleDelete(customer_goal)}
          className="btn btn-link py-0 px-1 text-muted me-2"
        >
          <i className="fa fa-trash" />
        </button>
        <button
          onClick={() => handleEdit(customer_goal)}
          className="btn btn-link px-0 py-0 text-muted"
        >
          <i className="fa fa-edit" />
        </button>
      </div>
    </div>
  );
};

export default SingleCustomerGoal;
