import api from "./api";
import { getArgs } from "../utils";

const route = "/locations";

const LocationsService = {
  getLocations: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getCountries: filters => api.get(`${route}/countries?${getArgs(filters)}`),
  getStates: filters => api.get(`${route}/states?${getArgs(filters)}`),
  getCities: filters => api.get(`${route}/cities?${getArgs(filters)}`),
  getLocationsByBranch: (filters, branch_id) => api.get(`${route}/${branch_id}?${getArgs(filters)}`),
  postLocation: (location) => api.post(route, { ...location }),
  putLocation: (location) => api.put(route, { ...location }),
  deleteLocation: (location_id) => api.delete(`${route}/${location_id}`),
};

export default LocationsService;
