import { navigate } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/global/Page";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";
import { ClassTypeContext } from "../../context/ClassTypesContext";

const ClassTypePage = ({ class_type_id }) => {
  const [classType, setClassType] = useState(null);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(class_types)) {
      const current = class_types.find(
        (class_type) => class_type.class_type_id === parseInt(class_type_id)
      );
      if (current) {
        setClassType(current);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_types]);

  const renderMedia = () => {
    if (classType.video_url !== null) {
      return (
        <video className="mw-100 w-100" autoPlay playsInline muted loop>
          <source
            src={`${S3_ENDPOINT}/${classType.video_url}`}
            type="video/mp4"
          />
        </video>
      );
    }
    if (classType.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${classType.file.name}.${classType.file.type}`}
          className="mw-100 w-100 p-3"
          alt={classType.name}
        />
      );
    }
  };

  const renderBenefits = () => {
    if (
      classType.benefits &&
      classType.benefits !== "" &&
      classType.benefits !== null
    ) {
      let benefits = String(classType.benefits).split(",");
      return (
        <div>
          <h3>Beneficios</h3>
          <ul className="ps-0" style={{ listStyleType: "none" }}>
            {benefits.map((content, index) => (
              <li key={index}>
                <i className="fa fa-check text-primary me-1" /> {content}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const renderVideos = () => {
    if (Array.isArray(classType.videos)) {
      return (
        <p className="bold">
          Encontrarás {classType.videos.length} clases de esta disciplina en TBM
          Online
        </p>
      );
    }
  };

  const renderClassType = () => {
    if (classType && classType !== null) {
      return (
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6">{renderMedia()}</div>
          <div className="col-md-12 col-lg-6">
            <div className="card no-scale p-4 shadow">
              <h3>Acerca de esta disciplina</h3>
              <p>{classType.description}</p>
              {renderBenefits()}
              {renderVideos()}
              <div className="container-fluid px-0">
                <Link to="/checkout/149" className="btn btn-primary">
                  Suscríbete
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Page title={classType !== null ? classType.name : ""}>
      {renderClassType()}
    </Page>
  );
};

export default ClassTypePage;
