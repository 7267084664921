import React, { useEffect, useContext } from "react";
import { NotificationsContext } from "../../context/NotificationsContext";
import { StreaksContext } from "../../context/StreaksContext";
import { ModalContext } from "../../context/ModalContext";
import StreakMonthUnlocked from "./StreakMonthUnlocked";
import StreakUnlocked from "./StreakUnlocked";
import { S3_ENDPOINT } from "../../utils";
import moment from "moment";


const InlineStreaks = () => {
  const { notifications, getNotifications } = useContext(NotificationsContext);
  const { streaks, getStreaks } = useContext(StreaksContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getStreaks();
    getNotifications();
  }, []);

  useEffect(() => {
    if (Array.isArray(streaks) && Array.isArray(notifications)) {
      handleMonthStreak();
    }
  }, [streaks, notifications]);

  const handleMonthStreak = () => {
    const monthStreakNotification = notifications.find(({ createdAt, content }) => {
      const notificationDate = moment(createdAt).utc().format("YYYY-MM-DD");
      const notificationContent = String(content).toLowerCase();
      const todayString = moment().format("YYYY-MM-DD");
      return notificationContent.includes("streak") && notificationContent.includes("mensual") && notificationDate === todayString;
    });
    if(monthStreakNotification) {
      return modalComponent("¡Felicidades!", <StreakMonthUnlocked />);
    }
    handleWeekStreak();
  };

  const handleWeekStreak = () => {
    const weekStreakNotification = notifications.find(({ createdAt, content }) => {
      const notificationDate = moment(createdAt).utc().format("YYYY-MM-DD");
      const notificationContent = String(content).toLowerCase();
      const todayString = moment().format("YYYY-MM-DD");
      return notificationContent.includes("streak") && notificationContent.includes("semanal") && notificationDate === todayString;
    });
    if(weekStreakNotification) {
      return modalComponent("¡Felicidades!", <StreakUnlocked />);
    }
  };

  const renderStreaks = () => {
    if (Array.isArray(streaks)) {
      return streaks.map((streak, index) => (
        <img
          key={index}
          src={`${S3_ENDPOINT}/streak-${Math.ceil((index + 1) / 4)}-${
            (index + 1) % 4
          }.png`}
          style={{
            display: "inline",
            width: 50,
            height: 50,
            objectFit: "contain",
            marginRight: -16,
          }}
        />
      ));
    }
  };

  return (
    <span
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Cada 🔥 representa una semana de Workout Planner completado"
    >
      {renderStreaks()}
    </span>
  );
};

export default InlineStreaks;
