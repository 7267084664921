import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import Attention from "../../components/editor/Attention";
import Bonus from "../../components/editor/Bonus";
import BulletList from "../../components/editor/BulletList";
import CallToAction from "../../components/editor/CallToAction";
import MainPitch from "../../components/editor/MainPitch";
import Pitch from "../../components/editor/Pitch";
import SectionTitle from "../../components/editor/SectionTitle";
import Secure from "../../components/editor/Secure";
import Stacking from "../../components/editor/Stacking";
import VideoTestimonial from "../../components/editor/VideoTestimonial";
import CustomContent from "../../components/editor/CustomContent";
import Product from "../../components/editor/Product";
import PictureTestimonial from "../../components/editor/PictureTestimonial";
import ThankYou from "../../components/editor/ThankYou";
import ImageText from "../../components/editor/ImageText";
import VideoText from "../../components/editor/VideoText";
import Page from "../../components/global/Page";
import { PagesContext } from "../../context/PagesContext";
import { HOME_URL } from "../../utils";
import DoubleButton from "../../components/editor/DoubleButton";
import NumberList from "../../components/editor/NumberList";

const SinglePage = ({ slug }) => {
  const [elements, setElements] = useState([]);

  const { page, getSinglePage } = useContext(PagesContext);

  const call_to_action = () => {};

  useEffect(() => {
    getSinglePage(slug);
    handleContent(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const getTitle = () => {
    if (page !== null) {
      return page.title;
    }
  };

  const handleContent = (slug) => {
    firebase
      .database()
      .ref(slug)
      .on("value", (snap) => {
        if (elements.length > 0) {
          setElements([]);
        }
        let tree = snap.val();
        if (tree && tree !== null) {
          let objects = Object.keys(tree).map((key) => ({
            component: key,
            props: tree[key],
          }));
          objects.sort((a, b) =>
            parseInt(a.props.orden) > parseInt(b.props.orden) ? 1 : -1
          );
          setElements(objects);
          window.scrollTo(0, 0);
        } else {
          window.open(HOME_URL, "_self");
        }
      });
  };

  const renderContent = () => {
    if (elements.length > 0) {
      return elements
        .map((object, index) => {
          let CustomComponent = "";
          if (object.component.includes("MainPitch")) {
            CustomComponent = MainPitch;
          } else if (object.component.includes("CallToAction")) {
            CustomComponent = CallToAction;
          } else if (object.component.includes("SectionTitle")) {
            CustomComponent = SectionTitle;
          } else if (object.component.includes("Pitch")) {
            CustomComponent = Pitch;
          } else if (object.component.includes("Stacking")) {
            CustomComponent = Stacking;
          } else if (object.component.includes("Attention")) {
            CustomComponent = Attention;
          } else if (object.component.includes("Bonus")) {
            CustomComponent = Bonus;
          } else if (object.component.includes("VideoTestimonial")) {
            CustomComponent = VideoTestimonial;
          } else if (object.component.includes("Secure")) {
            CustomComponent = Secure;
          } else if (object.component.includes("CustomContent")) {
            CustomComponent = CustomContent;
          } else if (object.component.includes("Product")) {
            CustomComponent = Product;
          } else if (object.component.includes("BulletList")) {
            CustomComponent = BulletList;
          } else if (object.component.includes("PictureTestimonial")) {
            CustomComponent = PictureTestimonial;
          } else if (object.component.includes("ThankYou")) {
            CustomComponent = ThankYou;
          } else if (object.component.includes("ImageText")) {
            CustomComponent = ImageText;
          } else if (object.component.includes("DoubleButton")) {
            CustomComponent = DoubleButton;
          } else if (object.component.includes("NumberList")) {
            CustomComponent = NumberList;
          } else if (object.component.includes("VideoText")) {
            CustomComponent = VideoText;
          }
          if (CustomComponent !== "") {
            return (
              <CustomComponent
                key={index}
                {...object.props}
                call_to_action={call_to_action}
              />
            );
          } else if (object.component === "Checkout") {
            document.title = object.props.page_title;
          }
          return null;
        })
        .filter((elem) => elem !== null);
    }
    return (
      <div className="h-100 text-center py-5">
        <div className="spinner-border"></div>
      </div>
    );
  };

  return <Page title={getTitle()}>{renderContent()}</Page>;
};

export default SinglePage;
