import React from "react";
import Footer from "../components/global/Footer";
import LandingVideo from "../components/landing/LandingVideo";
import LandingAbout from "../components/landing/LandingAbout";
import LandingClassTypes from "../components/landing/LandingClassTypes";
import LandingCoaches from "../components/landing/LandingCoaches";
import LandingSchedule from "../components/landing/LandingSchedule";
import LandingQuote from "../components/landing/LandingQuote";
import LandingTestimonials from "../components/landing/LandingTestimonials";
import LandingPlanes from "../components/landing/LandingPlanes";

const LandingPresencial = () => {
  return (
    <div id="landing">
      <LandingVideo />
      <LandingAbout />
      <LandingQuote />
      <LandingPlanes />
      <LandingTestimonials />
      <LandingClassTypes />
      <LandingCoaches />
      <LandingSchedule />
      <Footer />
    </div>
  );
};

export default LandingPresencial;
