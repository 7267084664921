import React from "react";

const ProgressBar = ({ progress, color }) => {
  return (
    <div className="w-100 position-relative">
      <span
        className=" text-white position-absolute start-0 end-0"
        style={{
          top: "-50px",
          width: "max-content",
          margin: "auto",
        }}
      >
        <i className="fas fa-comment-alt progress-bar-percentage" style={{fontSize: '40px'}} />

        <span
          className="text-dark bold bottom-0 position-absolute m-auto start-0 end-0"
          style={{
            fontSize: "12px",
            width: "max-content",
            height: "max-content",
            top: "-10px",
          }}
        >
          {progress}%
        </span>
      </span>

      <div
        className="progress position-relative"
        role="progressbar"
        aria-label="Basic example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{
          height: 30,
          borderRadius: "1rem",
        }}
      ></div>

      <div
        className={`progress-bar bg-${
          color ? color : "primary"
        } position-absolute bottom-0`}
        style={{
          width: `${progress}%`,
          height: "30px",
          top: "-1px",
          borderRadius: "1rem",
        }}
      ></div>
    </div>
  );
};
export default ProgressBar;
