import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import InvoicesTable from "../components/invoices/InvoicesTable";
import InvoiceList from "../components/invoices/InvoiceList";

const Invoices = () => {
  const { invoices, getInvoices } = useContext(PurchasesContext);

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid px-4 mx-md-3 pe-md-5 pb-4">
      <h2 className="my-2">Mis Cargos</h2>
      <div className="card border-0 shadow p-0 no-scale hide-mobile">
        <InvoicesTable invoices={invoices} />
      </div>
      <div className="show-mobile">
        <InvoiceList invoices={invoices} />
      </div>
    </div>
  );
};

export default Invoices;
