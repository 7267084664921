import React, { useState, useContext, useEffect } from "react";
import { PostsContext } from "../../context/PostsContext";
import TextareaInput from "../common/TextAreaInput";
import EmojiPicker from "emoji-picker-react";

const PostInput = ({ replyTo, video_id, clearReplyTo, inputCallback }) => {
  const { savePost } = useContext(PostsContext);

  const [files, setFiles] = useState(null);
  const [srcSet, setSrcSet] = useState([]);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);

  useEffect(() => {
    if (files !== null && files.length > 0) {
      const fileSet = [];
      const promises = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        let reader = new FileReader();

        const currentPromise = new Promise((resolve, reject) => {
          reader.readAsDataURL(file);

          reader.onload = (e) => {
            let src = e.target.result;
            fileSet.push({ src, index: i });
            resolve();
          };
        });

        promises.push(currentPromise);
      }

      Promise.all(promises).then(() => {
        setSrcSet(fileSet);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleCallback = () => {
    clearReplyTo();
    if (typeof inputCallback === "function") {
      inputCallback();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content !== "" || Array.isArray(files)) {
      const thread_id = replyTo && replyTo !== null ? replyTo.thread_id : null;
      const reply_post_id =
        replyTo && replyTo !== null ? replyTo.post_id : null;
      savePost(
        {
          reply_post_id,
          thread_id,
          video_id,
          content,
          files,
        },
        handleCallback
      );
      setContent("");
      setFiles(null);
      setSrcSet([]);
    }
  };

  const handleEmoji = (object) => {
    setContent(content + object.emoji);
  };

  const handleFiles = () => {
    const input = document.getElementById("community-file-input");
    input.click();
  };

  const removeFile = (index) => {
    let currentFiles = [...files];
    currentFiles.splice(index, 1);
    setFiles(currentFiles);
  };

  const renderReplyTo = () => {
    if (replyTo && replyTo !== null) {
      return (
        <div className="reply px-2 bg-white py-2 mb-2">
          <span className="d-block bold small">Respondiendo a:</span>
          {replyTo.content}
        </div>
      );
    }
  };

  const renderFiles = () => {
    if (srcSet.length > 0) {
      return (
        <div className="mt-2 ms-2 mb-3 reply">
          {srcSet.map(({ src, index }) => (
            <div key={index} className="position-relative d-inline-block">
              <img src={src} className="thumbnail mx-2 br-10" />
              <button
                style={{ position: "absolute", top: -15, right: -5 }}
                className="btn text-danger"
                onClick={() => removeFile(index)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div id="post-input" className="bg-light br-15 p-2">
      {renderReplyTo()}
      {renderFiles()}
      <form onSubmit={handleSubmit} className="position-relative">
        <div className="container-fluid px-0">
          <div className="row align-items-center">
            <div className="col-12 col-md-10">
              <TextareaInput
                valid={true}
                value={content}
                handleChange={setContent}
                placeholder="Escribe un comentario..."
              />
            </div>
            <div
              style={{ height: 35 }}
              className="col-12 col-md-2 position-relative text-end"
            >
              <button
                onClick={() => setShowEmojis(!showEmojis)}
                className="btn text-dark"
                disabled={loading}
                type="button"
              >
                <i className="fa fa-smile"></i>
              </button>
              {showEmojis && (
                <div className="emoji-picker-wrapper">
                  <button
                    onClick={() => setShowEmojis(false)}
                    className="btn btn-light shadow-sm"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                  <EmojiPicker onEmojiClick={handleEmoji} />
                </div>
              )}
              <button
                type="button"
                disabled={loading}
                onClick={handleFiles}
                className="btn text-dark"
              >
                <i className="fa fa-paperclip"></i>
              </button>
              <button
                disabled={loading}
                type="submit"
                className="btn text-dark"
              >
                {loading ? (
                  <div className="spinner-border" />
                ) : (
                  <i className="fa fa-paper-plane"></i>
                )}
              </button>
              <input
                type="file"
                multiple
                id="community-file-input"
                onChange={(e) => setFiles(e.target.files)}
                style={{ visibility: "hidden", height: 0, padding: 0 }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostInput;
