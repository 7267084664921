import {
  SET_CUSTOMERWORKOUTPLAN,
  CREATE_CUSTOMERWORKOUTPLAN,
  CUSTOMERWORKOUTPLANS_RECEIVED,
  SET_PROPERTY_CUSTOMERWORKOUTPLAN,
} from "../types/customer_workout_plans";

const schema = {};

const customerWorkoutPlansReducer = (state, { type, payload }) => {
  switch (type) {
    case CUSTOMERWORKOUTPLANS_RECEIVED:
      return { ...state, customer_workout_plans: payload };
    case SET_CUSTOMERWORKOUTPLAN:
      return { ...state, customer_workout_plan: payload };
    case CREATE_CUSTOMERWORKOUTPLAN:
      return { ...state, customer_workout_plan: schema };
    case SET_PROPERTY_CUSTOMERWORKOUTPLAN: {
      const { key, value } = payload;
      const customer_workout_plan = { ...state.customer_workout_plan };
      customer_workout_plan[key] = value;
      return { ...state, customer_workout_plan };
    }
    default:
      return { ...state };
  }
};

export default customerWorkoutPlansReducer;
