import React from "react";

const PaymentMethodCard = ({
  name,
  label,
  children,
  selected,
  description,
  setPaymentMethod,
}) => {
  return (
    <div
      onClick={() => setPaymentMethod(name)}
      className={`card no-scale mb-3 payment-method-card ${
        selected === name ? "border-primary" : ""
      }`}
    >
      <div className="row">
        <div className="col-1">
          <input
            type="radio"
            checked={selected === name}
            onChange={() => setPaymentMethod(name)}
          />
        </div>
        <div className="col-11">
          <h3>{label}</h3>
        </div>
      </div>
      <p>{description}</p>
      {children}
    </div>
  );
};

export default PaymentMethodCard;
