import api from "./api";
import { getArgs } from "../utils";

const route = "/posts";

export default {
  getPosts: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSinglePost: (post_id) => api.get(`${route}/${post_id}`),
  postPost: (post) => api.post(route, { ...post }),
  putPost: (post) => api.put(route, { ...post }),
  likePost: (post_id, customer_id) =>
    api.post(`${route}/like`, { post_id, customer_id }),
  deletePost: (post_id) => api.delete(`${route}/${post_id}`),
  deletePostLike: (post_id, customer_id) =>
    api.delete(`${route}/like/${post_id}/${customer_id}`),
  reportPost: (data) => api.post(`${route}/report`, { ...data }),
};
