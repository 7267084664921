import React from "react";
import moment from "moment";

const PlannerRestDay = ({ start_date, completedDay, currentDateString }) => {
  return (
    <div className="schedule-col text-center p-2">
      <div
        className={`card small p-2 py-4 ${
          completedDay ? "bg-success" : "bg-light"
        }`}
      >
        <span className="my-2">Descanso</span>
        {moment(start_date).isValid() && (
          <span className="d-block">{currentDateString}</span>
        )}
      </div>
    </div>
  );
};
export default PlannerRestDay;
