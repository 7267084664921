import React, { useState, useContext, useEffect } from "react";
import { WaitlistsContext } from "../../context/WaitlistsContext";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import SingleClassData from "./SingleClassData";

const Waitlist = ({ handleCancel, single_class_id }) => {
  const [inWaitlist, setInWaitlist] = useState(null);
  const [currentWaitlist, setCurrentWaitlist] = useState(null);
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { waitlist, getWaitlists, saveWaitlist, deleteWaitlist, getSingleClassWaitlist } =
    useContext(WaitlistsContext);

  useEffect(() => {
    fetchWaitlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (waitlist && waitlist !== null) {
      if (inWaitlist === null) {
        let index = waitlist.findIndex(
          ({ customer_id }) => customer_id === user.customer_id
        );
        if (index !== -1) setCurrentWaitlist(waitlist[index]);
        setInWaitlist(index !== -1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitlist]);

  const fetchWaitlist = () => {
    getSingleClassWaitlist(single_class_id);
    getWaitlists()
  }

  const handleSubmit = () => {
    saveWaitlist(
      { single_class_id, customer_id: user.customer_id },
      fetchWaitlist
    );
  };

  const handleDelete = () => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Precaución",
        <div>
          <p>¿Estás segura que deseas eliminarte de la lista de espera?</p>
          <p>
            No recibirás ningún aviso cuano un lugar esté disponible en esta
            clase.
          </p>
          <div className="row mx-0">
            <div className="col-6">
              <button className="btn text-muted w-100">Cancelar</button>
            </div>
            <div className="col-6">
              <button
                onClick={() =>
                  deleteWaitlist(currentWaitlist.waitlist_id, fetchWaitlist)
                }
                className="btn btn-danger w-100"
              >
                Eliminarme
              </button>
            </div>
          </div>
        </div>
      );
    }, 500);
  };

  const renderWaitlist = () => {
    if (inWaitlist === null) return <div className="spinner-border mb-4"></div>;
    if (inWaitlist) {
      return (
        <div className="text-center">
          <h4>¡Ya estás en lista de espera!</h4>
          <SingleClassData single_class={currentWaitlist.single_class} />
          <button
            onClick={handleDelete}
            className="btn btn-outline-danger mb-5"
          >
            Quitarme de Lista de Espera
          </button>
        </div>
      );
    }
    return (
      <div className="mb-4">
        <p>Esta clase está llena, pero puedes anotarte a lista de espera.</p>
        <button onClick={handleSubmit} className="btn btn-primary w-100 mb-4">
          Anotarme en Lista de Espera
        </button>
        <h4>¿Cómo funciona la lista de espera?</h4>
        <p>
          Si algún cliente cancela, recibirás un mensaje por WhatsApp para tomar
          su lugar.
        </p>
        <p>
          Todos los clientes en lista de espera reciben el mensaje al mismo
          tiempo, el primero en reservar se quedará con el lugar.
        </p>
      </div>
    );
  };

  return (
    <div className="container-fluid px-0">
      {renderWaitlist()}
      <button onClick={handleCancel} className="btn text-muted w-100">
        Cerrar
      </button>
    </div>
  );
};

export default Waitlist;
