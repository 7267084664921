import { Link } from "@reach/router";
import React from "react";

const EmptyReservations = () => {
  return (
    <div className="container-fluid px-0">
      <p>Aún no has reservado clases.</p>
      <Link to="/mytbm/presencial" className="btn btn-primary bold">
        Ir a Reservar
      </Link>
    </div>
  );
};

export default EmptyReservations;
