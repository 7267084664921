import React from "react";
import ReservacionRow from "./ReservacionRow";

const ReservacionesTable = ({ reservations, confirmCancel }) => {
  return (
    <div className="table-responsive hide-mobile">
      <table className="table">
        <thead>
          <tr className="bg-light border mx-0 py-2 bold hide-mobile">
            <td>Clase</td>
            <td>Coach</td>
            <td>Fecha y Hora</td>
            <td>Reservada en</td>
            <td>Estado</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>
          {reservations.map((reservation) => (
            <ReservacionRow
              reservation={reservation}
              handleCancel={confirmCancel}
              key={reservation.class_reservation_id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReservacionesTable;
