import React from "react";
import { S3_ENDPOINT } from "../../utils";

const CancelWorkoutPlanner = () => {
  return (
    <div className="bg-gradient">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <img
            src={`${S3_ENDPOINT}/workout-planner-target.jpg`}
            className="mw-100 w-100 hide-mobile"
          />
          <img src="/img/landing-1.jpg" className="mw-100 w-100 show-mobile" />
        </div>
        <div className="col-12 col-md-6 text-white">
          <div className="p-5">
            <h3 className="text-shadow">
              ¿Ya probaste nuestro Workout Planner?
            </h3>
            <p>
              ¡Ayúdanos a personalizar tu entrenamiento de acuerdo a tus
              objetivos y necesidades!
            </p>
            <p>
              Contesta un par de preguntas para recibir nuestras recomendaciones
              sobre cómo organizar tus clases!
            </p>
            <button className="btn btn-warning bold text-uppercase">
              Ir a Workout Planner
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancelWorkoutPlanner;
