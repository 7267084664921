import React, { useContext, useEffect } from "react";
import VideoCard from "../components/videos/VideoCard";
import { VideosContext } from "../context/VideosContext";
import { AuthContext } from "../context/AuthContext";
import OnlineHeader from "../components/global/OnlineHeader";
import { Link } from "@reach/router";
import { ModalContext } from "../context/ModalContext";
import CustomerUpgrade from "../components/customers/CustomerUpgrade";

const VideoPremiere = () => {
  const { modalComponent } = useContext(ModalContext);
  const { user, getUsuario } = useContext(AuthContext);

  const {
    videos,
    favoritos,
    getVideos,
    purchase_needed,
    agregarFavorito,
    eliminarFavorito,
  } = useContext(VideosContext);

  useEffect(() => {
    getUsuario();
    getVideos({ program_id: "estrenos" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.has_access) {
      modalComponent(
        "Suscríbete a TBM",
        <CustomerUpgrade feature="Clases Online" />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderVideos = () => {
    if (!user.has_access || purchase_needed) {
      return (
        <div className="container-fluid">
          <p>
            Lo sentimos, no cuentas con una membresía activa para TBM Online
          </p>
          <Link className="btn btn-primary" to="/mytbm/paquetes">
            Ir a Paquetes
          </Link>
        </div>
      );
    }
    if (Array.isArray(videos)) {
      let videosRender = videos;
      if (Array.isArray(favoritos)) {
        videosRender = favoritos;
      }
      if (videosRender.length === 0) {
        return <p>No hay videos disponibles para tu búsqueda.</p>;
      }
      return videosRender.map((video) => (
        <VideoCard
          key={video.video_id}
          video={video}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
        />
      ));
    }
    if (!purchase_needed) return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-4">
      <OnlineHeader title="Estrenos" backEnabled />
      <div className="row">{renderVideos()}</div>
    </div>
  );
};

export default VideoPremiere;
