import React from "react";
import { S3_ENDPOINT } from "../../utils";
import "./testimonials.css";

const TestimonialCard = ({ testimonial }) => {
  return (
      <div className="card testimonial-card mb-5 d-block m-auto br-25 no-scale py-md-5 px-4 bg-light w-100">
        <div className="row mx-0 align-items-center">
          <div className="col-12 col-md-6 col-xl-5 px-md-5 px-0">
            <img
              src={`${S3_ENDPOINT}/${testimonial.customer_file?.name}.${testimonial.customer_file?.type}`}
              className="br-25 w-100 d-block m-auto"
              alt={testimonial.customer_name}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-7 px-0 px-md-3">
            <div className="p-md-3 px-0 py-3">
              <p className="small">"{testimonial.description}"</p>
              <p className="bold small">- {testimonial.customer_name}</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TestimonialCard;
