import React, { useEffect, useRef, useState } from "react";
import "./MonterreyLandingAccordion.css";

const MonterreyLandingAccordion = () => {
  const [reservationsOpen, setReservationsOpen] = useState(true);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [studioOpen, setStudioOpen] = useState(false);

  const reservationsBtn = useRef();
  const materialBtn = useRef();
  const studioBtn = useRef();

  const handleToggle = () => {
    const reservationsValue =
      reservationsBtn.current.attributes["aria-expanded"].value === "true";
    const materialValue =
      materialBtn.current.attributes["aria-expanded"].value === "true";
    const studioValue =
      studioBtn.current.attributes["aria-expanded"].value === "true";

    setReservationsOpen(reservationsValue);
    setMaterialOpen(materialValue);
    setStudioOpen(studioValue);
  };

  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            ref={reservationsBtn}
            className="accordion-button d-flex justify-content-between align-items-center"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            onClick={handleToggle}
          >
            <h2 className="bold">RESERVACIONES</h2>
            <i
              className={`fas ${
                reservationsOpen ? "fa-minus" : "fa-plus"
              } accordion-btn-icon`}
            />
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <p>-Puedes reservar hasta 1 hora antes de la clase.</p>
            <p>
              -Puedes cancelar hasta 1 hora antes de la clase, si no el crédito
              será perdido.
            </p>
            <p>
              -Para asegurar un buen inicio de clase, por favor se puntual. Las
              puertas se cierran 10 minutos después de comenzar.
            </p>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            ref={materialBtn}
            onClick={handleToggle}
            className="accordion-button collapsed d-flex justify-content-between align-items-center"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <h2 className="bold">MATERIAL</h2>

            <i
              className={`fas ${
                materialOpen ? "fa-minus" : "fa-plus"
              } accordion-btn-icon`}
            />
          </button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <p>
              -Los mats son exclusivamente para las clases de barre y BBP. Deben
              usarse descalzo o con calcetines. Prohibido el uso de tenis.
            </p>
            <p>
              -En el resto de las clases, te pedimos que traigas tu propio
              tapete y banda. Solo las alumnas de clase de prueba recibirán este
              equipo.
            </p>
            <p>
              -El resto del equipo (mancuernas, trampolín etc.) lo
              proporcionamos nosotros.
            </p>
            <p>
              -Después de cada clase, desinfecta tu mat y el equipo que hayas
              usado con las toallitas que te daremos. ¡Gracias por tu
              colaboración!
            </p>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            ref={studioBtn}
            className="accordion-button collapsed d-flex justify-content-between align-items-center"
            type="button"
            onClick={handleToggle}
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <h2 className="bold">ESTUDIO</h2>

            <i
              className={`fas ${
                studioOpen ? "fa-minus" : "fa-plus"
              } accordion-btn-icon`}
            />
          </button>
        </h2>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <p>
              -Durante la espera, mantén el silencio para no interrumpir la
              clase en curso.
            </p>
            <p>
              -Para garantizar un entrenamiento cómodo y seguro, te pedimos que
              respetes el espacio asignado. Evita mover los materiales de tus
              compañeras y mantén tu área organizada.
            </p>
            <p>
              -Contamos con regadera equipada con shampoo, acondicionador, gel y
              crema corporal. Trae tu toalla y el resto de tus productos de
              higiene personal.
            </p>
            <p>
              -Objetos olvidados: Repórtalos en recepción. Serán resguardados
              por 2 semanas. Después de ese plazo, los objetos no reclamados
              serán desechados.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonterreyLandingAccordion;
