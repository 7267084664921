
import {
  SET_CUSTOMERNOTIFICATIONTYPE,
  CREATE_CUSTOMERNOTIFICATIONTYPE,
  CUSTOMERNOTIFICATIONTYPES_RECEIVED,
  SET_PROPERTY_CUSTOMERNOTIFICATIONTYPE,
} from "../types/customer_notification_types";

const schema = {

}

const CustomerNotificationTypesReducer = (state, { type, payload }) => {
  switch (type) {
    case CUSTOMERNOTIFICATIONTYPES_RECEIVED:
      return { ...state, customer_notification_types: payload };
    case SET_CUSTOMERNOTIFICATIONTYPE:
      return { ...state, customer_notification_type: payload };
    case CREATE_CUSTOMERNOTIFICATIONTYPE:
      return { ...state, customer_notification_type: schema };
    case SET_PROPERTY_CUSTOMERNOTIFICATIONTYPE: {
      const { key, value } = payload;
      const customer_notification_type = { ...state.customer_notification_type };
      customer_notification_type[key] = value;
      return { ...state, customer_notification_type };
    }
    default:
      return { ...state};
  }
};

export default CustomerNotificationTypesReducer;
