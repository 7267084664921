import React, { useEffect } from "react";
import moment from "moment";
import MonterreyScheduleClass from "./MonterreyScheduleClass";

const MonterreyScheduleRow = ({ hour, days }) => {
  const renderClassesByDay = () => {
    return Object.keys(days).map(currentDay => {
      if(days[currentDay].length > 0) {
        let colClassName = 'col';
        if(currentDay === 'Sa') colClassName = 'col pe-0';
        return (
          <div className={colClassName} key={currentDay}>
            <MonterreyScheduleClass  singleClass={days[currentDay][0]} />
          </div>
        )

      } else {
        let colClassName = 'col';
        let cardClassName = 'empty-class mx-auto';
        if(currentDay === 'Sa') colClassName = 'col pe-0';
        if(currentDay === 'Sa') cardClassName = 'empty-class mx-auto';
        
        return(
          <div className={colClassName} key={currentDay}>
            <div className={cardClassName}>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className="row text-black bold class-hour__row mx-auto justify-content-between w-100">
      {renderClassesByDay()}
    </div>
  );
};

export default MonterreyScheduleRow;
