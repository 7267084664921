import React from "react";

const CancelNotice = ({ purchase, handleCancel, handleContinue }) => {
  return (
    <div>
      <p>
        ¿Estás segura que deseas cancelar tu suscripción #{purchase.purchase_id}{" "}
        a {purchase.class_package.title}?
      </p>{" "}
      <div className="row align-items-center">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            Regresar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => handleContinue(purchase)}
            className="btn w-100 btn-danger"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelNotice;
