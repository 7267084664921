
import api from './api';

const route = '/addresses';

export default {
  getAddress: () => api.get(route),
  getSingleAddress: (address_id) => api.get(`${route}/${address_id}`),
  postAddress: (address) => api.post(route, { ...address}),
  putAddress: (address) => api.put(route, { ...address}),
  deleteAddress: (Address) => api.delete(`${route}/${Address}`),
};
