
import api from './api';

const route = '/streaks';

export default {
  getStreaks: () => api.get(route),
  getAllStreaks: () => api.get(route),
  getSingleStreak: (streak_id) => api.get(`${route}/${streak_id}`),
  postStreak: (streak) => api.post(route, { ...streak}),
  putStreak: (streak) => api.put(route, { ...streak}),
  deleteStreak: (Streak) => api.delete(`${route}/${Streak}`),
};
