import { navigate } from "@reach/router";
import React from "react";
import { ReactComponent as Video } from "../../assets/icons/video.svg";
import { S3_ENDPOINT } from "../../utils";

const HomeClassTypeCard = ({ class_type, size }) => {
  const src =
    class_type.file !== null
      ? `${S3_ENDPOINT}/${class_type.file.name}.${class_type.file.type}`
      : "";
  const renderVideos = () => {
    const { videos } = class_type;
    if (videos && videos !== null) {
      return (
        <span className="class-type-videos text-white text-shadow">
          <span className="me-2">{videos.length} Videos </span>
          <Video fill="#fff" className="icon" />
        </span>
      );
    }
  };
  return (
    <div
      className={`card shadow-sm position-relative mb-5 mx-md-3 bg-image class-type-card${
        size === "lg" ? "-lg" : ""
      }`}
      style={{ backgroundImage: `url("${src}")` }}
      onClick={() => navigate(`/class_type/${class_type.class_type_id}`)}
    >
      {renderVideos()}
      <div className="class-type-meta">
        <div className="container text-center">
          <h4 className="h2 text-shadow mb-0">{class_type.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default HomeClassTypeCard;
