import React, { createContext, useReducer, useContext } from "react";
import WorkoutPlansService from "../services/WorkoutPlansService";
import WorkoutPlansReducer from "../reducers/WorkoutPlansReducer";
import {
  WORKOUTPLANS_RECEIVED,
  SET_WORKOUTPLAN,
  CREATE_WORKOUTPLAN,
  SET_PROPERTY_WORKOUTPLAN,
} from "../types/workout_plans";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  workout_plans: null,
  workout_plan: null,
};

export const WorkoutPlansContext = createContext(initialState);

export const WorkoutPlansProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WorkoutPlansReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getWorkoutPlans = () => {
    WorkoutPlansService.getWorkoutPlans()
      .then((response) => {
        const { workout_plans } = response.data;
        dispatch({ type: WORKOUTPLANS_RECEIVED, payload: workout_plans });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleWorkoutPlan = (workout_plan_id) => {
    WorkoutPlansService.getSingleWorkoutPlan(workout_plan_id)
      .then((response) => {
        const { workout_plan } = response.data;
        dispatch({ type: SET_WORKOUTPLAN, payload: workout_plan });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getWorkoutPlanByQuery = (filters) => {
    WorkoutPlansService.getWorkoutPlanByQuery(filters)
      .then((response) => {
        const { workout_plan } = response.data;
        dispatch({ type: SET_WORKOUTPLAN, payload: workout_plan });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setWorkoutPlan = (workout_plan) => {
    dispatch({ type: SET_WORKOUTPLAN, payload: workout_plan });
  };

  const createWorkoutPlan = () => {
    dispatch({ type: CREATE_WORKOUTPLAN });
  };

  const setPropertyWorkoutPlan = (key, value) => {
    dispatch({ type: SET_PROPERTY_WORKOUTPLAN, payload: { key, value } });
  };

  const saveWorkoutPlan = (workout_plan, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = WorkoutPlansService.putWorkoutPlan;
    if (isNaN(parseInt(workout_plan.workout_plan_id))) {
      service = WorkoutPlansService.postWorkoutPlan;
    }
    service(workout_plan)
      .then(() => {
        success("Workout Plan guardado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteWorkoutPlan = (workout_plan_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    WorkoutPlansService.deleteWorkoutPlan(workout_plan_id)
      .then(() => {
        success("Workout Plan eliminado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <WorkoutPlansContext.Provider
      value={{
        ...state,
        setWorkoutPlan,
        getWorkoutPlans,
        saveWorkoutPlan,
        deleteWorkoutPlan,
        createWorkoutPlan,
        getSingleWorkoutPlan,
        getWorkoutPlanByQuery,
        setPropertyWorkoutPlan,
      }}
    >
      {children}
    </WorkoutPlansContext.Provider>
  );
};
