/**
 * Locations
 */
export const LOCATIONS_RECIBIDAS = "LOCATIONS_RECIBIDAS";
export const SET_LOCATION = "SET_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const SET_PROPIEDAD_LOCATION = "SET_PROPIEDAD_LOCATION";

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_STATES = 'SET_STATES';
export const SET_CITIES = 'SET_CITIES';