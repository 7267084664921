import React, { useContext, useEffect } from "react";
import PaqueteCard from "../components/paquetes/PaquetesCard";
import { PackagesContext } from "../context/PackageContext";
import { navigate } from "@reach/router";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { PurchasesContext } from "../context/PurchasesContext";
import useBranch from "../hooks/useBranch";

const Shop = () => {
  const { user } = useContext(AuthContext);
  const { purchases, getPurchases } = useContext(PurchasesContext);
  const { paquetes, getPaquetes } = useContext(PackagesContext);
  const { branch, branchInterceptor, branches, selectBranch } = useBranch();

  useEffect(() => {
    getPaquetes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchInterceptor, branch]);

  useEffect(() => {
    if (user && user !== null) {
      getPurchases();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (Array.isArray(paquetes)) {
      handleURLParams();
    }
  }, [paquetes]);

  const handleURLParams = () => {
    const elementId = window.location.hash.slice(1);
    if (elementId && elementId !== null) {
      const nodeElement = document.getElementById(elementId);
      if (nodeElement && nodeElement !== null) {
        nodeElement.scrollIntoView();
      }
    }
  };

  const hasOffers = () => {
    if (Array.isArray(paquetes)) {
      const salePackages = paquetes.filter(
        (class_package) => !isNaN(class_package.sale_price)
      );
      if (salePackages.length > 0) {
        return (
          <a href="#sale" className="btn btn-primary">
            VER OFERTAS
          </a>
        );
      }
    }
  };

  const renderSingleClass = () => {
    if (Array.isArray(paquetes)) {
      let paquetesRender = paquetes.filter(
        (paquete) => paquete.class_amount === 1
      );
      if (paquetesRender.length === 0) {
        return <p>No hay paquetes disponibles.</p>;
      }
      if (user && user !== null) {
        if (Array.isArray(purchases)) {
          // Look for trial class
          const index = purchases.findIndex(
            (purchase) => purchase.class_package_id === 19286
          );
          /*if (index === -1) {
            paquetesRender.unshift({
              class_package_id: 19286,
              title: "Clase Presencial de Prueba",
              include_online: false,
              expiration_days: null,              
              class_amount: 1,
              price: 0,
            });
          }*/
        }
      }
      return paquetesRender.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          className="btn btn-primary bold btn-block w-100"
          action={() => {
            navigate(`/checkout/${paquete.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  const renderOnline = () => {
    if (paquetes && paquetes !== null) {
      let paquetesRender = paquetes
        .filter(
          (paquete) =>
            paquete.include_online &&
            !paquete.is_special_event &&
            paquete.class_amount === null &&
            paquete.expiration_days < 40
        )
        .sort((a, b) => (moment(a.updatedAt).isAfter(b.updatedAt) ? 1 : -1));
      if (paquetesRender.length === 0) {
        return <p>No hay paquetes disponibles.</p>;
      }
      return paquetesRender.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          action={() => {
            navigate(`/checkout/${paquete.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  const renderPresenciales = () => {
    if (paquetes && paquetes !== null) {
      let paquetesRender = paquetes.filter(
        (paquete) =>
          !paquete.is_special_event &&
          paquete.class_amount > 1
      );
      paquetesRender = paquetesRender.sort((a, b) =>
        a.class_amount < b.class_amount ? -1 : 1
      );
      if (paquetesRender.length === 0) {
        return <p>No hay paquetes disponibles.</p>;
      }
      return paquetesRender.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          className="btn btn-primary bold btn-block w-100"
          action={() => {
            navigate(`/checkout/${paquete.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  const renderEspeciales = () => {
    if (paquetes && paquetes !== null) {
      const especiales = paquetes.filter((paquete) => paquete.is_special_event);
      if (especiales.length === 0) {
        return <p className="px-3">No hay paquetes disponibles.</p>;
      }
      return especiales.map((especial) => (
        <PaqueteCard
          key={especial.class_package_id}
          paquete={especial}
          action={() => {
            navigate(`/checkout/${especial.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  const renderSale = (period, name) => {
    if (Array.isArray(paquetes)) {
      let paquetesRender = [...paquetes];
      if (isNaN(period)) {
        if (period === "year") {
          paquetesRender = paquetesRender.filter(
            ({ subscription_period, subscription_interval }) =>
              subscription_period === period || subscription_interval === 365
          );
        } else {
          paquetesRender = paquetesRender.filter(
            ({ subscription_period }) => subscription_period === period
          );
        }
      } else {
        if (period === 3) {
          paquetesRender = paquetesRender.filter(
            ({ subscription_interval }) =>
              parseInt(subscription_interval) === parseInt(period) ||
              parseInt(subscription_interval) === 120
          );
        } else if (period === 6) {
          paquetesRender = paquetesRender.filter(
            ({ subscription_interval }) =>
              parseInt(subscription_interval) === parseInt(period) ||
              parseInt(subscription_interval) === 180 ||
              parseInt(subscription_interval) === 240
          );
        } else {
          paquetesRender = paquetesRender.filter(
            ({ subscription_interval }) =>
              parseInt(subscription_interval) === parseInt(period)
          );
        }
      }
      if (paquetesRender.length > 0) {
        return (
          <div>
            <h2 id="sale" className="row pb-3 border-bottom mt-5">
              TBM {name}
            </h2>
            <div className="row">
              {paquetesRender.map((paquete) => (
                <PaqueteCard
                  key={paquete.class_package_id}
                  paquete={paquete}
                  className="btn btn-primary bold btn-block w-100"
                  action={() => {
                    navigate(`/checkout/${paquete.class_package_id}`);
                  }}
                />
              ))}
            </div>
          </div>
        );
      }
    }
  };

  const renderBranches = () => {
    if (Array.isArray(branches)) {
      return (
        <div className="btn-group w-100 my-3">
          {branches.map((currentBranch) => (
              <button
              onClick={() => selectBranch(currentBranch.branch_id)}
                className={`btn btn-${
                  branch !== null && branch.branch_id === currentBranch.branch_id ? "primary": "outline-primary"
                }`}
              >
                {currentBranch.name}
              </button>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      {renderBranches()}
      <div id="online" className="pb-2 mx-3 align-items-center">
        <h2 className="mb-0">TBM Online</h2>
      </div>
      <div className="row online-bg px-3 align-items-center">
        {renderOnline()}
      </div>
      <div className="row mx-0 pb-3 m-3 mb-0">
        <div className="col-12 col-md-6">
          <h2 className="mb-0">TBM Presencial</h2>
        </div>
        <div className="col-12 col-md-6 text-end">{hasOffers()}</div>
      </div>
      <div
        id="presenciales"
        className="row px-3 presencial-bg align-items-center"
      >
        {renderSingleClass()}
      </div>
      <h2 className="pb-2 border-bottom m-3">TBM Presencial + TBM Online</h2>
      <div className="row mx-0">{renderPresenciales()}</div>
      {renderSale(3, "Trimestral")}
      {renderSale(6, "Semestral")}
      {renderSale("year", "Anual")}
      <h2 className="pb-2 border-bottom m-3 mt-0">Paquetes Especiales</h2>
      <div className="row mx-0">{renderEspeciales()}</div>
    </div>
  );
};

export default Shop;
