import moment from "moment";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const OnlineLeaderboardRow = ({ index, customer, start_date, end_date }) => {
  const { user } = useContext(AuthContext);

  const getDailyAveragePlaybacks = (playbacks) => {
    if (playbacks > 0) {
      const diff = moment(end_date).diff(moment(start_date), "days");
      if (diff > 0) {
        return parseFloat(parseInt(playbacks) / parseInt(diff)).toFixed(2);
      }
    }
  };
  return (
    <tr
      key={customer.customer_id}
      className={
        customer.customer_id === user.customer_id ? "bg-success" : ""
      }
    >
      <td>{index + 1}</td>
      <td>{customer.name}</td>
      <td>
        {customer.city !== null && (
          <span>
            {customer.city}, {customer.country}
          </span>
        )}
      </td>
      <td>{customer.playbacks}</td>
      <td>{getDailyAveragePlaybacks(customer.playbacks)} clases</td>
    </tr>
  );
};

export default OnlineLeaderboardRow;
