import React, { useState } from "react";
import { Link } from "@reach/router";
import MobileMenuItem from "./MobileMenuItem";
import { cuenta, comprar, misclases, comunidad } from "../../utils";

const MobileMenu = ({ signOut }) => {
  const [display, setDisplay] = useState(false);

  return (
    <>
      <nav className="panel-mobile-menu navbar navbar-expand-lg navbar-light bg-light py-2 shadow-sm">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="/img/logo.png" className="logo-navbar" />
          </Link>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            onClick={() => setDisplay(!display)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <div
        className="bg-light w-100 vh-100 drawer-menu"
        style={{
          position: "absolute",
          left: display ? 0 : "-100vw",
        }}
      >
        <ul className="navbar-nav px-3 pt-4">
          <MobileMenuItem
            name="Home"
            icon="fa fa-home"
            handle="/mytbm"
            setDisplay={setDisplay}
          />
          <h3 className="ps-2 border-bottom pb-2 mt-3 mb-2">Mis Clases</h3>
          {misclases.map(({ name, href, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              href={href}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <h3 className="ps-2 border-bottom pb-2 mt-3 mb-2">Comprar</h3>
          {comprar.map(({ name, href, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              href={href}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <h3 className="ps-2 border-bottom pb-2 mt-3 mb-2">Comunidad</h3>
          {comunidad.map(({ name, href, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              href={href}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <h3 className="ps-2 border-bottom pb-2 mt-3 mb-2">Mi Cuenta</h3>
          {cuenta.map(({ name, handle }) => (
            <MobileMenuItem
              key={name}
              name={name}
              handle={handle}
              setDisplay={setDisplay}
            />
          ))}
          <li className="nav-item mobile mt-5">
            <button
              className="btn btn-link nav-link text-danger"
              onClick={signOut}
            >
              <i className="fa fa-sign-out-alt fa-flip-horizontal ps-2"></i>{" "}
              Salir
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileMenu;
