import React, { useState, useEffect, useContext } from "react";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import { LocationsContext } from "../../context/LocationsContext";
import ScheduleLocationPicker from "./ScheduleLocationPicker";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ColorLegend from "../global/ColorLegend";
import utils from "./utils";
import ScheduleTabs from "./ScheduleTabs";
import useBranch from "../../hooks/useBranch";
import "./MonterreySchedule.css";
import MonterreyScheduleHeader from "./MonterreyScheduleHeader";
import MonterreyScheduleClasses from "./MonterreyScheduleClasses";
import { useClassesByHour } from "../../hooks/schedule/useClassesByHour";
import MonterreyColorLegend from "../monterrey/MonterreyColorLegend";

const MonterreySchedule = () => {
  const [view, setView] = useState("week");
  const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);

  const { branch } = useBranch();

  const {
    days,
    update,
    end_date,
    clearSemanas,
    getSchedule,
    start_date,
    setEndDate,
    setStartDate,
  } = useContext(ClassInstructorContext);

  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  useEffect(() => {
    clearSemanas();
    fetchSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date, update, branch]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getSchedule({
        branch_id: branch.branch_id,
        start_date,
        end_date,
      });

      getLocations({
        branch_id: branch.branch_id,
        start_date,
        end_date,
      });
    }
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  const { formatClasses, morningClasses, eveningClasses, startsAm } =
    useClassesByHour();

  useEffect(() => {
    formatClasses(currentClasses);
  }, [currentClasses]);

  const renderMorningHours = () => {
    return Object.keys(morningClasses).map((hour) => {
      return (
        <div
          key={`${hour}-am`}
          className="schedule__class-hour d-flex align-items-center text-start ps-0"
        >
          {hour}
        </div>
      );
    });
  };

  const renderEveningHours = () => {
    return Object.keys(eveningClasses).map((hour) => {
      return (
        <div
          key={`${hour}-pm`}
          className="schedule__class-hour d-flex align-items-center text-start ps-0"
        >
          {hour}
        </div>
      );
    });
  };

  const renderEveningDivision = () => {
    if (startsAm) {
      return (
        <div className=" text-black schedule__class-hour d-flex align-items-center text-start bold ps-0">
          PM
        </div>
      );
    }
  };

  const renderSchedule = () => {
    const scheduleHasClasses = (
      Object.keys(eveningClasses).length > 0 ||
      Object.keys(morningClasses).length > 0
    );
    
    if(scheduleHasClasses) {
      return (
        <>
          <div className="d-flex flex-column container__time-column" style={{ width: "60px"}}>
            <div
              className="text-black bold text-start ps-0"
              style={{ height: "74px", paddingTop: "37px" }}
            >
              {startsAm ? "AM" : "PM"}
            </div>
            {renderMorningHours()}
            {renderEveningDivision()}
            {renderEveningHours()}
          </div>

          <div className="d-flex container__content flex-column">
            <MonterreyScheduleHeader startsAm={startsAm} />
            <MonterreyScheduleClasses
              morningClasses={morningClasses}
              eveningClasses={eveningClasses}
              startsAm={startsAm}
            />
          </div>
        </>
      );
    }

    return (
      <div className="row py-3">
        <p className="mb-0">No hay más clases programadas esta semana.</p>
      </div>
    )
  }

  return (
    <div className="container-fluid px-0 mb-4">
      <div className="row w-100 mx-auto me-0 align-items-center">
      
        <div className="col-12 col-md-4 mb-3 px-0">
          <ScheduleMonthSelect
            start_date={start_date}
            handleNext={handleNextMonth}
            handlePrev={handlePrevMonth}
          />
        </div>
        <div className="col-12 col-md-4 mb-3 px-0">
          <ScheduleWeekSelect
            start_date={start_date}
            handleNextWeek={handleNextWeek}
            handlePrevWeek={handlePrevWeek}
          />
        </div>
        <div className="col-12 col-md-4 mb-3 ps-0">
          <MonterreyColorLegend />
          <ScheduleLocationPicker
            locations={locations}
            modifier={setLocation}
            selectedLocation={location}
          />
        </div>
      </div>

      <div className="container-fluid schedule__container ">
        {renderSchedule()}
      </div>
    </div>
  );
};

export default MonterreySchedule;
