import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, navigate } from "@reach/router";
import LoginForm from "../components/auth/LoginForm";

const Login = () => {
  const [email, setEmail] = useState("");
  const { user, legacy, isLegacyCustomer, recoverPassword, setLegacy } =
    useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    isLegacyCustomer(email);
  };

  useEffect(() => {
    if (user !== null && !window.location.href.includes("mytbm")) {
      navigate("/mytbm");
    }
  }, [user]);

  const renderForm = () => {
    if (legacy !== null && !legacy) return <LoginForm prevEmail={email} />;
    if (legacy) {
      return (
        <div id="login-card" className="card no-scale shadow">
          <h4>¡Bienvenida de Regreso!</h4>
          <p>
            Recibirás un correo a {email} con un enlace para esteblecer tu
            contraseña.
          </p>
          <p className="mb-4">Recuerda revisar el folder de SPAM.</p>
          <button className="btn btn-primary" onClick={setLegacy}>
            Ya establecí mi contraseña
          </button>
          <button
            className="btn btn-link text-secondary"
            onClick={() => recoverPassword(email)}
          >
            Enviar de Nuevo
          </button>
        </div>
      );
    }
    return (
      <div id="login-card" className="card no-scale">
        <form onSubmit={handleSubmit}>
          <label>Correo Electrónico</label>
          <input
            type="email"
            value={email}
            className="form-control mb-3"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="btn btn-primary">
            Continuar
          </button>
        </form>
      </div>
    );
  };

  return (
    <div className="container-fluid bg-light">
      <div className="row">
        <div className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile bg-dark">
          <div className="bg-vertical"></div>
        </div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <Link to="/">
                <img
                  alt="logo"
                  src="/img/logo.png"
                  className="logo-login d-block m-auto"
                />
              </Link>
              <h1 className="text-center my-4">Entrar Ahora</h1>
              {renderForm()}
              <div className="login-card mt-4">
                <Link to="/" className="text-muted">
                  <i className="fa fa-home me-1"></i> Regresar a Inicio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
