
import {
  SET_GOAL,
  CREATE_GOAL,
  GOALS_RECEIVED,
  SET_PROPERTY_GOAL,
} from "../types/goals";

const schema = {

}

const goalsReducer = (state, { type, payload }) => {
  switch (type) {
    case GOALS_RECEIVED:
      return { ...state, goals: payload };
    case SET_GOAL:
      return { ...state, goal: payload };
    case CREATE_GOAL:
      return { ...state, goal: schema };
    case SET_PROPERTY_GOAL: {
      const { key, value } = payload;
      const goal = { ...state.goal };
      goal[key] = value;
      return { ...state, goal };
    }
    default:
      return { ...state};
  }
};

export default goalsReducer;
