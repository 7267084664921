import moment from "moment";
import React from "react";
import {
  S3_ENDPOINT,
  findExpirationRenewalDate,
  formatMonto,
} from "../../utils";
import StatusBadge from "../global/StatusBadge";

const PurchaseCard = ({ purchase, cancelSubscription }) => {
  const validClassPackage = () => {
    return purchase.class_package && purchase.class_package !== null;
  };

  const renderClassPackage = () => {
    if (validClassPackage()) {
      return purchase.class_package.title;
    }
  };

  const renderActions = () => {
    if (validClassPackage()) {
      return (
        <div className="card-footer">
          {renderFile()}
          {renderLink()}
          {renderCancel()}
        </div>
      );
    }
    return <td>{renderCancel()}</td>;
  };

  const renderFile = () => {
    if (
      purchase.class_package.downloadable &&
      purchase.class_package.downloadable !== null
    ) {
      return (
        <a
          href={`${S3_ENDPOINT}/${purchase.class_package.downloadable.name}.${purchase.class_package.downloadable.type}`}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="fa fa-file"></i>
        </a>
      );
    }
  };

  const renderLink = () => {
    if (
      purchase.class_package.file_url &&
      purchase.class_package.file_url !== null
    ) {
      return (
        <a
          target="_blank"
          href={purchase.class_package.file_url}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="fa fa-link"></i>
        </a>
      );
    }
  };

  const renderCancel = () => {
    if (purchase.subscription_id !== null) {
      if (purchase.status !== "cancelled" && purchase.status !== "revoked") {
        return (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => cancelSubscription(purchase)}
          >
            <i className="fa fa-times me-1"></i> Cancelar
          </button>
        );
      }
    }
  };

  const renderNextInvoice = () => {
    if (purchase.status === "active") {
      const next_invoice = findExpirationRenewalDate(purchase);
      return (
        <p className="small mb-0 mt-2">
          <b>Sig. Cargo:</b> {next_invoice}
        </p>
      );
    }
  };

  return (
    <div className="card px-0 py-0 shadow mb-3">
      <div className="card-header bg-light">
        <h5 className="mb-2">
          #{purchase.purchase_id} - {renderClassPackage()}
        </h5>
      </div>
      <div className="card-body">
        <p className="small mb-0">
          <b>Fecha:</b> {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
        </p>
        <p className="small mb-1">
          <b>Monto:</b> ${formatMonto(purchase.total_payment)} MXN
        </p>
        <StatusBadge status={purchase.status} />
        {renderNextInvoice()}
      </div>
      {renderActions()}
    </div>
  );
};
export default PurchaseCard;
