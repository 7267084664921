import React, { useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { PurchasesContext } from "../../context/PurchasesContext";

const CustomerUpgrade = ({ feature }) => {
  const { user } = useContext(AuthContext);
  const { clearModal } = useContext(ModalContext);
  const { purchases, getPurchases } = useContext(PurchasesContext);

  useEffect(() => {
    getPurchases();
  }, []);

  const hasFreeTrial = () => {
    if (user && user !== null) {
      if (Array.isArray(purchases)) {
        const freeTrialPurchase = purchases.find(
          ({ class_package_id }) => class_package_id === 19271
        );
        return freeTrialPurchase !== undefined;
      }
    }
  };

  const renderFreeTrialButton = () => {
    if (!has_free_trial) {
      return (
        <Link
          to="/checkout/19271"
          onClick={clearModal}
          className="btn btn-primary w-100 mb-3"
        >
          Probar 7 Días Gratis
        </Link>
      );
    }
  };

  const has_free_trial = hasFreeTrial();

  return (
    <div>
      <p>
        Para disfrutar de {feature}, necesitas una membresía activa de TBM o TBM
        Online.
      </p>
      {renderFreeTrialButton()}
      <Link
        to="/mytbm/paquetes"
        onClick={clearModal}
        className={`btn w-100 btn-${
          !has_free_trial ? "outline-primary" : "primary"
        }`}
      >
        Ir a Paquetes
      </Link>
    </div>
  );
};

export default CustomerUpgrade;
