import api from "./api";

const route = "/cancel_reasons";

export default {
  getCancelReasons: () => api.get(route),
  getSingleCancelReason: (cancelReason_id) =>
    api.get(`${route}/${cancelReason_id}`),
  postCancelReason: (cancelReason) => api.post(route, { ...cancelReason }),
  putCancelReason: (cancelReason) => api.put(route, { ...cancelReason }),
  deleteCancelReason: (CancelReason) => api.delete(`${route}/${CancelReason}`),
};
