import React from "react";
import Navbar from "../components/global/Navbar";
import Shop from "./Shop";

const Paquetes = () => {
  return (
    <div className="container-fluid px-0">
      <Navbar />
      <div className="row p-4 mt-5 pt-5">
        <Shop />
      </div>
    </div>
  );
};

export default Paquetes;
