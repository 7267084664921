import io from "socket.io-client";
import { SOCKET_URL } from "../utils";
import { useContext, useState } from "react";
import { ModalContext } from "../context/ModalContext";
import { PostsContext } from "../context/PostsContext";

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const { alert } = useContext(ModalContext);
  const { likePost, appendPost, removePost, unlikePost } =
    useContext(PostsContext);

  const setupSocket = () => {
    const currentSocket = io(SOCKET_URL, {
      transports: ["websocket"],
    });

    currentSocket.on("unlike_post", unlikePost);

    currentSocket.on("like_post", likePost);

    currentSocket.on("remove_post", removePost);

    currentSocket.on("post", handlePost);

    currentSocket.on("error", handleError);

    setSocket(currentSocket);
  };

  const handlePost = (data) => {
    appendPost(data);
  };

  const handleError = (data) => {
    alert(data);
  };

  return [socket, setupSocket];
};

export default useSocket;
