import React from "react";
import ReservationsCard from "./ReservationCard";

const ReservationsList = ({ reservations, confirmCancel }) => {
  const renderReservations = () => {
    if (Array.isArray(reservations)) {
      return reservations.map((reservation) => (
        <ReservationsCard
          reservation={reservation}
          handleCancel={confirmCancel}
          key={reservation.class_reservation_id}
        />
      ));
    }
  };
  return <div className="show-mobile">{renderReservations()}</div>;
};

export default ReservationsList;
