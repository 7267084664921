import React from "react";

const PanelGreeting = ({ handleWorkoutPlanner, handleQuote }) => {
  return (
    <div>
      <h1 className="display-5">¡Bienvenida TBM Babe!</h1>
      <p className="bold">¿Lista para alcanzar tu máximo potencial?</p>
      <p>
        Haz que cada segundo cuente con nuestra rutinas diseñadas para que
        obtengas resultados increíbles y sustentables.
      </p>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 col-xl-6 mb-2">
          <button
            onClick={handleWorkoutPlanner}
            className="btn w-100 btn-primary"
          >
            Mi Workout Planner
          </button>
        </div>
        {/*<div className="col-12 col-md-12 col-lg-12 col-xl-6 mb-2">
          <button
            onClick={handleQuote}
            className="btn w-100 bold text-uppercase btn-secondary"
          >
            Frase del Día
          </button>
          <label className="small mt-2 w-100 d-block text-center">
            El mensaje que el universo tiene para ti{" "}
            <i className="fa fa-star text-warning" />
          </label>
        </div>*/}
      </div>
    </div>
  );
};

export default PanelGreeting;
