import React from "react";
import ScheduleClass from "./ScheduleClass";
import moment from "moment";
import "./schedule.css";

const ScheduleDay = ({ day, clases, location }) => {
  const renderSingleClasses = () => {
    if (Array.isArray(clases)) {
      if (location && location !== null) {
        clases = clases.filter(
          (clase) => parseInt(clase.location_id) === parseInt(location)
        );
      }
      if (clases.length === 0) {
        return <p className="show-mobile">No hay clases programadas.</p>;
      }
      clases = clases.sort((a, b) => (a.class_date > b.class_date ? 1 : -1));
      return clases.map((clase) => (
        <ScheduleClass key={clase.single_class_id} singleClass={clase} />
      ));
    }
  };

  const renderDay = () => {
    const day_string = moment(day.date).utc().format("dd");
    switch (day_string) {
      case "Tu":
        return "MARTES";
      case "We":
        return "MIÉRCOLES";
      case "Th":
        return "JUEVES";
      case "Fr":
        return "VIERNES";
      case "Sa":
        return "SÁBADO";
      case "Su":
        return "DOMINGO";
      default:
        return "LUNES";
    }
  };

  return (
    <div className="schedule-col bg-light border text-center">
      <div>
        <div className="row p-2">
          <div className="container-fluid text-center">
            {moment(day.date).utc().format("DD")}
          </div>
        </div>
        <div className="row bg-dark text-white">
          <div className="container-fluid text-center bold">{renderDay()}</div>
        </div>
      </div>
      <div>{renderSingleClasses()}</div>
    </div>
  );
};

export default ScheduleDay;
