import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { VideosContext } from "../../context/VideosContext";
import VideoRating from "./VideoRating";
import { ModalContext } from "../../context/ModalContext";
import { formatMonto } from "../../utils";
import { PlaybacksContext } from "../../context/PlaybacksContext";

const VideoActions = ({ video }) => {
  const {
    completarVideo,
    agregarFavorito,
    eliminarFavorito,
    eliminarCompletado,
  } = useContext(VideosContext);
  const { user } = useContext(AuthContext);
  const { playback } = useContext(PlaybacksContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleRating = () => {
    modalComponent(
      "Califica tu Entrenamiento",
      <VideoRating
        video_id={video.video_id}
        handleCancel={clearModal}
        playback_id={playback.playback_id}
      />,
      { size: "lg" }
    );
  };

  const isFavorito = () => {
    if (video && video !== null) {
      const favorite = video.favorites.find(
        (favorite) => favorite.customer_id === user.customer_id
      );
      return favorite !== undefined;
    }
    return false;
  };

  const isCompleted = () => {
    if (video && video !== null) {
      return video.completed_videos.length > 0;
    }
    return false;
  };

  const renderRating = () => {
    if (video && video !== null) {
      let components = [];
      if (video.class_stars_average !== null) {
        components.push(
          <span key="class" className="me-2">
            {formatMonto(video.class_stars_average)}{" "}
            <i className="fa fa-star text-warning" />
          </span>
        );
      }
      if (video.coach_stars_average !== null) {
        components.push(
          <span key="coach" className="me-2">
            {formatMonto(video.coach_stars_average)}{" "}
            <i className="fa fa-certificate text-warning" />
          </span>
        );
      }
      return components;
    }
  };

  const renderRatingButton = () => {
    if (playback && playback !== null) {
      return (
        <button
          className={`btn btn-round shadow-sm border ms-2`}
          onClick={handleRating}
        >
          <i className="fa fa-star small"></i>
        </button>
      );
    }
  };

  return (
    <div>
      {renderRating()}
      {renderRatingButton()}
      <button
        className={`btn btn-round shadow-sm border mx-2 ${
          isCompleted() ? "btn-success" : "btn-light"
        }`}
        onClick={() => {
          if (isCompleted()) {
            eliminarCompletado(video.video_id);
          } else {
            completarVideo(video.video_id);
          }
        }}
      >
        <i className="fas fa-check"></i>
      </button>
      <button
        className={`btn btn-light btn-round border shadow-sm ${
          isFavorito() ? "text-danger" : ""
        }`}
        onClick={() => {
          if (isFavorito()) {
            eliminarFavorito(video.video_id);
          } else {
            agregarFavorito(video.video_id);
          }
        }}
      >
        <i className="fas fa-heart"></i>
      </button>
    </div>
  );
};

export default VideoActions;
