import React, { createContext, useReducer, useContext } from "react";
import CancelReasonsService from "../services/CancelReasonsService";
import CancelReasonsReducer from "../reducers/CancelReasonsReducer";
import {
  CANCELREASONS_RECEIVED,
  SET_CANCELREASON,
  CREATE_CANCELREASON,
  SET_PROPERTY_CANCELREASON,
} from "../types/cancelReasons";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  cancelReasons: null,
  cancelReason: null,
};

export const CancelReasonsContext = createContext(initialState);

export const CancelReasonsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CancelReasonsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCancelReasons = () => {
    CancelReasonsService.getCancelReasons()
      .then((response) => {
        const { cancel_reasons } = response.data;
        dispatch({ type: CANCELREASONS_RECEIVED, payload: cancel_reasons });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCancelReason = (cancelReason_id) => {
    CancelReasonsService.getSingleCancelReason(cancelReason_id)
      .then((response) => {
        const { cancel_reason } = response.data;
        dispatch({ type: SET_CANCELREASON, payload: cancel_reason });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCancelReason = (cancelReason) => {
    dispatch({ type: SET_CANCELREASON, payload: cancelReason });
  };

  const createCancelReason = () => {
    dispatch({ type: CREATE_CANCELREASON });
  };

  const setPropertyCancelReason = (key, value) => {
    dispatch({ type: SET_PROPERTY_CANCELREASON, payload: { key, value } });
  };

  const saveCancelReason = (cancelReason, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = CancelReasonsService.putCancelReason;
    if (isNaN(parseInt(cancelReason.cancelReason_id))) {
      service = CancelReasonsService.postCancelReason;
    }
    service(cancelReason)
      .then(() => {
        success("CancelReason saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCancelReason = (cancelReason_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CancelReasonsService.deleteCancelReason(cancelReason_id)
      .then(() => {
        success("CancelReason deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <CancelReasonsContext.Provider
      value={{
        ...state,
        setCancelReason,
        getCancelReasons,
        saveCancelReason,
        deleteCancelReason,
        createCancelReason,
        getSingleCancelReason,
        setPropertyCancelReason,
      }}
    >
      {children}
    </CancelReasonsContext.Provider>
  );
};
