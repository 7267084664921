import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import UserForm from "../components/user/UserForm";
import UserData from "../components/user/UserData";
import { ModalContext } from "../context/ModalContext";

const UserInformation = () => {
  const [editMode, setEditMode] = useState(false);

  const { alert } = useContext(ModalContext);
  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  const handleSubmit = (e) => {
    if (user.signup_reason === null) {
      return alert('No has llenado el campo "¿Cómo te enteraste de TBM?"');
    }
    e.preventDefault();
    setEditMode(false);
    updateUsuario(user);
  };

  const handleCancel = () => {
    setEditMode(false);
    getUsuario();
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const renderInformacion = () => {
    if (user && user !== null) {
      if (editMode) {
        return (
          <UserForm
            user={user}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            setPropiedadUser={setPropiedadUser}
          />
        );
      }
      return <UserData user={user} handleEdit={handleEdit} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="card no-scale my-4 p-4 shadow">{renderInformacion()}</div>
  );
};

export default UserInformation;
