import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCreditCard from "./StripeCreditCard";
import useBranch from "../../hooks/useBranch";

const StripeCheckout = ({ cart, element_id, discountCode }) => {
  const { branch } = useBranch();

  return (
    <div className="container-fluid position-relative h-100 px-0">
      <Elements stripe={loadStripe(branch.stripe_public)}>
        <StripeCreditCard
          cart={cart}
          element_id={element_id}
          discountCode={discountCode}
        />
      </Elements>
    </div>
  );
};

export default StripeCheckout;
