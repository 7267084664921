import React, { useContext, useEffect, useState } from "react";
import { VideoTypeContext } from "../../context/VideoTypeContext";
import { CoachesContext } from "../../context/CoachesContext";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { VideosContext } from "../../context/VideosContext";
import { FiltersContext } from "../../context/FiltersContext";
import { ProgramsContext } from "../../context/ProgramsContext";
import { useLocation } from "@reach/router";
import { durations, getValue } from "../../utils";
import SearchableMultipleSelect from "../common/SearchableMultipleSelect";
import { PropsContext } from "../../context/PropsContext";
import FilterOption from "./FilterOption";
import FilterVideoProps from "../filters/FilterVideoProps";

const FilterSection = () => {
  const [showFilters, setShowFilters] = useState(true);

  const location = useLocation();

  const filters = useContext(FiltersContext);

  const {
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    clearFilters,
    program_id,
    completed,
    setFilter,
    favorites,
    duration,
    query,
    tag_id,
    page,
  } = filters;

  const { props, getProps } = useContext(PropsContext);

  const { getClassTypes } = useContext(ClassTypeContext);

  const { video_types, getVideoTypes } = useContext(VideoTypeContext);

  const { getPrograms, getSingleProgram } = useContext(ProgramsContext);

  const { coaches, getCoaches } = useContext(CoachesContext);

  const { getClassCategories } = useContext(ClassCategoryContext);

  const {
    tags,
    getTags,
    getVideos,
    clearVideos,
    clearReload,
    reloadVideos,
    getFavoritos,
    clearFavoritos,
  } = useContext(VideosContext);

  useEffect(() => {
    if (reloadVideos) {
      fetchVideos();
      clearReload();
    }
  }, [reloadVideos]);

  useEffect(() => {
    if (!isNaN(parseInt(program_id))) {
      getSingleProgram(program_id);
    }
  }, [program_id]);

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    clearVideos();
    fetchVideos();
  }, [
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    filters.props,
    program_id,
    completed,
    favorites,
    duration,
    tag_id,
    query,
    page,
  ]);

  const fetchFilterOptions = () => {
    getTags();
    getProps();
    getCoaches();
    getPrograms();
    getClassTypes();
    getVideoTypes();
    getClassCategories();
  };

  const fetchVideos = () => {
    if (favorites) {
      getFavoritos({
        props: filters.props,
        class_category_id,
        class_type_id,
        instructor_id,
        video_type_id,
        completed,
        duration,
        tag_id,
        query,
        page,
      });
    } else {
      const { pathname } = location;
      if (pathname.includes("clases") && class_type_id) {
        clearFavoritos();
        getVideos({
          props: filters.props,
          class_category_id,
          class_type_id,
          instructor_id,
          video_type_id,
          program_id,
          completed,
          duration,
          tag_id,
          query,
          page,
        });
      }
    }
  };

  const removeProp = (prop) => {
    let currentProps = getValue(filters, "props");
    if (!Array.isArray(currentProps)) currentProps = [];
    const index = currentProps.findIndex((prop_id) => prop_id === prop.prop_id);
    if (index !== -1) {
      currentProps.splice(index, 1);
    }
    setFilter("props", currentProps);
    fetchVideos();
  };

  const renderProps = () => {
    const currentProps = getValue(filters, "props");
    if (Array.isArray(currentProps)) {
      const propsRender = props.filter((prop) =>
        currentProps.includes(prop.prop_id)
      );
      return propsRender.map((current) => (
        <span
          key={current.prop_id}
          className="badge badge-pill bg-primary me-2 mb-2"
        >
          {current.name}{" "}
          <button
            onClick={() => removeProp(current)}
            className="btn btn-sm ms-2 small p-1 py-0 text-white"
          >
            <i className="fa fa-times" />
          </button>
        </span>
      ));
    }
  };

  const renderMessage = () => {
    if (favorites || completed) {
      return (
        <p className="small mb-0 text-muted">
          Estás viendo: Tus clases {favorites ? "Favoritas" : "Completadas"}
        </p>
      );
    }
  };


  return (
    <div className="filter-card card no-scale shadow-sm">
      {showFilters && (
        <div className="row align-items-center border-bottom pb-2 mb-3">
          <div className="col-12 col-md-6">
            <label className="mb-1">Búsqueda</label>
            <input
              type="text"
              className="form-control"
              value={query}
              placeholder="Buscar por nombre de clase..."
              onChange={(e) => setFilter("query", e.target.value)}
            />
          </div>
          {/*<div className="col-12 col-md-6">
            <FilterVideoProps />
          </div>*/}
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 col-md-3 mb-2">
                <FilterOption
                  label="Coach"
                  options={coaches}
                  id_key="instructor_id"
                />
              </div>
              <div className="col-12 col-md-3 mb-2">
                <FilterOption
                  label="Duración"
                  options={durations}
                  id_key="duration"
                />
              </div>
              <div className="col-12 col-md-3 mb-2">
                <FilterOption label="Target" options={tags} id_key="tag_id" />
              </div>
              <div className="col-12 col-md-3 mb-2">
                <FilterOption
                  label="Impacto"
                  options={video_types}
                  id_key="video_type_id"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row align-items-center">
        {renderMessage()}
        <div className="col-12 col-md-12 col-xl-6">{renderProps()}</div>
        <div className="col-12 col-md-12 col-xl-6 text-right mobile-left">
          <button
            className={`btn btn-light shadow-sm border mb-1 me-2`}
            onClick={clearFilters}
          >
            <i className="fas fa-redo-alt me-1"></i> Limpiar Filtros
          </button>
          <button
            className={`btn shadow-sm border mb-1 me-2 ${
              completed ? "btn-success" : "btn-light"
            }`}
            onClick={() => setFilter("completed", !completed)}
          >
            <i className="fa fa-check me-1"></i>{" "}
            <span className="hide-mobile">Completadas</span>
          </button>
          <button
            className={`btn shadow-sm border mb-1 ${
              favorites ? "btn-danger" : "btn-light"
            }`}
            onClick={() => setFilter("favorites", !favorites)}
          >
            <i className="fa fa-heart me-1"></i>{" "}
            <span className="hide-mobile">Favoritos</span>
          </button>
          <button
            className="btn btn-light hide-desktop border mb-1 ms-2"
            onClick={() => setShowFilters(!showFilters)}
          >
            <i className="fa fa-filter"></i>{" "}
            {showFilters ? "Ocultar" : "Mostrar"} Filtros
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
