import React, { useContext } from "react";
import { PurchasesContext } from "../../context/PurchasesContext";

const ConfirmCancel = ({ reason, handleCancel }) => {
  const { spinner, purchase, cancelSubscription } =
    useContext(PurchasesContext);
  return (
    <div>
      <p>
        ¿Estás seguro que deseas cancelar tu suscripción a{" "}
        {purchase.class_package.name}?
      </p>
      <p>Esta acción NO puede deshacerse.</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            No, gracias
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => cancelSubscription(purchase.purchase_id, reason)}
            className="btn w-100 btn-danger"
            disabled={spinner}
          >
            {spinner ? <div className="spinner-border"></div> : "Cancelar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCancel;
