import { navigate } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/global/Page";
import { ProgramsContext } from "../../context/ProgramsContext";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";

const ProgramPage = ({ program_id }) => {
  const [program, setProgram] = useState(null);

  const { programs, getPrograms } = useContext(ProgramsContext);

  useEffect(() => {
    getPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(programs)) {
      const current = programs.find(
        (program) => program.program_id === parseInt(program_id)
      );
      if (current) {
        setProgram(current);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  const renderProgram = () => {
    if (program && program !== null) {
      return (
        <div className="row align-items-center">
          <div className="col-12 col-md-4">
            <img
              src={`${S3_ENDPOINT}/${program.file.name}.${program.file.type}`}
              className="mw-100 w-100 p-3"
              alt={program.name}
            />
          </div>
          <div className="col-12 col-md-8">
            <div className="card no-scale p-4 shadow">
              <p className="bold">{program.short_description}</p>
              <p>{program.description}</p>
              <p>
                *Cualquier membresía incluye acceso a todos nuestros programas
              </p>
              <div className="container-fluid px-0">
                <Link to="/checkout/149" className="btn btn-primary">
                  Suscríbete
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Page title={program !== null ? program.name : ""}>{renderProgram()}</Page>
  );
};

export default ProgramPage;
