
import api from './api';

const route = '/goals';

export default {
  getGoals: () => api.get(route),
  getSingleGoal: (goal_id) => api.get(`${route}/${goal_id}`),
  postGoal: (goal) => api.post(route, { ...goal}),
  putGoal: (goal) => api.put(route, { ...goal}),
  deleteGoal: (Goal) => api.delete(`${route}/${Goal}`),
};
