import React from "react";

const CancelBenefits = () => {
  return (
    <div className="bg-light p-md-5 p-4">
      <h2 className="display-5 text-center text-accent">
        ¡Lamentamos verte partir!
      </h2>
      <p>
        Antes de que completed el proceso, queremos recordarte los beneficios
        que perderás al cancelar tu membresía:
      </p>
      <ul className="large ps-2" style={{ listStyleType: "none" }}>
        <li className="mb-4">
          <i className="fa fa-check-circle text-primary me-2" /> Acceso a una
          biblioteca de más de 500 videos con entrenamientos divertidos,
          dinámicos y acorde a tus objetivos.
        </li>
        <li className="mb-4">
          <i className="fa fa-check-circle text-primary me-2" /> Acceso a una
          comunidad de más de 500 miembros alrededor del mundo con quien puedes
          interactuar diaramente en la comunidad TBM.
        </li>
        <li className="mb-4">
          <i className="fa fa-check-circle text-primary me-2" /> La posibilidad
          de entrenar en donde tu quieras, cuando tú quieras y cuánto tu quieras
          con TBM Online.
        </li>
      </ul>
      
    </div>
  );
};
export default CancelBenefits;
