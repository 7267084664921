
import {
  SET_NOTIFICATIONTYPE,
  CREATE_NOTIFICATIONTYPE,
  NOTIFICATIONTYPES_RECEIVED,
  SET_PROPERTY_NOTIFICATIONTYPE,
} from "../types/notification_types";

const schema = {

}

const notificationTypesReducer = (state, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONTYPES_RECEIVED:
      return { ...state, notification_types: payload };
    case SET_NOTIFICATIONTYPE:
      return { ...state, notification_type: payload };
    case CREATE_NOTIFICATIONTYPE:
      return { ...state, notification_type: schema };
    case SET_PROPERTY_NOTIFICATIONTYPE: {
      const { key, value } = payload;
      const notification_type = { ...state.notification_type };
      notification_type[key] = value;
      return { ...state, notification_type };
    }
    default:
      return { ...state};
  }
};

export default notificationTypesReducer;
