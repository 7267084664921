import React, { useEffect, useContext } from "react";
import { FiltersContext } from "../../context/FiltersContext";
import { ProgramsContext } from "../../context/ProgramsContext";

const PlannerProgram = ({ workout_plan }) => {
  const { program, getSingleProgram } = useContext(ProgramsContext);
  const { program_id, setFilter } = useContext(FiltersContext);

  useEffect(() => {
    if (workout_plan && workout_plan !== null) {
      handlePlannerLevel();
    }
  }, [workout_plan]);

  useEffect(() => {
    if (!isNaN(parseInt(program_id))) {
      getSingleProgram(program_id);
    }
  }, [program_id]);

  const handlePlannerLevel = () => {
    //Set Beginners
    if (workout_plan.pregnant) {
      setFilter("program_id", 4);
    } else if (parseInt(workout_plan.level) === 1) {
      setFilter("program_id", 1);
    } else if (
      parseInt(workout_plan.level) === 2 ||
      parseInt(workout_plan.level) === 3
    ) {
      setFilter("program_id", 2);
    } else {
      setFilter("program_id", null);
    }
  };

  const renderProgram = () => {
    if (workout_plan && workout_plan !== null) {
      if (program && program !== null) {
        return (
          <div>
            <p className="bold mb-0 text-uppercase">
              Programa Recomendado: {program.name}{" "}
              {parseInt(workout_plan.level) > 1 ? "(Intermedio-Avanzado)" : ""}
            </p>
            <p>
              Dentro de tu workout planner, tus clases estarán filtradas para{" "}
              {workout_plan.pregnant && workout_plan.pregnant !== null
                ? "prego y post parto"
                : parseInt(workout_plan.level) === 1
                ? "principiantes"
                : "intermedio o avanzado"}
              . Si quieres hacer una clase de otro nivel, puedes hacer click en{" "}
              <span className="bold">"Limpiar Filtros".</span>
            </p>
          </div>
        );
      }
    }
  };

  return <div>{renderProgram()}</div>;
};

export default PlannerProgram;
