import React from "react";
import InvoiceCard from "./InvoiceCard";

const InvoiceList = ({ invoices }) => {
  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return invoices.map((invoice) => (
        <InvoiceCard key={invoice.invoice_id} invoice={invoice} />
      ));
    }
  };
  return <div>{renderInvoices()}</div>;
};

export default InvoiceList;
