import { ClassInstructorProvider } from "./context/ClassInstructorContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { PaquetesProvider } from "./context/PackageContext";
import { UserProvider } from "./context/AuthContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import { ProgramsProvider } from "./context/ProgramsContext";
import { FiltersProvider } from "./context/FiltersContext";
import { PagesProvider } from "./context/PagesContext";
import Main from "./Main";
import "./index.css";
import "./css/custom.css";
import { combineComponents } from "./context";
import { CancelReasonsProvider } from "./context/CancelReasonsContext";
import { PostsProvider } from "./context/PostsContext";
import { ThreadsProvider } from "./context/ThreadsContext";
import { TracksProvider } from "./context/TracksContext";
import { WorkoutPlansProvider } from "./context/WorkoutPlansContext";
import { CustomerWorkoutPlansProvider } from "./context/CustomerWorkoutPlansContext";
import { GoalsProvider } from "./context/GoalsContext";
import { NutritionTipsProvider } from "./context/NutritionTipsContext";
import { QuotesProvider } from "./context/QuotesContext";
import { CustomerGoalsProvider } from "./context/CustomerGoalsContext";
import { CartProvider } from "./context/CartContext";
import { PlaybacksProvider } from "./context/PlaybacksContext";
import { AddressProvider } from "./context/AddressContext";
import { AnaliticasProvider } from "./context/AnaliticasContext";
import { StreaksProvider } from "./context/StreaksContext";
import { FrequentQuestionsProvider } from "./context/FrequentQuestionsContext";
import { PropsProvider } from "./context/PropsContext";
import { AvailableClassesProvider } from "./context/AvailableClassesContext";
import { TestimonialsProvider } from "./context/TestimonialsContext";
import { MusclesProvider } from "./context/MusclesContext";
import { NotificationsProvider } from "./context/NotificationsContext";
import { TagsProvider } from "./context/TagsContext";
import { NotificationTypesProvider } from "./context/NotificationTypesContext";
import { CustomerNotificationTypesProvider } from "./context/CustomerNotificationTypesContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { BranchesProvider } from "./context/BranchesContext";
import { Router } from "@reach/router";
import { AppConfigProvider } from "./context/AppConfigContext";
import { WaitlistsProvider } from "./context/WaitlistsContext";

const AppContextProviderWrapper = combineComponents([
  TagsProvider,
  CartProvider,
  HomeProvider,
  PropsProvider,
  PagesProvider,
  PostsProvider,
  GoalsProvider,
  QuotesProvider,
  TracksProvider,
  VideosProvider,
  MusclesProvider,
  StreaksProvider,
  ThreadsProvider,
  MetodosProvider,
  FiltersProvider,
  CoachesProvider,
  AddressProvider,
  PurchaseProvider,
  CheckoutProvider,
  ProgramsProvider,
  PaquetesProvider,
  CustomerProvider,
  LocationsProvider,
  DiscountsProvider,
  VideoTypeProvider,
  ClassTypeProvider,
  PlaybacksProvider,
  WaitlistsProvider,
  AppConfigProvider,
  AnaliticasProvider,
  TestimonialsProvider,
  WorkoutPlansProvider,
  ReservationsProvider,
  ClassCategoryProvider,
  CancelReasonsProvider,
  NutritionTipsProvider,
  CustomerGoalsProvider,
  NotificationsProvider,
  ClassInstructorProvider,
  AvailableClassesProvider,
  NotificationTypesProvider,
  CustomerWorkoutPlansProvider,
  CustomerNotificationTypesProvider,
  FrequentQuestionsProvider,
  PaymentSourcesProvider,
  BranchesProvider,
]);

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <AppContextProviderWrapper>
          <Router>
            <Main path="/*" default />
          </Router>
        </AppContextProviderWrapper>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
