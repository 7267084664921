import React from "react";

const ResetButtons = ({ goBack, resetAll }) => {
  return (
    <div className="container-fluid mt-5 pt-5 px-0">
      <button className="btn btn-light me-3 border" onClick={goBack}>
        <i className="fa fa-chevron-left me-2"></i> Regresar 1 Paso
      </button>
      <button className="btn btn-light border" onClick={resetAll}>
        <i className="fas fa-undo-alt me-2"></i> Volver a Comenzar
      </button>
    </div>
  );
};
export default ResetButtons;
