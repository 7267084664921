import React from "react";
import { S3_ENDPOINT } from "../../utils";
import ResetButtons from "./ResetButtons";

const Goal = ({ setAnswer, resetPlanner, goBack }) => {
  return (
    <div className="row align-items-center">
      <div
        className="col-12 col-md-6 bg-image"
        style={{
          backgroundImage: `url("${`${S3_ENDPOINT}/workout-planner-2.jpg`}")`,
        }}
      />
      <div className="col-12 col-md-6">
        <div className="p-md-5 p-3">
          <h2>¿CUÁL ES TU OBJETIVO?</h2>
          <p>Selecciona tu opción</p>
          <h4>PIERDE PESO</h4>
          <p>
            Baja de peso y medidas, acelera tu metabolismo y mejora tu salud.
          </p>
          <h4>COMPACTA Y TONIFICA</h4>
          <p>
            Compacta tu cuerpo, tonifica, define, disminuye grasa corporal,
            estiliza y alarga tus músculos.
          </p>
          <h4>AUMENTA MÚSCULO</h4>
          <p>
            Aumenta unos cms en lugares específicos como glúteos y piernas,
            incrementando y dándole forma a tu musculatura, If you are looking
            for some curves!
          </p>
          <div className="row mt-4">
            <div className="col-12 col-md-4 my-2 text-center">
              <button
                className="btn btn-dark d-block w-100"
                onClick={() => setAnswer("pierde")}
              >
                Pierde Peso
              </button>
            </div>
            <div className="col-12 col-md-4 my-2 text-center">
              <button
                className="btn btn-dark d-block w-100"
                onClick={() => setAnswer("compacta")}
              >
                Compacta y Tonifica
              </button>
            </div>
            <div className="col-12 col-md-4 my-2 text-center">
              <button
                className="btn btn-dark d-block w-100"
                onClick={() => setAnswer("aumenta")}
              >
                Aumenta Músculo
              </button>
            </div>
          </div>
          <ResetButtons resetAll={resetPlanner} goBack={goBack} />
        </div>
      </div>
    </div>
  );
};

export default Goal;
