
import React, { createContext, useReducer, useContext } from 'react';
import FrequentQuestionsService from '../services/FrequentQuestionsService';
import FrequentQuestionsReducer from '../reducers/FrequentQuestionsReducer';
import {
  FREQUENTQUESTIONS_RECEIVED,
} from "../types/FrequentQuestions";
import { ModalContext } from './ModalContext';

const initialState = {
  allQuestions: null,
};

export const FrequentQuestionsContext = createContext(initialState);

export const FrequentQuestionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FrequentQuestionsReducer, initialState);

  const { alert } = useContext(ModalContext);

  const getFrequentQuestions = () => {
    FrequentQuestionsService.getFrequentQuestions()
      .then((response) => {
        const { questions } = response.data;
        dispatch({ type: FREQUENTQUESTIONS_RECEIVED, payload: questions });
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <FrequentQuestionsContext.Provider
    value={{
      ...state,
      getFrequentQuestions,
    }}
  >
    {children}
  </FrequentQuestionsContext.Provider>
);
};
