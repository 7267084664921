import { LEADERBORARD_RECEIVED } from "../types/analytics";

const AnaliticasReducer = (state, { type, payload }) => {
  switch (type) {
    case LEADERBORARD_RECEIVED:
      return { ...state, leaderboard: payload };
    default:
      return { ...state };
  }
};

export default AnaliticasReducer;
