import React from "react";

const ScheduleLocationPicker = ({ locations, modifier, selectedLocation }) => {
  const renderLocations = () => {
    if (Array.isArray(locations)) {
      return [
        <option key="all" value="">
          Todos los Estudios
        </option>,
        ...locations.map((location) => (
          <option key={location.location_id} value={location.location_id}>
            {location.name}
          </option>
        )),
      ];
    }
  };

  const renderPicker = () => {
    if (Array.isArray(locations)) {
      if (locations.length > 1) {
        return (
          <div className="container-fluid text-left px-0">
            <label>Buscar por estudio</label>
            <select
              value={selectedLocation}
              className="form-control"
              onChange={(e) => modifier(e.target.value)}
            >
              {renderLocations()}
            </select>
          </div>
        );
      }
    }
  };

  return <div className="row ms-0">{renderPicker()}</div>;
};

export default ScheduleLocationPicker;
