import React from "react";
import { S3_ENDPOINT } from "../../utils";

const PlannerPregoTips = () => {
  return (
    <div className="row">
      <div className="container-fluid p-md-5 px-3 py-5 bg-light">
        <h2>
          GUÍA Y RECOMENDACIONES:{" "}
          <span>PRE Y POST NATALES EN EL EJERCICIO</span>
        </h2>
        <p>
          Según las guías del American College of Obstetricians and
          Gynecologists (ACOG) se promueve el ejercicio durante el periodo
          prenatal y postnatal con modificaciones según cambios anatómicos y
          requerimientos del bebé. En ausencia de condiciones médicas, la
          actividad física durante el embarazo es{" "}
          <span className="bold">SEGURA Y DESEADA</span>
        </p>
        <h3>Beneficios</h3>
        <div className="row">
          <div className="col-6 col-md-4 col-lg-2">
            <img
              src={`${S3_ENDPOINT}/prego-1.jpg`}
              className="mw-100 w-100 d-block m-auto mb-3 br-10"
            />
            <p>Ganancia excesiva de peso</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <img
              src={`${S3_ENDPOINT}/prego-2.jpg`}
              className="mw-100 w-100 d-block m-auto mb-3 br-10"
            />
            <p>Diabetes gestacional</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <img
              src={`${S3_ENDPOINT}/prego-3.jpg`}
              className="mw-100 w-100 d-block m-auto mb-3 br-10"
            />
            <p>Hipertensión</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <img
              src={`${S3_ENDPOINT}/prego-4.jpg`}
              className="mw-100 w-100 d-block m-auto mb-3 br-10"
            />
            <p>Parto prematuro</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <img
              src={`${S3_ENDPOINT}/prego-5.jpg`}
              className="mw-100 w-100 d-block m-auto mb-3 br-10"
            />
            <p>Bajo peso al nacer</p>
          </div>
        </div>
        <h3>Recomendaciones</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <ul>
              <li>
                Al menos 150 minutos de actividad aeróbica moderada por semana.
              </li>
              <li>
                Aquellas que son nuevas al ejercicio se recomienda que empiecen
                despacio y aumenten la actividad gradualmente.
              </li>
              <li>
                Aquellas que realizaban ejercicios de mayor intensidad previo al
                embarazo pueden continuarlos con aprobación de su doctor.
              </li>
              <li>
                Evitar posición supina (acostada boca arriba) por más de 5
                minutos.
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul>
              <li>
                Hidratarse (aproximadamente 8 onzas cada 20 minutos de
                ejercicio).
              </li>
              <li>Evitar practicar ejercicio en habitaciones calientes.</li>
              <li>
                Evitar deportes de contacto (donde puede haber golpes como
                basquetbol, soccer).
              </li>
              <li>
                Realiza nuestras clases de bajo impacto como: Barre, Sculpt N
                Burn, Glide N Tone, Yoga y Cardio (únicamente el que esté
                clasificado como "Bajo Impacto")
              </li>
            </ul>
          </div>
        </div>
        <p>
          Es de suma importancia tener la aprobación del ginecólogo previo a
          comenar una rutina de ejercicio ya que está completamente
          contraindicada en pacientes con enfermedad cardiaca, pulmonar,
          incompetencia cervical, placenta previa, entre otras condiciones.
        </p>
        <h3>Señales de Alerta</h3>
        <div className="row">
          <div className="col-6 col-md-4 col-lg-3">
            <ul>
              <li>Sangrdo Vaginal</li>
              <li>Contracciones dolorosas</li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-3">
            <ul>
              <li>Mareos</li>
              <li>Falta de Aire</li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-3">
            <ul>
              <li>Presencia de líquido amniótico</li>
              <li>Dolor en las pantorrillas o hinchazón</li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-3">
            <ul>
              <li>Dolor de cabeza</li>
              <li>Dolor de pecho</li>
            </ul>
          </div>
        </div>
        <p>
          En caso de que quieras realizar clases fuera de nuestro programa de
          PREGO & POST PARTO, ejemplo: BEGINNERS
        </p>
        <h3>Ejercicios que Debes Evitar</h3>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <img
              src={`${S3_ENDPOINT}/prego-exercises-1.jpg`}
              className="mw-100 w-100 d-block m-auto"
            />
            <p>Crunches o ejercicios que flexionan la columna.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <p>
              Planchas (cambiar a planchas en barra o plancha con rodillas en el
              piso). Aplica igual para planchas laterales.
            </p>
            <img
              src={`${S3_ENDPOINT}/prego-exercises-2.jpg`}
              className="mw-100 w-100 d-block m-auto"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <img
              src={`${S3_ENDPOINT}/prego-exercises-3.jpg`}
              className="mw-100 w-100 d-block m-auto"
            />
            <p>
              Ejercicios acostada boca arriba. Ejemplo: Crunches, elavando las
              piernas a techo, Bicicleta.
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <p>
              Cualquier posición en la que pueda ocurrir pérdida de equilibrio.
              Ejemplo: Steps subiendo silla.
            </p>
            <img
              src={`${S3_ENDPOINT}/prego-exercises-4.jpg`}
              className="mw-100 w-100 d-block m-auto"
            />
          </div>
        </div>
        <h3>Bibliografía</h3>
        <p>
          Physical Acivity and Exercise during Pregnancy and the Postpartum
          Period ACOG (2020):
          https://www.acog.org/clinical/clinical-guidance/committee-opinion/articles/2020/04/physical-activity-and-exercise-during-pregnancy-and-the-postpartum-period
        </p>
      </div>
    </div>
  );
};

export default PlannerPregoTips;
