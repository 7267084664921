import React from "react";
import LandingAboutMobile from "./LandingAboutMobile";
import LandingAboutDesktop from "./LandingAboutDesktop";

const LandingAbout = () => {
  return (
    <div id="about" className="p-4 py-5 row align-items-center">
      <div className="container-fluid pt-3 mt-5 mb-3">
        <h2 className="text-center">
          Un espacio físico y digital donde hay lugar para lograr tus metas
        </h2>
        <LandingAboutMobile />
      </div>
      <LandingAboutDesktop />
    </div>
  );
};

export default LandingAbout;
