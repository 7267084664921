import { navigate } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/global/Page";
import { CoachesContext } from "../../context/CoachesContext";
import { S3_ENDPOINT } from "../../utils";

const CoachPage = ({ coach_id }) => {
  const [coach, setCoach] = useState(null);

  const { coaches, getHomeCoaches } = useContext(CoachesContext);

  useEffect(() => {
    getHomeCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(coaches)) {
      const current = coaches.find(
        (coach) => coach.instructor_id === parseInt(coach_id)
      );
      if (current) {
        setCoach(current);
      } else {
        navigate("/");
      }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coaches]);

  const renderCoach = () => {
    if (coach && coach !== null) {
      return (
        <div className="row align-items-center">
          <div className="col-12 col-md-4">
            <img
              src={`${S3_ENDPOINT}/${coach.file.name}.${coach.file.type}`}
              className="mw-100 w-100 p-3"
              alt={coach.name}
            />
          </div>
          <div className="col-12 col-md-8">
            <div className="card no-scale p-4 shadow">
              <p>{coach.short_bio}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Page title={coach !== null ? coach.name + " " + coach.last_name : ""}>
      {renderCoach()}
    </Page>
  );
};

export default CoachPage;
