export const multimedia = {
    monterrey: {
        hero_video: "hero-video-monterrey.mp4",
        about: {
            desktop: {
                left: "landing-4.jpg",
                center: "full-power.jpeg",
                right: "landing-1.jpg",
            },
            mobile: {
                left: "full-power.jpeg",
                center: "holistic.jpeg",
                right: "landing-1.jpg",
            }
        },
        planes: {
            presencial: "tbm-presencial-thumbnail.jpg",
            online: "tbm-online-thumbnail-min.jpg"
        }
    },
    tijuana: {
        hero_video: "hero-video-tijuana.mp4",
        about: {
            desktop: {
                left: "landing-about-left-tijuana-min.jpg",
                center: "landing-fabi-tijuana.jpg",
                right: "landing-about-right-tijuana.jpg",
            },
            mobile: {
                left: "landing-4.jpeg",
                center: "full-power.jpeg",
                right: "landing-1.jpg",
            }
        },
        planes: {
            presencial: "landing-planes-presencial-tijuana.jpg",
            online: "landing-planes-online-tijuana.jpg",
        }
    }
}