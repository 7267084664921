import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import CheckoutReducer from "../reducers/CheckoutReducer";
import CheckoutService from "../services/CheckoutService";
import {
  HIDE_SPINNER,
  SET_PAQUETE,
  SHOW_SPINNER,
  SHOW_SPINNER_DESCUENTO,
  HIDE_SPINNER_DESCUENTO,
  SET_DESCUENTO,
  SET_DISCOUNT_CODE
} from "../types";
import { ModalContext } from "./ModalContext";
import PaquetesService from "../services/PaquetesService";
import DescuentosService from "../services/DescuentosService";
import { PAYPAL_URL } from "../utils/paypal";
import { SET_PAYMENTSOURCE } from "../types/PaymentSources";

const initialState = {
  payment_source: "card",
  class_package: null,
  paquete: null,
};

export const CheckoutContext = createContext(initialState);

export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CheckoutReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const setPaquete = (paquete) => {
    dispatch({ type: SET_PAQUETE, payload: paquete });
  };

  const setPaymentSource = payment_source => {
    dispatch({ type: SET_PAYMENTSOURCE, payload: payment_source });
  }

  const getPaquete = (package_id) => {
    PaquetesService.getPaquete(package_id)
      .then((res) => {
        const { class_package } = res.data;
        dispatch({ type: SET_PAQUETE, payload: class_package });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            alert(
              "Lo sentimos, no encontramos el paquete que quieres comprar."
            );
          }
        }
        alert(error);
      });
  };

  const createOrder = (paquete, paymentMethod, discountCode, cart) => {
    dispatch({ type: SHOW_SPINNER });
    CheckoutService.postCheckout(
      paquete.class_package_id,
      paymentMethod.payment_source_id,
      discountCode,
      cart
    )
      .then((res) => {
        const { payment_id } = res.data;
        success("¡Pago exitoso!");
        navigate(`/gracias/${payment_id}`);
        dispatch({ type: HIDE_SPINNER });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          const { data } = error.response;
          if (data) {
            if (data.details) {
              if (data.details[0]) {
                if (
                  data.details[0].code ===
                  "conekta.errors.processing.charge.card_payment.suspicious_behaviour"
                ) {
                  return alert(
                    "Lo sentimos, nuestro procesador de tarjetas ha rechazado el pago. Comunícate con nosotros en @thebodymethod.mx."
                  );
                } else if (data.details[0].code.includes("formatted")) {
                  return alert(
                    "El formato de la tarjeta no es válido, revisa los datos que ingresaste por favor."
                  );
                }
              }
            }
          }
          if (error.response.status === 412) {
            return alert(
              "Lo sentimos, se ha alcanzado el límite de personas para este paquete."
            );
          } else if (error.response.status === 409) {
            return alert(
              "Lo sentimos, ya has alcanzado el límite de compras de este paquete."
            );
          }
        }
      });
  };

  const setPayPalSubscription = ({
    class_package_id,
    discountCode,
    cart,
    start_time,
    branch
  }) => {
    const script = document.createElement("script");
    script.src = PAYPAL_URL(branch.paypal_client_id);
    script.id = "paypal-subscription";
    document.body.appendChild(script);
    script.onload = (result) => {
      const paypalButton = document.getElementById("paypal-button");
      if (paypalButton.innerHTML !== "") {
        paypalButton.innerHTML = "";
      }
      window.paypal
        .Buttons({
          createSubscription: function (data, actions) {
            return CheckoutService.postPayPal(
              class_package_id,
              discountCode,
              cart
            ).then((res) => {
              const { plan_id } = res.data;
              const customValues = {};
              if (start_time && start_time !== null) {
                customValues.start_time = start_time;
              }
              return actions.subscription.create({
                plan_id,
                ...customValues,
              });
            });
          },
          onApprove: function (data, actions) {
            return CheckoutService.capturePayPal({ ...data, class_package_id }).then((res) => {
              const { purchase_id } = res.data;
              success("¡Pago exitoso!");
              const paypalSubscription = document.getElementById(
                "paypal-subscription"
              );
              if (paypalSubscription !== null) {
                paypalSubscription.remove();
              }
              paypalButton.innerHTML = "";
              navigate(`/gracias/${purchase_id}`);
            });
          },
          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button");
    };
  };

  const setPayPal = (class_package_id, discountCode) => {
    const paypalButton = document.getElementById("paypal-button");
    if (paypalButton && paypalButton !== null) {
      paypalButton.innerHTML = "";
    }
    const script = document.createElement("script");
    script.src = "https://www.paypalobjects.com/api/checkout.js";
    script.id = "paypal-checkout";
    document.body.appendChild(script);
    script.onload = (result) => {
      window.paypal.Button.render(
        {
          env: "production",
          payment: (data, actions) => {
            return CheckoutService.postPayPal(class_package_id, discountCode)
              .then((res) => {
                return res.data.orderID;
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 412) {
                    return alert(
                      "Lo sentimos, se ha alcanzado el límite de personas para este paquete."
                    );
                  } else if (error.response.status === 409) {
                    return alert(
                      "Lo sentimos, ya has alcanzado el límite de compras de este paquete."
                    );
                  }
                }
              });
          },
          onApprove: (data, actions) => {
            return CheckoutService.capturePayPal(data).then(function (res) {
              const { purchase_id } = res.data;
              success("¡Pago exitoso!");
              const paypalCheckout = document.getElementById("paypal-checkout");
              if (paypalCheckout !== null) {
                paypalCheckout.remove();
              }
              paypalButton.innerHTML = "";
              navigate(`/gracias/${purchase_id}`);
            });
          },
        },
        "#paypal-button"
      );
    };
  };

  const showSpinner = () => {
    dispatch({ type: SHOW_SPINNER });
  };

  const hideSpinner = () => {
    dispatch({ type: HIDE_SPINNER });
  };

  const setDescuento = (descuento) => {
    dispatch({ type: SET_DESCUENTO, payload: descuento });
  };

  const setDiscountCode = (code) => {
    dispatch({ type: SET_DISCOUNT_CODE, payload: code });
  };

  const validarDescuento = (code, class_package_id) => {
    dispatch({ type: SHOW_SPINNER_DESCUENTO });
    DescuentosService.validarDescuento(code, class_package_id)
      .then((res) => {
        const { discount, error } = res.data;
        if (error) {
          alert(error.message);
        }
        dispatch({ type: SET_DESCUENTO, payload: discount });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER_DESCUENTO });
        dispatch({ type: SET_DESCUENTO, payload: null });
        if (error.response) {
          if (error.response.data) {
            return alert(error.response.data.message);
          }
        }
        alert("Lo sentimos, ese descuento no es válido.");
      });
  };

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setPayPal,
        getPaquete,
        setPaquete,
        createOrder,
        showSpinner,
        hideSpinner,
        setDescuento,
        setDiscountCode,
        setPaymentSource,
        validarDescuento,
        setPayPalSubscription,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
