import React from "react";

const Pagination = ({ maxPages, value, setValue }) => {

  return (
    <div className="container-fluid text-right px-0 mt-2">
      <button
        className="btn btn-light border mx-2"
        disabled={value === 0}
        onClick={() => {
          if (value > 1) {
            setValue(value - 1);
          }
        }}
      >
        <i className="fa fa-chevron-left"></i>
      </button>
      {value} / {maxPages}
      <button
        disabled={value >= maxPages}
        className="btn btn-light border mx-2"
        onClick={() => {
          if (value < maxPages) setValue(value + 1);
        }}
      >
        <i className="fa fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
