import api from "./api";

const route = "/cart";

export default {
  getCart: (email) => api.get(`${route}?email=${email}`),
  getSingleCart: (cart_id) => api.get(`${route}/${cart_id}`),
  postCart: (cart) => api.post(route, { ...cart }),
  putCart: (cart) => api.put(route, { ...cart }),
  deleteCart: (Cart) => api.delete(`${route}/${Cart}`),
};
