import { CLEAR_FILTERS, SET_FILTER, SET_MANY_FILTERS } from "../types";

const FiltersReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_FILTER:
      const { key, value } = payload;
      return { ...state, [key]: value };
    case SET_MANY_FILTERS:
      const filters = payload;
      let updatedState = { ...state }

      filters.forEach(obj => {
        updatedState = {
          ...updatedState,
          [obj.key]: obj.value
        }
      });

      return updatedState;
    case CLEAR_FILTERS:
      return { ...payload };
    default:
      return { ...state };
  }
};
export default FiltersReducer;
