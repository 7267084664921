import React, { useContext } from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";
import ItemCarousel from "../global/ItemCarousel";
import CustomerFreeTrial from "../customers/CustomerFreeTrial";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { navigate } from "@reach/router";
import useBranch from "../../hooks/useBranch";

const LandingVideo = () => {
  const { branch, content } = useBranch();
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);

  const handleFreeTrial = () => {
    if (user === null) {
      return modalComponent("Comienza Gratis", <CustomerFreeTrial />);
    }
    if(branch?.slug === "tijuana") {
      return navigate("/checkout/19310");
    }
    navigate("/checkout/19271");
  };

  return (
    <div id="video" className="row mx-0 align-items-center position-relative">
      <video
        className="mw-100 w-100 home-video"
        autoPlay
        data-autoplay
        playsInline
        loop
        muted
        data-object-fit="cover"
        poster={`${S3_ENDPOINT}/${
          branch.slug === "monterrey"
            ? "portada_tbm-min.jpeg"
            : "cover-tbm-tijuana-jpg"
        }`}
      >
        <source src={`${S3_ENDPOINT}/${content.hero_video}`} />
      </video>
      <div className="container text-center main-cta-cont">
        <h1 className="display-1 text-shadow text-white">
          THE BODY METHOD
        </h1>
        <p className="tagline text-white mb-md-5 bold">
          Entrena inteligente con nuestro método
        </p>
        <div className="show-mobile">
          <ItemCarousel
            automatic
            items={[
              <a href="#class_types" className="card mb-2 w-75 m-auto">
                <p className="bold mb-1">
                  9 clases diferentes dentro de un mismo estudio
                </p>
              </a>,
              <div className="card mb-2 w-75 m-auto">
                <p className="bold mb-1">
                  Elige tus clases de acuerdo a tus necesidades, duración,
                  target muscular o impacto.
                </p>
              </div>,
              <div className="card mb-2 w-75 m-auto">
                <p className="bold mb-1">
                  Organizamos tus clases de acuerdo a tus objetivos
                </p>
              </div>,
            ]}
          />
        </div>
        <div className="container hide-mobile mt-3 mb-md-4">
          <div className="row">
            <div className="col-12 col-lg-4">
              <a href="#class_types" className="card mb-2 no-decoration">
                <p className="bold mb-1">
                  9 clases diferentes dentro de un mismo estudio
                </p>
              </a>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card mb-2">
                <p className="bold mb-1">
                  Elige tus clases de acuerdo a tus necesidades, duración,
                  target muscular o impacto.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card mb-2">
                <p className="bold mb-1">
                  Organizamos tus clases de acuerdo a tus objetivos
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="buttons">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 px-0">
                <button
                  id="main-cta"
                  onClick={handleFreeTrial}
                  className="btn btn-pink text-shadow py-3 px-5 btn-lg br-0 bold w-100"
                >
                  PRUÉBALO 7 DÍAS GRATIS
                </button>
              </div>
              <div className="col-12 col-md-4 px-0">
                <Link
                  to="/pages/workout-planner"
                  className="btn btn-primary py-3 br-0 btn-lg w-100"
                >
                  CONOCE TU PLAN IDEAL
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingVideo;
