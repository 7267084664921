import React, { useState } from "react";
import parse from "html-react-parser";
import CustomIcon from "./CustomIcon";

const AccordionItem = ({
  id,
  icon,
  title,
  index,
  content,
  modifier,
  children,
  parentId,
  collapsed,
  controlled,
  setCollapsed,
}) => {
  const headerId = `head-${id}`;

  const renderContent = () => {
    if (content && content !== null && content !== "") {
      return parse(content);
    }
    if (children && collapsed === id) {
      return children;
    }
  };

  return (
    <div className="accordion-item text-start bg-white border shadow-sm mb-2">
      <h2
        className="boldaccordion-header align-items-center mb-0"
        id={headerId}
      >
        <button
          className={`accordion-button text-uppercase bold text-star py-0 ${
            collapsed !== id ? "collapsed" : ""
          }`}
          style={{
            fontFamily: 'Futura Condensed Extra Bold", sans-serif !important'
          }}
          type="button"
          onClick={() => {
            if (collapsed === id) return setCollapsed(null);
            setCollapsed(id);
          }}
        >
          {icon && icon !== null && icon !== "" && (
            <div className="d-inline-block me-3">
              <CustomIcon icon={icon} />
            </div>
          )}
          {title}
        </button>
      </h2>
      <div
        id={id}
        className={`accordion-collapse ${collapsed !== id ? "collapse" : ""}`}
        aria-labelledby={headerId}
        data-bs-parent={parentId}
      >
        <div className="accordion-body large text-dark text-left">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ id, icon, items }) => {
  const [collapsed, setCollapsed] = useState(null);
  const renderItems = () => {
    if (Array.isArray(items)) {
      return items.map(({ title, htmlContent, children }, index) => (
        <AccordionItem
          key={index}
          icon={icon}
          index={index}
          title={title}
          parentId={id}
          content={htmlContent}
          collapsed={collapsed}
          id={`faq-${index + 1}`}
          setCollapsed={setCollapsed}
        >
          {children}
        </AccordionItem>
      ));
    }
  };

  return (
    <div className="accordion accordion-flush" id={id}>
      {renderItems()}
    </div>
  );
};

export default Accordion;
