import React from "react";

const MonterreyColorLegend = () => {
  return (
    <div className="container-fluid px-0 mb-2">
      <div className="row w-100 mx-auto">
        <div className="col-4">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-light pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">Disponible</span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-primary-light pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">Reservada</span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-primary pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">Sold out</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MonterreyColorLegend;
