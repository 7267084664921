import React from "react";
import { S3_ENDPOINT } from "../../utils";

const Embarazada = ({ setAnswer }) => {
  return (
    <div className="row align-items-center">
      <div
        className="col-12 col-md-6 bg-image"
        style={{
          backgroundImage: `url("${`${S3_ENDPOINT}/workout-planner.jpeg`}")`,
        }}
      />
      <div className="col-12 col-md-6">
        <div className="p-md-5 p-3">
          <p>
            ¿Actualmente estás embarazada o tienes 3 meses o menos de haber
            tenido un parto?
          </p>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className="btn btn-dark w-100 px-4 my-2"
                onClick={() => setAnswer("si")}
              >
                Si
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn btn-dark w-100 px-4 my-2"
                onClick={() => setAnswer("no")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Embarazada;
