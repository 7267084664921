import { Link } from "@reach/router";
import React from "react";
import Page from "../../components/global/Page";
import { S3_ENDPOINT } from "../../utils";

const Certificaciones = () => {
  return (
    <Page title="TBM CERTIFICATIONS">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div className="p-5">
            <video className="mw-100 w-100" autoPlay muted>
              <source
                src={`${S3_ENDPOINT}/video-certificacion.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card no-scale shadow p-5">
            <h3>29 y 30 de Abril 2023</h3>
            <p>
              ¿Quieres mejorar tu técnica?, ¿Quieres ser coach?, ¿Ampliar tus
              conocimientos? ¡Esta certificación es para ti!
            </p>
            <p>Elige la modalidad que prefieras: En Línea o Presencial (Mty)</p>
            <p>¡Recuerda que nuestro cupo es limitado!</p>
            <h3>PRE VENTA $7,500 MXN</h3>
            <p>Precio Regular: $9,500 MXN</p>
            <Link to="/checkout/376" className="btn btn-primary">
              Comenzar Ahora
            </Link>
            {/*
            <a
              href="https://mpago.la/1vUwi7m"
              className="btn btn-dark border mt-3"
            >
              3 MSI con MercadoPago
            </a>
            */}
          </div>
        </div>
        <div className="container-fluid">
          <h4>¿Qué Incluye?</h4>
          <ul>
            <li>
              Teoría y practica de nuestras 3 disciplinas principales: Barre,
              Jump y Sculpt and Burns
            </li>
            <li>Conoce nuestra metodología completa.</li>
            <li>Perfecciona tu técnica.</li>
            <li>Aprende a estructurar rutinas con base a objetivos.</li>
            <li>Cuieng (Aprende como enseñar)</li>
            <li>1 mes de TBM Online ilimitado o 8 clases presenciales</li>
            <li>Manual de TBM Certification</li>
          </ul>
          <h4>¡Recuerda que nuestro cupo es limitado!</h4>
        </div>
      </div>
    </Page>
  );
};

export default Certificaciones;
