/**
 * Videos
 */
export const SINGLE_VIDEO_RECIBIDO = "SINGLE_VIDEO_RECIBIDO";
export const SET_PROPIEDAD_VIDEO = "SET_PROPIEDAD_VIDEO";
export const PROGRAMS_RECIBIDOS = "PROGRAMS_RECIBIDOS";
export const VIDEOS_RECIBIDOS = "VIDEOS_RECIBIDOS";
export const PURCHASE_NEEDED = "PURCHASE_NEEDED";
export const TAGS_RECIBIDOS = "TAGS_RECIBIDOS";
export const SET_COMPLETED = "SET_COMPLETED";
export const APPEND_VIDEOS = "APPEND_VIDEOS";
export const CLEAR_VIDEOS = "CLEAR_VIDEOS";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const SET_NONCE = "SET_NONCE";
export const SET_MAX = "SET_MAX";
/**
 * Favoritos
 */
export const FAVORITOS_RECIBIDOS = "FAVORITOS_RECIBIDOS";
export const ELIMINAR_FAVORITO = "ELIMINAR_FAVORITO";
export const AGREGAR_FAVORITO = "AGREGAR_FAVORITO";
export const CLEAR_RELOAD = "CLEAR_RELOAD";
