import React from "react";

const TrackMuscleTable = () => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light bold">
            <td>Edad</td>
            <td>Porcentaje</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>18 - 33</td>
            <td>31% - 33%</td>
          </tr>
          <tr>
            <td>36 - 55</td>
            <td>29% - 31%</td>
          </tr>
          <tr>
            <td>56 - 75</td>
            <td>27% - 30%</td>
          </tr>
          <tr>
            <td>76 - 85</td>
            <td>{"< 26%"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TrackMuscleTable;
