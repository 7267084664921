import React from "react";
import { S3_ENDPOINT } from "../../utils";

const PlannerSingleTip = ({ index, tip }) => {
  return (
    <div className="col-6 col-md-4 col-lg-3">
      <div className="position-relative">
        <img
          src={`${S3_ENDPOINT}/${tip.file.name}.${tip.file.type}`}
          className="mw-100 w-100 m-auto br-25"
        />
        <div className="nutrition-tip-image w-100 text-white text-shadow px-4 py-3">
          <h3 className="mb-0 display-5">{index + 1}</h3>
        </div>
      </div>
      <div className="my-4">{tip.description}</div>
    </div>
  );
};

export default PlannerSingleTip;
