const paypal_dev_key =
  "AX-1lDAl3DdCeZ1wa_PTH8jBry2OmlUs6lrbSp2T42FRjvuLRPRAahCZHz1_V665R-U5WINWXVVdALxd";

const paypal_live_key =
  "AU94SbddKFHy_FYksRQCQ2XZGHLOW3DVCcKrew5-egkqAWmJKfEpJSycBH5Ntydtxxj40PHJHjV2pQF-";

const paypal_client_id =
  process.env.NODE_ENV === "development" ? paypal_dev_key : paypal_live_key;

export const PAYPAL_URL = (paypal_client_id) => `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&vault=true&intent=subscription`;
