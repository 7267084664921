
import api from './api';

const route = '/payment_sources';

const PaymentSourcesService = {
  getPaymentSources: () => api.get(route),
  postPaymentSource: (payment_source) => api.post(route, { ...payment_source }),
  postStripePaymentSource: (payment_method) => api.post(`${route}/stripe`, { payment_method }),
  deletePaymentSource: (payment_source_id) =>api.delete(`${route}/${payment_source_id}`),
};

export default PaymentSourcesService;
