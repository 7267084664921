import api from "./api";

const route = "/nutrition_tips";

export default {
  getNutritionTips: (goal_id) => api.get(`${route}/${goal_id}`),
  getSingleNutritionTips: (nutrition_tip_id) =>
    api.get(`${route}/${nutrition_tip_id}`),
  postNutritionTips: (nutrition_tip) => api.post(route, { ...nutrition_tip }),
  putNutritionTips: (nutrition_tip) => api.put(route, { ...nutrition_tip }),
  deleteNutritionTips: (NutritionTips) =>
    api.delete(`${route}/${NutritionTips}`),
};
