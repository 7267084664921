import React, { createContext, useReducer, useContext } from "react";
import CustomerWorkoutPlansService from "../services/CustomerWorkoutPlansService";
import CustomerWorkoutPlansReducer from "../reducers/CustomerWorkoutPlansReducer";
import {
  SET_CUSTOMERWORKOUTPLAN,
  CREATE_CUSTOMERWORKOUTPLAN,
  CUSTOMERWORKOUTPLANS_RECEIVED,
  SET_PROPERTY_CUSTOMERWORKOUTPLAN,
} from "../types/customer_workout_plans";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { navigate } from "@reach/router";
import moment from "moment";

const initialState = {
  customer_workout_plans: null,
  customer_workout_plan: null,
};

export const CustomerWorkoutPlansContext = createContext(initialState);

export const CustomerWorkoutPlansProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    CustomerWorkoutPlansReducer,
    initialState
  );

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCustomerWorkoutPlans = () => {
    CustomerWorkoutPlansService.getCustomerWorkoutPlans()
      .then((response) => {
        const { customer_workout_plans } = response.data;
        dispatch({
          type: CUSTOMERWORKOUTPLANS_RECEIVED,
          payload: customer_workout_plans,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getActiveCustomerWorkoutPlan = () => {
    CustomerWorkoutPlansService.getActiveCustomerWorkoutPlan().then(res => {
      const { customer_workout_plan } = res.data;
      if(customer_workout_plan?.end_date === null){
        customer_workout_plan.end_date = moment(customer_workout_plan.start_date).add(1, "month").format("YYYY-MM-DDTHH:mm:ss");
      }
      dispatch({ type: SET_CUSTOMERWORKOUTPLAN, payload: customer_workout_plan });
    })
  }

  const getSingleCustomerWorkoutPlan = (customer_workout_plan_id) => {
    CustomerWorkoutPlansService.getSingleCustomerWorkoutPlan(
      customer_workout_plan_id
    )
      .then((response) => {
        const { customer_workout_plan } = response.data;
        dispatch({
          type: SET_CUSTOMERWORKOUTPLAN,
          payload: customer_workout_plan,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCustomerWorkoutPlan = (customer_workout_plan) => {
    dispatch({ type: SET_CUSTOMERWORKOUTPLAN, payload: customer_workout_plan });
  };

  const createCustomerWorkoutPlan = () => {
    dispatch({ type: CREATE_CUSTOMERWORKOUTPLAN });
  };

  const setPropertyCustomerWorkoutPlan = (key, value) => {
    dispatch({
      type: SET_PROPERTY_CUSTOMERWORKOUTPLAN,
      payload: { key, value },
    });
  };

  const saveCustomerWorkoutPlan = (customer_workout_plan, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = CustomerWorkoutPlansService.putCustomerWorkoutPlan;
    if (isNaN(parseInt(customer_workout_plan.customer_workout_plan_id))) {
      service = CustomerWorkoutPlansService.postCustomerWorkoutPlan;
    }
    service(customer_workout_plan)
      .then((res) => {
        customer_workout_plan = res.data.customer_workout_plan;
        navigate(
          `/mytbm/workout-planner/${customer_workout_plan.customer_workout_plan_id}`
        );
        success("¡Workout Planner aplicado!");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCustomerWorkoutPlan = (customer_workout_plan_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CustomerWorkoutPlansService.deleteCustomerWorkoutPlan(
      customer_workout_plan_id
    )
      .then(() => {
        success("Workout Planner eliminado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <CustomerWorkoutPlansContext.Provider
      value={{
        ...state,
        setCustomerWorkoutPlan,
        getCustomerWorkoutPlans,
        saveCustomerWorkoutPlan,
        deleteCustomerWorkoutPlan,
        createCustomerWorkoutPlan,
        getActiveCustomerWorkoutPlan,
        getSingleCustomerWorkoutPlan,
        setPropertyCustomerWorkoutPlan,
      }}
    >
      {children}
    </CustomerWorkoutPlansContext.Provider>
  );
};
