import React, { useState, useEffect, useContext } from "react";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import { LocationsContext } from "../../context/LocationsContext";
import ScheduleLocationPicker from "./ScheduleLocationPicker";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ColorLegend from "../global/ColorLegend";
import ScheduleDay from "./ScheduleDay";
import utils from "./utils";
import ScheduleTabs from "./ScheduleTabs";
import useBranch from "../../hooks/useBranch";

const Schedule = () => {
  const [view, setView] = useState("week");
  const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);

  const { branch } = useBranch();

  const {
    days,
    update,
    end_date,
    clearSemanas,
    getSchedule,
    start_date,
    setEndDate,
    setStartDate,
  } = useContext(ClassInstructorContext);

  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  useEffect(() => {
    clearSemanas();
    fetchSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date, update, branch]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getSchedule({
        branch_id: branch.branch_id,
        start_date,
        end_date,
      });

      getLocations({
        branch_id: branch.branch_id,
        start_date,
        end_date,
      });
    }
  };

  const renderDays = () => {
    if (Array.isArray(currentClasses)) {
      if (!utils.hasClases(currentClasses)) {
        return (
          <div className="row py-3">
            <p className="mb-0">No hay más clases programadas esta semana.</p>
          </div>
        );
      }
      return currentClasses.map((day) => (
        <ScheduleDay
          day={day}
          key={day.date}
          location={location}
          clases={day.details}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0 mb-4">
      <div className="row me-0 align-items-center">
        <div className="col-12 mb-3 pe-0">
          <ScheduleTabs />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ScheduleMonthSelect
            start_date={start_date}
            handleNext={handleNextMonth}
            handlePrev={handlePrevMonth}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ScheduleWeekSelect
            start_date={start_date}
            handleNextWeek={handleNextWeek}
            handlePrevWeek={handlePrevWeek}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ColorLegend />
          <ScheduleLocationPicker
            locations={locations}
            modifier={setLocation}
            selectedLocation={location}
          />
        </div>
      </div>

      <div className="container-fluid schedule-mobile-container">
        <div className="schedule-mobile-wrapper">
          <div className="row">{renderDays()}</div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
