import React from "react";
import { useContext } from "react";
import { StreaksContext } from "../../context/StreaksContext";
import { S3_ENDPOINT } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const StreakUnlocked = () => {
  const { streaks } = useContext(StreaksContext);
  const { clearModal } = useContext(ModalContext);

  const renderFirst = () => {
    if (streaks.length === 1) {
      return (
        <div>
          <p>
            Cada streak representa una semana que has logrado el objetivo de tu
            Workout Planner. Ejemplo, si elegiste tu Workout Planner para
            entrenar 3 veces por semana, cuando completes el 3er día, habrás
            desbloquado un streak.
          </p>
          <h5>¿Qué Beneficios Tienen?</h5>
          <p>
            Además de motivarte a seguir con tu proceso y sacando lo mejor de
            ti, ofrecemos una variedad de beneficios como descuentos, clases
            gratuitas, entre otros.
          </p>
          <p>Consulta la página de beneficios para más información.</p>
        </div>
      );
    }
  };

  return (
    <div>
      <p>Al completar tu clase haz logrado un nuevo streak semanal.</p>
      <p>Sigue cumpliendo con tu workout planner para obtener más beneficios</p>
      <p>
        Empuja tus límites, porque cada desafío es una oportunidad para
        reinventarte.
      </p>
      <img
        src={`${S3_ENDPOINT}/streak-${Math.ceil(streaks?.length / 4)}-${
          streaks?.length % 4
        }.png`}
        className="m-auto d-block"
        style={{
          width: 250,
          height: 250,
          objectFit: "contain",
        }}
      />
      <p className="text-center">Sigue cumpliendo con tu Workout Planner</p>
      {renderFirst()}
      <button onClick={clearModal} className="btn w-100 btn-primary">
        Continuar
      </button>
    </div>
  );
};

export default StreakUnlocked;
