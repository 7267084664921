import React from "react";

const PlanCard = ({ url, title, description, image_url }) => {
  return (
    <div className="col-12 col-md-4 text-center">
      <a
        className="card mb-3 shadow-sm no-decoration px-0 py-0 position-relative plan-card"
        href={url}
      >
        <img
          src={image_url}
          className="plan-image mw-100 w-100 d-block m-auto"
        />
        <div className="plan-meta text-white">
          <div className="container">
            <div className="plan-meta-button">
              <i className="fa fa-plus" />
            </div>
            <h3 className="mb-3">{title}</h3>
            <p className="plan-description">{description}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default PlanCard;
