
import {
  FREQUENTQUESTIONS_RECEIVED,
} from "../types/FrequentQuestions";


const FrequentQuestionsReducer = (state, { type, payload }) => {
  switch (type) {
    case FREQUENTQUESTIONS_RECEIVED:
      return { ...state, allQuestions: payload };
   
    default:
      return { ...state};
  }
};

export default FrequentQuestionsReducer;
