import React, { useContext, useEffect } from "react";
import { MusclesContext } from "../../context/MusclesContext";
import { ReactComponent as MusclesFull } from "../../assets/muscles/muscles-full.svg";

const MusclesDiagram = ({ video_muscles }) => {
  const { muscles, getMuscles } = useContext(MusclesContext);

  useEffect(() => {
    getMuscles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(video_muscles) && Array.isArray(muscles)) {
      setTimeout(() => {
        handleMuscles();
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video_muscles, muscles]);

  const getVideoMuscles = () => {
    if (Array.isArray(video_muscles)) {
      return video_muscles.map((video_muscle) => ({
        ...video_muscle,
        ...video_muscle.muscle,
      }));
    }
    return video_muscles;
  };

  const handleMuscles = () => {
    if (Array.isArray(video_muscles) && Array.isArray(muscles)) {
      const currentVideoMuscles = getVideoMuscles();
      currentVideoMuscles.forEach((muscle) => {
        const element = document.getElementById(muscle.selector);
        if (element && element !== null) {
          element.classList = "st5";
          if (muscle.primary) {
            element.classList.add("bg-primary");
          } else if (muscle.secondary) {
            element.classList.add("bg-primary-light");
          }
        }
      });
      const video_muscles_ids = currentVideoMuscles.map(
        (video_muscle) => video_muscle.muscle_id
      );
      const otherMuscles = muscles.filter(
        (muscle) => !video_muscles_ids.includes(muscle.muscle_id)
      );
      otherMuscles.forEach((muscle) => {
        const element = document.getElementById(muscle.selector);
        if (element && element !== null) {
          element.classList = "st5";
          element.classList.add("bg-light");
        }
      });
    }
  };

  return (
    <div className="row">
      <div className="container-fluid px-4">
        <span className="d-flex align-items-center">
          <div style={{ width: 20, height: 20 }} className="bg-primary d-inline-block me-2 br-5"></div>
          <span className="me-5">Primario</span>
          <div style={{ width: 20, height: 20 }} className="bg-primary-light d-inline-block me-2 br-5"></div>
          <span className="me-5">Secundario</span>
        </span>
      </div>
      <div className="container-fluid">
        <MusclesFull className="mw-100 w-100" />
      </div>
    </div>
  );
};

export default MusclesDiagram;
