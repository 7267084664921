import { useContext, useEffect } from "react";
import { FiltersContext } from "../context/FiltersContext";
import { VideoTypeContext } from "../context/VideoTypeContext";
import { CoachesContext } from "../context/CoachesContext";
import { ClassTypeContext } from "../context/ClassTypesContext";
import { ClassCategoryContext } from "../context/ClassCategoryContext";
import { VideosContext } from "../context/VideosContext";
import { TagsContext } from "../context/TagsContext";

const useFilters = () => {
  const filters = useContext(FiltersContext);
  
  const { getClassCategories } = useContext(ClassCategoryContext);
  const { getClassTypes } = useContext(ClassTypeContext);
  const { getVideoTypes } = useContext(VideoTypeContext);
  const { getCoaches } = useContext(CoachesContext);
  const { getVideos } = useContext(VideosContext);
  const { getTags } = useContext(TagsContext);

  /**
   * Define all individual properties and methods
   */
  const {
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    clearFilters,
    program_id,
    completed,
    favorites,
    duration,
    tag_id,
    props,
    query,
  } = filters;

  const isPropValid = (prop) => prop && prop !== "" && prop !== null;

  /**
   * Reload All Options
   */
  const getAllAttributes = () => {
    getTags();
    getCoaches();
    getVideoTypes();
    getClassTypes({ 
      class_category_id,
      program_id,
      tag_id
    });
    getClassCategories();
  };

  /**
   * Clear any filters
   */
  const clearAllFilters = () => {
    clearFilters();
  };

  /**
   * If query or class_type is valid, means user is using he home searchbar or is in VideoClassType and we must search videos
   * Other cases, we filter the available class types
   */
  useEffect(() => {
    if (isPropValid(query) || isPropValid(class_type_id)) {
      getVideos({
        class_category_id,
        instructor_id,
        video_type_id,
        program_id,
        completed,
        favorites,
        duration,
        tag_id,
        query,
      });
    } else {
      getClassTypes({
        class_category_id,
        instructor_id,
        video_type_id,
        program_id,
        completed,
        favorites,
        duration,
        tag_id,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    program_id,
    completed,
    favorites,
    duration,
    tag_id,
    props,
    query,
  ]);

  return { ...filters, getAllAttributes, clearAllFilters };
};

export default useFilters;
