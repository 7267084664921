import React from "react";

const PlannerGrayDay = () => {
  return (
    <div className="schedule-col text-center p-2">
      <div className="card disabled small bg-light p-2 py-4">
        <span className="my-4"></span>
        <span className="d-block my-2"></span>
      </div>
    </div>
  );
};
export default PlannerGrayDay;
