
import api from './api';

const route = '/notification_types';

export default {
  getNotificationTypes: () => api.get(route),
  getSingleNotificationType: (notificationType_id) => api.get(`${route}/${notificationType_id}`),
  postNotificationType: (notification_type) => api.post(route, { ...notification_type}),
  putNotificationType: (notification_type) => api.put(route, { ...notification_type}),
  deleteNotificationType: (NotificationType) => api.delete(`${route}/${NotificationType}`),
};
