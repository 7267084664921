import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import Estrenos from "../components/videos/Estrenos";
import VideoCard from "../components/videos/VideoCard";
import { VideosContext } from "../context/VideosContext";
import { FiltersContext } from "../context/FiltersContext";
import { ProgramsContext } from "../context/ProgramsContext";
import ClassTypesGrid from "../components/classTypes/ClassTypesGrid";
import ProgramVideoCard from "../components/programs/ProgramVideoCard";
import FilterClassType from "../components/classTypes/FilterClassType";
import { handleWorkoutPlanner } from "../utils/workout_planner";
import { CustomerWorkoutPlansContext } from "../context/CustomerWorkoutPlansContext";

const Videos = () => {
  const { videos, agregarFavorito, eliminarFavorito } =
    useContext(VideosContext);
  const { getUsuario } = useContext(AuthContext);
  const { programs, getPrograms } = useContext(ProgramsContext);
  const { query, class_category_id } = useContext(FiltersContext);
  const { customer_workout_plan, getActiveCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );

  useEffect(() => {
    getUsuario();
    getPrograms();
    getActiveCustomerWorkoutPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPrograms = () => {
    if (Array.isArray(programs) && !class_category_id) {
      return (
        <div>
          <div className="row">
            <div className="col-12 col-md-6">
              <h3 className="my-2">Programas</h3>
            </div>
            <div className="col-12 col-md-6 text-md-end">
              <button
                onClick={() => handleWorkoutPlanner(customer_workout_plan)}
                className="btn btn-primary"
              >
                Mi Workout Planner
              </button>
            </div>
          </div>

          <div className="row mb-3">
            {[
              <ProgramVideoCard
                key="Estrenos"
                customURL="/mytbm/online/estrenos"
                program={{
                  url: "estrenos",
                  name: "Estrenos",
                  icon: "iconosTBM-01.png",
                  color: "rgb(237,184,128)",
                }}
              />,
              ...programs.map((program) => (
                <ProgramVideoCard key={program.program_id} program={program} />
              )),
            ]}
          </div>
        </div>
      );
    }
  };

  const renderEstrenos = () => {
    if (!class_category_id) {
      return <Estrenos />;
    }
  };

  const renderClassTypes = () => {
    if (query === "") {
      return <ClassTypesGrid title="Tipos de Clase" />;
    }
  };

  const renderVideos = () => {
    if (Array.isArray(videos) && query !== "") {
      return videos.map((video) => (
        <VideoCard
          video={video}
          key={video.video_id}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
        />
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      {renderEstrenos()}
      <div className="container-fluid px-md-5 py-md-4 py-3 px-4">
        {renderPrograms()}
        <h3 className="mb-2 show-mobile">Filtros</h3>
        <FilterClassType />
        {renderClassTypes()}
        <div className="row">{renderVideos()}</div>
      </div>
    </div>
  );
};

export default Videos;
