import { Link } from "@reach/router";
import React from "react";
import { S3_ENDPOINT } from "../../utils";

const HomeCoachCard = ({ coach, color }) => {
  const { name, last_name, file } = coach;

  const renderFile = () => {
    if (file && file !== null) {
      return (
        <img
          alt={coach.name}
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="home-coach-img"
        />
      );
    }
  };


  const renderFounder = () => {
    if (coach.instructor_id === 2) {
      return (
        <p className="text-center text-dark bold founder w-100">
          Fundadora The Body Method
        </p>
      );
    }
  };

  return (
    <Link
      to={`/coach/${coach.instructor_id}`}
      className={`coach-column position-relative no-decoration d-block mx-3`}
    >
      {renderFounder()}
      <div className="home-coach-img-wrapper position-relative">
        <div className={`bg-image bg-coach-${color}`} />
        {renderFile()}
      </div>      
      <div className="text-center py-md-4 my-3 mt-md-4 text-dark">
        <h3>
          {name} {last_name}
        </h3>
        <p className="small">"{coach.quote}"</p>
        <div className="text-center">
        <button
          target="_blank"
          className="btn btn-sm text-primary"
          onClick={() =>
            window.open(`https://instagram.com/${coach.instagram !== null ? coach.instagram : "thebodymethodmx"}`)
          }
        >
          <i className="fab fa-instagram fa-2x" />
        </button>
        </div>
      </div>
    </Link>
  );
};

export default HomeCoachCard;
