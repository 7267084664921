import React from "react";
import BirthdateInput from "../common/BirthdateInput";
import ImageInput from "../common/ImageInput";
import { S3_ENDPOINT, getValue } from "../../utils";

const UserForm = ({ user, handleCancel, handleSubmit, setPropiedadUser }) => {
  const getSrc = () => {
    const { file } = user;
    if (file && file !== null) {
      return `${file.name}.${file.type}`;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ImageInput
        value={getSrc()}
        base_url={S3_ENDPOINT}
        modifier={(picture) => setPropiedadUser("picture", picture)}
      />
      <div className="row mt-3">
        <div className="col-12 col-md-6">
          <label>Nombre:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(user, "name")}
            onChange={(e) => setPropiedadUser("name", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Apellidos:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(user, "last_name")}
            onChange={(e) => setPropiedadUser("last_name", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Telefono Celular</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={getValue(user, "phone")}
            onChange={(e) => setPropiedadUser("phone", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Instagram:</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(user, "instagram")}
            onChange={(e) => setPropiedadUser("instagram", e.target.value)}
          />
        </div>
      </div>

      <label>Fecha de Nacimiento:</label>
      <BirthdateInput
        value={getValue(user, "birthdate")}
        modifier={(value) => setPropiedadUser("birthdate", value)}
      />

      <label>¿Cómo te enteraste de TBM?</label>
      {user.signup_reason === null && (
        <p className="text-danger small">Debes completar esta información</p>
      )}
      <select
        required
        value={getValue(user, "signup_reason")}
        className="form-control"
        onChange={(e) => setPropiedadUser("signup_reason", e.target.value)}
      >
        <option value="">Elige una opción</option>
        <option value="Instagram">Instagram</option>
        <option value="Recomendación">Recomendación</option>
        <option value="Ví el local">Ví el local</option>
        <option value="Ex Alumna">Ex Alumna</option>
        <option value="Otro">Otro</option>
      </select>

      <div className="row mt-4">
        <div className="col col-md-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
        <div className="col col-md-6">
          <button
            type="button"
            className="btn w-100 text-muted"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
