import moment from "moment";
import StreakWeek from "./StreakWeek";
import { StreaksContext } from "../../context/StreaksContext";
import React, { useEffect, useState, useContext } from "react";
import { PlaybacksContext } from "../../context/PlaybacksContext";
import { handleWorkoutPlanner } from "../../utils/workout_planner";
import { ReservationsContext } from "../../context/ReservationsContext";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";
import ProgressBar from "../common/ProgressBar";

const StreakMonth = () => {
  const [weeks, setWeeks] = useState(null);
  const [progress, setProgress] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const { getPlaybacksByDate } = useContext(PlaybacksContext);
  const { getMyReservations } = useContext(ReservationsContext);
  const { streakWeeks, setCompletedWeek } = useContext(StreaksContext);
  const { customer_workout_plan, getActiveCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );

  useEffect(() => {
    getActiveCustomerWorkoutPlan();
    getMyReservations();
    handleDates();
  }, []);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getPlaybacksByDate(startDate, endDate);
      handleWeeks();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getProgress();
  }, [streakWeeks, customer_workout_plan]);

  const getProgress = () => {
    if (Object.keys(streakWeeks).length > 0 && customer_workout_plan !== null) {
      const { frequency } = customer_workout_plan.workout_plan;
      let total = 0;
      Object.keys(streakWeeks).forEach((key) => {
        total += streakWeeks[key];
      });
      const totalFrequency = Object.keys(streakWeeks).length * frequency;
      total = ((total / totalFrequency) * 100).toFixed(2);
      setProgress(total);
    }
  };

  const handleWeeks = () => {
    const days = moment(endDate).diff(moment(startDate), "days");
    const weeks = Math.ceil(days / 7);
    setWeeks(weeks);
  };

  const handleDates = () => {
    setStartDate(
      moment().startOf("month").startOf("isoWeek").format("YYYY-MM-DD")
    );
    setEndDate(moment().endOf("month").endOf("isoWeek").format("YYYY-MM-DD"));
  };

  const renderWeeks = () => {
    let components = [];
    for (let i = 0; i < weeks; i++) {
      const start_date = moment(startDate).add(i, "week").format("YYYY-MM-DD");
      const end_date = moment(startDate)
        .add(i, "week")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
      components.push(
        <StreakWeek
          key={i}
          modifier={setCompletedWeek}
          start_date={start_date}
          end_date={end_date}
          weekIndex={i + 1}
          hideHeader
        />
      );
    }
    return components;
  };

  return (
    <div className="container py-3">
      <div className="row align-items-center">
        <div className="col-12 col-md-8">
          <h1 className="mb-0">Goal Tracker: {moment().format("MMM")}</h1>
        </div>
        <div className="col-12 col-md-4 text-end">
          <button
            onClick={() => handleWorkoutPlanner(customer_workout_plan)}
            className="btn btn-primary"
          >
            Mi Workout Planner
          </button>
        </div>
      </div>
      <div className="row my-2 mt-4 align-items-center">
        <div className="col-12 mt-4">
          <ProgressBar progress={progress} color={"secondary"} />
        </div>
      </div>
      <p>El porcentaje se calcula con base a tu Workout Planner.</p>
      <h3 className="mt-4 mb-5">Semanas</h3>
      <div className="row mb-3 mt-5">{renderWeeks()}</div>
    </div>
  );
};

export default StreakMonth;
