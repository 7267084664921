import React, { useEffect, useContext } from "react";
import ClassTypeCard from "../classTypes/ClassTypeCard";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { FiltersContext } from "../../context/FiltersContext";
import { Link } from "@reach/router";

const PlannerCategory = ({ className, hideButtons, handleClick, class_category_id }) => {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { setFilter } = useContext(FiltersContext);

  useEffect(() => {
    setFilter("class_category_id", class_category_id);
    getClassTypes({
      class_category_id,
    });
  }, [class_category_id]);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      let class_types_render = class_types.filter(
        (class_type) => class_type.file !== null
      );
      if (class_types_render.length > 0) {
        return class_types_render.map((class_type) => (
          <div
            key={class_type.class_type_id}
            className={
              className ? className : "col-12 col-md-6 col-lg-4 col-xl-3"
            }
          >
            <ClassTypeCard
              key={class_type.class_type_id}
              handleClick={handleClick}
              class_type={class_type}
              noAction={hideButtons}
            />
          </div>
        ));
      }
    }
  };

  const renderMorningBoost = () => {
    if (Array.isArray(class_types)) {
      let morning = class_types.find((type) => type.name === "Morning Boost");
      if (morning) {
        return (
          <div>
            <b>Recomendaciones para MORNING BOOST</b>
            <ul className="ps-3">
              <li>Realiza tu clase en ayunas.</li>
              <li>
                Puedes realizarla <b>además</b> de tu clase recomendada,{" "}
                <b>cualquier día.</b>
              </li>
              <li>
                Puedes realizarla <b>en lugar de</b> tu clase recomendada,{" "}
                <b>1 vez por semana.</b>
              </li>
            </ul>
          </div>
        );
      }
    }
  };

  const renderButtons = () => {
    if (!hideButtons) {
      return (
        <div>
          <Link
            to={`/mytbm/class_category/${class_category_id}`}
            className="btn btn-primary me-2 mb-2"
          >
            Ver Todas las Categorías
          </Link>
          <button className="btn btn-secondary mb-2 bold text-uppercase">
            <i className="fa fa-random me-1" /> Clase Sorpresa
          </button>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="row mb-3">{renderClassTypes()}</div>
      {renderMorningBoost()}
      {renderButtons()}
    </div>
  );
};

export default PlannerCategory;
