import React, { useContext, useEffect, useState } from "react";
import WorkoutPlannerCover from "../../components/planner/WorkoutPlannerCover";
import { WorkoutPlansContext } from "../../context/WorkoutPlansContext";
import Experiencia from "../../components/planner/Experiencia";
import Embarazada from "../../components/planner/Embarazada";
import Goal from "../../components/planner/Goal";
import Days from "../../components/planner/Days";
import Page from "../../components/global/Page";
import { navigate } from "@reach/router";

const WorkoutPlanner = () => {
  const [experiencia, setExperiencia] = useState("");
  const [pregnant, setPregnant] = useState("");
  const [initial, setInitial] = useState(true);
  const [goal, setGoal] = useState("");
  const [days, setDays] = useState("");

  const { workout_plan, setWorkoutPlan, getWorkoutPlanByQuery } =
    useContext(WorkoutPlansContext);

  useEffect(() => {
    return () => {
      setWorkoutPlan(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (experiencia !== "" || (days !== "" && pregnant === "si")) {
      const payload = {
        goal_id:
          pregnant === "si"
            ? 4
            : goal === "pierde"
            ? 1
            : goal === "compacta"
            ? 2
            : 3,
        level:
          pregnant === "si" ? null : experiencia === "principiante" ? 1 : 2,
        pregnant: pregnant === "si",
        frequency: parseInt(days),
      };
      getWorkoutPlanByQuery(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, experiencia]);

  useEffect(() => {
    if (workout_plan && workout_plan !== null) {
      navigate(`/pages/workout-planner-result/${workout_plan.workout_plan_id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout_plan]);

  const resetPlanner = () => {
    setInitial(true);
    setExperiencia("");
    setPregnant("");
    setGoal("");
    setDays("");
  };

  const renderStep = () => {
    if (initial) {
      return <WorkoutPlannerCover onClick={() => setInitial(false)} />;
    }
    if (pregnant === "") {
      return <Embarazada setAnswer={setPregnant} />;
    }
    if (goal === "" && pregnant === "no") {
      return (
        <Goal
          setAnswer={setGoal}
          goBack={() => setPregnant("")}
          resetPlanner={resetPlanner}
        />
      );
    }
    if (days === "") {
      return (
        <Days
          setAnswer={setDays}
          resetPlanner={resetPlanner}
          goBack={() => {
            setGoal("");
            if (pregnant === "si") setPregnant("");
          }}
        />
      );
    }
    if (experiencia === "" && pregnant !== "si") {
      return (
        <Experiencia
          setAnswer={setExperiencia}
          resetPlanner={resetPlanner}
          goBack={() => setDays("")}
        />
      );
    }
  };

  return (
    <Page fluid fullHeight>
      <div className="row">
        <div id="workout-planner" className="container-fluid px-0">
          {renderStep()}
        </div>
      </div>
    </Page>
  );
};

export default WorkoutPlanner;
