import React, { createContext, useReducer, useContext } from "react";
import TracksService from "../services/TracksService";
import TracksReducer from "../reducers/TracksReducer";
import {
  TRACKS_RECEIVED,
  SET_TRACK,
  CREATE_TRACK,
  SET_PROPERTY_TRACK,
} from "../types/tracks";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import AdjuntosService from "../services/AdjuntosService";
import { navigate } from "@reach/router";

const initialState = {
  tracks: null,
  track: null,
};

export const TracksContext = createContext(initialState);

export const TracksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TracksReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getTracks = () => {
    TracksService.getTracks()
      .then((response) => {
        const { tracks } = response.data;
        dispatch({ type: TRACKS_RECEIVED, payload: tracks });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleTrack = (track_id) => {
    TracksService.getSingleTrack(track_id)
      .then((response) => {
        const { track } = response.data;
        dispatch({ type: SET_TRACK, payload: track });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setTrack = (track) => {
    dispatch({ type: SET_TRACK, payload: track });
  };

  const createTrack = () => {
    dispatch({ type: CREATE_TRACK });
  };

  const setPropertyTrack = (key, value) => {
    dispatch({ type: SET_PROPERTY_TRACK, payload: { key, value } });
  };

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    alert(error);
  };

  const handleSuccess = () => {
    success("Entrada guardada.");
    navigate("/mytbm/tracks");
    dispatch({ type: HIDE_SPINNER });
  };

  const saveTrack = (track) => {
    dispatch({ type: SHOW_SPINNER });
    let service = TracksService.putTrack;
    if (isNaN(parseInt(track.track_id))) {
      service = TracksService.postTrack;
    }
    const result = [];
    const promises = [];
    let existingFiles = [];
    if (track.files && track.files !== null) {
      if (track.files.length > 0) {
        const filesUpload = Array.from(track.files).filter(
          (file) => !file.file_id
        );
        existingFiles = Array.from(track.files).filter((file) => file.file_id);
        filesUpload.forEach((file) => {
          const formData = AdjuntosService.getFormData(file);
          const currentPromise = new Promise((resolve, reject) => {
            AdjuntosService.postAdjunto(formData).then((res) => {
              result.push(res.data.file_id);
              resolve();
            });
          });
          promises.push(currentPromise);
        });
      }
    }
    Promise.all(promises).then(() => {
      track.files = [...result, ...existingFiles];
      service(track).then(handleSuccess).catch(handleError);
    });
  };

  const deleteTrack = (track_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    TracksService.deleteTrack(track_id)
      .then(() => {
        success("Entrada eliminada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <TracksContext.Provider
      value={{
        ...state,
        setTrack,
        getTracks,
        saveTrack,
        deleteTrack,
        createTrack,
        getSingleTrack,
        setPropertyTrack,
      }}
    >
      {children}
    </TracksContext.Provider>
  );
};
