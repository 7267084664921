import React, { useContext, useEffect } from "react";
import { CustomerGoalsContext } from "../../context/CustomerGoalsContext";
import SingleCustomerGoal from "./SingleCustomerGoal";
import CustomerGoalForm from "./CustomerGoalForm";
import { ModalContext } from "../../context/ModalContext";

const CustomerGoals = () => {
  const {
    customer_goal,
    customer_goals,
    setCustomerGoal,
    getCustomerGoals,
    createCustomerGoal,
    deleteCustomerGoal,
  } = useContext(CustomerGoalsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getCustomerGoals();
  }, []);

  const handleForm = () => {
    modalComponent(
      "Editar Meta",
      <CustomerGoalForm
        handleCancel={handleCancel}
        customer_goal={customer_goal}
        handleCallback={getCustomerGoals}
      />
    );
  };

  const handleDelete = (customer_goal) => {
    modalComponent(
      "Eliminar Meta",
      <div>
        <p>
          ¿Estás segura que quieres eliminar la meta {customer_goal.name}? Esta
          acción NO puede deshacerse.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() =>
                deleteCustomerGoal(
                  customer_goal.customer_goal_id,
                  getCustomerGoals
                )
              }
              className="btn btn-danger w-100"
            >
              <i className="fa fa-trash me-2" />
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleEdit = (customer_goal) => {
    setCustomerGoal(customer_goal);
    handleForm();
  };

  const handleCancel = () => {
    setCustomerGoal(null);
    clearModal();
  };

  const handleCreate = () => {
    createCustomerGoal();
    handleForm();
  };

  const renderCustomerGoals = () => {
    if (Array.isArray(customer_goals)) {
      if (customer_goals.length === 0) {
        return (
          <p className="small mb-0">
            No has agregado ninguna meta.
            <br />
            <span className="small">
              Ejemplos de metas: "Dominar 1 burpee", "Leer 1 libro este mes",
              "Entrenar 4 veces por semana".
            </span>
          </p>
        );
      }
      return customer_goals.map((customer_goal) => (
        <SingleCustomerGoal
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          customer_goal={customer_goal}
          handleCallback={getCustomerGoals}
          key={customer_goal.customer_goal_id}
        />
      ));
    }
  };
  return (
    <div className="container-fluid px-0">
      <div className="row mb-2 pb-2 border-bottom align-items-center">
        <div className="col-8">
          <h4 className="mb-0">Mis Metas</h4>
        </div>
        <div className="col-4 text-end">
          <button onClick={handleCreate} className="btn btn-primary">
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>

      {renderCustomerGoals()}
    </div>
  );
};

export default CustomerGoals;
