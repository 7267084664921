import { Link } from "@reach/router";
import React from "react";
import { S3_ENDPOINT } from "../../utils";

const ProgramCard = ({ program }) => {
  const { program_id, name, short_description, file } = program;
  return (
    <Link
      to={`/program/${program_id}`}
      className="col-12 col-md-4 col-lg-3 no-decoration position-relative"
    >
      <div
        className="card bg-image shadow-sm text-center program-card"
        style={{
          backgroundImage: `url("${S3_ENDPOINT}/${file.name}.${file.type}")`,
        }}
      >
        <div className="program-meta p-3 text-white">
          <h4 className="program-title">{name}</h4>
          <p className="program-description">{short_description}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProgramCard;
