
import {
  SET_BRANCH,
  CREATE_BRANCH,
  BRANCHES_RECEIVED,
  SET_PROPERTY_BRANCH,
  SET_BRANCH_INTERCEPTOR,
  SET_INTERCEPTOR,
} from "../types/branches";
import { multimedia } from "../utils/multimedia";

const schema = {
  name: '',
  description: ''
}

const branchesReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_INTERCEPTOR:
      return { ...state, interceptor: payload };
    case BRANCHES_RECEIVED:
      return { ...state, branches: payload };
    case SET_BRANCH:
      let content = multimedia[payload.slug];
      if(!content) content = multimedia.monterrey;
      return { ...state, branch: payload, content };
    case CREATE_BRANCH:
      return { ...state, branch: schema };
    case SET_BRANCH_INTERCEPTOR:
      return { ...state, branchInterceptor: payload };
    case SET_PROPERTY_BRANCH: {
      const { key, value } = payload;
      const branch = { ...state.branch };
      branch[key] = value;
      return { ...state, branch };
    }
    default:
      return { ...state};
  }
};

export default branchesReducer;
