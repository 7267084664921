import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { ClassTypeContext } from "../context/ClassTypesContext";
import { ProgramsContext } from "../context/ProgramsContext";
import { FiltersContext } from "../context/FiltersContext";
import ClassTypeCard from "../components/classTypes/ClassTypeCard";
import OnlineHeader from "../components/global/OnlineHeader";
import FilterClassType from "../components/classTypes/FilterClassType";
import { Link } from "@reach/router";
import CustomerUpgrade from "../components/customers/CustomerUpgrade";
import { ModalContext } from "../context/ModalContext";

const VideoProgram = ({ program_url }) => {
  const { modalComponent } = useContext(ModalContext);
  const { class_types } = useContext(ClassTypeContext);
  const { user, getUsuario } = useContext(AuthContext);
  const { program_id, setFilter } = useContext(FiltersContext);
  const { program, getSingleProgram } = useContext(ProgramsContext);

  useEffect(() => {
    getUsuario();
    getSingleProgram(program_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (program_id === null) {
      getSingleProgram(program_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program_id]);

  useEffect(() => {
    if (program !== null) {
      setFilter("program_id", program.program_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  useEffect(() => {
    if (!user.has_access) {
      modalComponent(
        "Suscríbete a TBM",
        <CustomerUpgrade feature="Clases Online" />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getTitle = () => {
    if (program && program !== null) {
      return program.name;
    }
  };

  const renderClassTypes = () => {
    if (!user.has_access) {
      return (
        <div className="container-fluid">
          <p>
            Lo sentimos, no cuentas con una membresía activa para TBM Online
          </p>
          <Link className="btn btn-primary" to="/mytbm/paquetes">
            Ir a Paquetes
          </Link>
        </div>
      );
    }
    if (Array.isArray(class_types)) {
      let class_types_render = class_types.filter(
        (class_type) => class_type.file !== null
      );
      if (class_types_render.length > 0) {
        return class_types_render.map((class_type) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <ClassTypeCard
              key={class_type.class_type_id}
              class_type={class_type}
            />
          </div>
        ));
      }
    }
  };

  return (
    <div className="container-fluid px-md-5">
      <OnlineHeader title={getTitle()} backEnabled />
      {program_id !== null && <FilterClassType />}
      <h3>Tipos de Clases</h3>
      <div className="row mb-3">{renderClassTypes()}</div>
    </div>
  );
};

export default VideoProgram;
