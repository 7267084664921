
import api from './api';

const route = '/tags';

export default {
  getTags: () => api.get(route),
  getSingleTag: (tag_id) => api.get(`${route}/${tag_id}`),
  postTag: (tag) => api.post(route, { ...tag}),
  putTag: (tag) => api.put(route, { ...tag}),
  deleteTag: (Tag) => api.delete(`${route}/${Tag}`),
};
