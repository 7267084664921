import React, { useContext, useEffect, useState } from "react";
import LeaderboardTable from "../components/leaderboard/LeaderboardTable";
import OnlineLeaderboardTable from "../components/leaderboard/OnlineLeaderboardTable";
import moment from "moment";
import { AnaliticasContext } from "../context/AnaliticasContext";

const Leaderboards = () => {
  const [view, setView] = useState("online");

  const start_date = moment().startOf("month").format("YYYY-MM-DD");
  const end_date = moment().endOf("month").format("YYYY-MM-DD");

  const { leaderboard, getLeaderboard, getOnlineLeaderboard } =
    useContext(AnaliticasContext);

  useEffect(() => {
    if (view === "presenciales") {
      getLeaderboard({ start_date, end_date });
    } else {
      getOnlineLeaderboard({ start_date, end_date });
    }
  }, [view]);

  const renderLeaderboard = () => {
    if (view === "presenciales") {
      return <LeaderboardTable customers={leaderboard} />;
    }
    return (
      <OnlineLeaderboardTable
        end_date={end_date}
        start_date={start_date}
        customers={leaderboard}
      />
    );
  };

  return (
    <div className="container-fluid px-4 px-md-5 py-3">
      <div className="row mx-0 align-items-center mb-3 pb-3 border-bottom">
        <div className="col col-md-6 px-0">
          <h1 className="h2 bold mb-0">Leaderboard</h1>
        </div>
        <div className="col col-md-6 px-0 text-end">
          <div className="btn-group">
            <button
              onClick={() => setView("online")}
              className={`btn btn-${
                view === "online" ? "primary" : "outline-primary"
              }`}
            >
              Online
            </button>
            <button
              onClick={() => setView("presenciales")}
              className={`btn btn-${
                view === "presenciales" ? "primary" : "outline-primary"
              }`}
            >
              Presenciales
            </button>
          </div>
        </div>
      </div>
      <div className="card p-3 no-scale shadow">{renderLeaderboard()}</div>
    </div>
  );
};

export default Leaderboards;
