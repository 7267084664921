import React from "react";
import Page from "./Page";

const Ubicacion = () => {
  return (
    <Page title="Ubicación">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div className="card no-scale shadow">
            <h2 className="border-bottom pb-2 mb-3">Plaza Vía 02</h2>
            <p>
              Blvd. Rogelio Cantú Gómez 1000 3er. Piso, Las Lajas, 64638
              Monterrey, N.L., México
            </p>
            <p>
              <i className="fa fa-phone"></i> 811 321 2423
            </p>

            <p>
              <i className="fa fa-envelope"></i> thebodymethodmty@gmail.com
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 p-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.209501510948!2d-100.382471384357!3d25.697486683665865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629646f0530ea7%3A0x78a7a68096a32786!2sThe%20Body%20Method!5e0!3m2!1sen!2smx!4v1641451170676!5m2!1sen!2smx"
            width="100%"
            height="500px"
            className="border-0"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </Page>
  );
};

export default Ubicacion;
