import React from "react";

const TimeInput = ({ value, modifier, hideLabel }) => {
  const handleChangeHour = (hour) => {
    let hourString = String(hour);
    if (hourString.length === 1) {
      hourString = `0${hourString}`;
    } else if (hourString.length > 2) {
      if (hourString[0] === "0") {
        hourString = hourString.substr(1);
      } else {
        hourString = hourString.substr(0, 2);
      }
    }
    if (parseInt(hourString) > 23) hourString = "23";
    if (parseInt(hourString) < 0) hourString = "0";
    const minutes = String(value).split(":")[1];
    modifier(`${hourString}:${minutes}`);
  };

  const handleChangeMinutes = (minutes) => {
    let minuteString = String(minutes);
    if (minuteString.length === 1) {
      minuteString = `0${minuteString}`;
    } else if (minuteString.length > 2) {
      if (minuteString[0] === "0") {
        minuteString = minuteString.substr(1);
      } else {
        minuteString = minuteString.substr(0, 2);
      }
    }
    if (parseInt(minuteString) > 59) minuteString = "23";
    if (parseInt(minuteString) < 0) minuteString = "0";
    const hours = String(value).split(":")[0];
    modifier(`${hours}:${minuteString}`);
  };

  return (
    <div className="container-fluid">
     {!hideLabel && <label className="mb-1">Hora (0h - 23h)</label>}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row align-items-center">
            <div className="col-10">
              <input
                type="number"
                className="form-control"
                value={String(value).split(":")[0]}
                onChange={(e) => handleChangeHour(e.target.value)}
                max={23}
                min={0}
              />
            </div>
            <div className="col-2 px-0">h</div>
          </div>
        </div>
        <div className="col-6">
          <div className="row align-items-center">
            <div className="col-10">
              <input
                type="number"
                className="form-control"
                value={String(value).split(":")[1]}
                max={59}
                min={0}
                onChange={(e) => handleChangeMinutes(e.target.value)}
              />
            </div>
            <div className="col-2 px-0">min</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeInput;
