import React from "react";
import { S3_ENDPOINT } from "../../utils";
import useBranch from "../../hooks/useBranch";
import ItemCarousel from "../global/ItemCarousel";

const LandingAboutMobile = () => {
  const { content } = useBranch();

  return (
    <div className="show-mobile">
      <ItemCarousel
        automatic
        interval={6000}
        items={[
          <div
            className="bg-image br-25 overflow-hidden"
            style={{
              height: 500,
              backgroundImage: `url('${S3_ENDPOINT}/${content.about.mobile.left}')`,
            }}
          >
            <div className="row align-items-center h-100">
              <div className="container text-white p-5">
                <h2 className="h1 display-5 futura text-sentence text-center text-shadow">
                  Everything <br />
                  You Want <br />
                  in a Workout
                </h2>
                <p className="text-shadow bold">
                  Conecta contigo y siéntete viva a través del ejercicio.
                </p>
              </div>
            </div>
          </div>,
          <div
            className="bg-image br-25 overflow-hidden"
            style={{
              height: 500,
              backgroundImage: `url('${S3_ENDPOINT}/${content.about.mobile.center}')`,
            }}
          >
            <div className="row align-items-center mx-0 px-2 h-100 bg-overlay">
              <div className="container text-white p-5 pe-4">
                <p>
                  Únete a esta comunidad en donde nos inspiramos y empoderamos a
                  la mujer a través del movimiento.
                </p>
                <br />
                <p>
                  Cambia tu vida de manera positiva con nuestro método fitness
                  único respaldado por la ciencia.
                </p>
              </div>
            </div>
          </div>,
          <div
            className="bg-image br-25 overflow-hidden"
            style={{
              height: 500,
              backgroundImage: `url('${S3_ENDPOINT}/${content.about.mobile.right}')`,
            }}
          >
            <div className="row align-items-center mx-0 px-2 h-100 bg-overlay">
              <div className="container text-white p-5 pe-4">
                <p>
                  Haz que cada segundo cuente con nuestras rutinas divertidas y
                  estratégicamente planeadas
                </p>
                <p>
                  Organiza tus clases de acuerdo a tus objetivos, días de
                  entrenamiento y experiencia fitness.
                </p>
              </div>
            </div>
          </div>,
        ]}
      />
    </div>
  );
};

export default LandingAboutMobile;