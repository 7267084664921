import React, { useContext, useEffect } from "react";
import "./MonterreyFooter.css";
import { Link } from "@reach/router";

const MonterreyFooter = () => {
  return (
    <div className="container-fluid monterrey-footer bg-white position-relative">
      <div className="footer__top">
        <h2 className="mb-0">THE BODY METHOD</h2>

        <div className="d-flex">
          <i className="fab fa-instagram" />
          <i className="fab fa-tiktok" />
          <i className="fab fa-youtube" />
        </div>
      </div>

      <div className="footer__info row">
        <div className="col-12 col-md-4 text-center">
          <i className="fas fa-phone-alt" />
          <p className="info__title">Teléfono</p>
          <p className="info__description">812 515 9100</p>
        </div>
        <div className="col-12 col-md-4 text-center">
          <i className="far fa-envelope" />
          <p className="info__title">Email</p>
          <p className="info__description">hola@thebodymethod.mx</p>
        </div>
        <div className="col-12 col-md-4 text-center">
          <i className="fas fa-map-marker-alt"></i>
          <p className="info__title">Studio</p>
          <p className="info__description">
            Blvd. Rogelio Cantú Gómez #1000, Las Colinas Residencial, 64638
            Monterrey, N.L.
          </p>
        </div>
      </div>

      <div className="footer__bottom bg-primary text-white">
        <p>Copyright © 2024. All rights reserved.</p>

        <div className="d-flex align-items-center">
          <Link to="#" className="me-2">Preguntas frecuentes</Link> 
          |
          <Link to="#" className="ms-2">Terminos y condiciones</Link> 
        </div>
      </div>
    </div>
  );
};

export default MonterreyFooter;
