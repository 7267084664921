import React from "react";
import MainLanding from "./MainLanding";

const MainHome = () => {
  return (
    <div className="container-fluid px-0">
      <MainLanding />
    </div>
  );
};

export default MainHome;
