import React from "react";
import PlanCard from "../plans/PlanCard";
import { S3_ENDPOINT } from "../../utils";
import useBranch from "../../hooks/useBranch";

const LandingPlanes = () => {
  const { branch, content } = useBranch();

  return (
    <div className="container-fluid py-5 bg-white">
      <h2 className="h1 text-center">Planes</h2>
      <div className="mw-1600 m-auto mt-5 mb-5">
        <div className="row">
          <PlanCard
            title="TBM Presencial"
            url="/paquetes#presenciales"
            description={`Clases en el estudio @${
              branch.slug === "monterrey" ? "MTY, Nuevo León" : "Tijuana, BC"
            }`}
            image_url={`${S3_ENDPOINT}/${content.planes.presencial}`}
          />
          <PlanCard
            title="TBM Online"
            url="https://thebodymethod.mx/paquetes#online"
            image_url={`${S3_ENDPOINT}/${content.planes.online}`}
            description="Nuestro método desdes tu casa o donde quiera que estés."
          />
          <PlanCard
            title="Certificaciones"
            url="https://tbmcertifications.com"
            image_url="https://tbmcerts.s3.us-west-1.amazonaws.com/negocio.JPG"
            description="Certifícate como entrenadora del método más efectivo."
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPlanes;
