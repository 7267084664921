import { useContext, useEffect } from "react";
import { BranchesContext } from "../context/BranchesContext";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "@reach/router";
import { setupColor } from "../utils";
import api from "../services/api";

const useBranch = () => {
  const {
    branch,
    content,
    branches,
    setBranch,
    getBranches,
    setInterceptor,
    branchInterceptor,
  } = useContext(BranchesContext);

  const { user } = useContext(AuthContext);

  const location = useLocation();
  const branch_id = branch?.branch_id;

  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setBranchBySlug = () => {
    const pathname = window.location.pathname;
    let branchSet = false;

    for (const branchData of branches) {
      if (pathname.includes(branchData.slug)) {
        localStorage.setItem("branch_id", branchData.branch_id);
        selectBranch(branchData.branch_id);
        branchSet = true;
      }
    }

    return branchSet;
  };

  const setBranchByLocalStorage = () => {
    const localBranch = window.localStorage.getItem("branch_id");
    let branchSet = false;

    if (localBranch && localBranch !== null) {
      selectBranch(localBranch);
      branchSet = true;
    }

    return branchSet;
  };

  const setBranchByCustomer = () => {
    let branchSet = false;

    if (
      user?.branch_id &&
      user?.branch_id !== null &&
      user?.branch_id !== undefined
    ) {
      selectBranch(user.branch_id);
      branchSet = true;
    }

    return branchSet;
  };

  const setDefaultBranch = () => {
    window.localStorage.setItem("branch_id", branches[0].branch_id);
    selectBranch(branches[0].branch_id);
  };

  useEffect(() => {
    const pathname = location.pathname;
    let onlineView = false;

    const onlineLocation = pathname.includes("online");
    const videoLocation = pathname.includes("video");
    const workoutPlannerLocation = pathname.includes("workout-planner");

    if(onlineLocation || videoLocation || workoutPlannerLocation) {
      onlineView = true;
    }

    if (branch && branch !== null && !onlineView) {
      setupColor("primary", branch.primary_color);
      setupColor("primary-light", branch.light_color);

      window.localStorage.setItem("branch_id", branch.branch_id);
    }

    if (onlineView && Array.isArray(branches)) {
      const monterreyBranch = branches[0];

      setupColor("primary", monterreyBranch.primary_color);
      setupColor("primary-light", monterreyBranch.light_color);
    }
  }, [branch, location]);

  useEffect(() => {
    if (Array.isArray(branches) && branch === null) {
      handleSetBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, branches]);

  const handleSetBranch = () => {
    let alreadySet = setBranchBySlug();
    if (!alreadySet) alreadySet = setBranchByLocalStorage();
    if (!alreadySet) alreadySet = setBranchByCustomer();
    if (!alreadySet) setDefaultBranch();
  };

  const selectBranch = (branch_id) => {
    const selected = branches?.find(
      (branch) => String(branch.branch_id) === String(branch_id)
    );
    if (selected && selected !== null) {
      if (branch !== null && selected.branch_id !== branch.branch_id) {
        window.localStorage.setItem("branch_id", selected.branch_id);

        // return window.location.reload();
      }
      setBranch(selected);
      setupInterceptors(selected.branch_id);
    }
  };

  const setupInterceptors = (branch_id) => {
    if (branchInterceptor) api.interceptors.request.eject(branchInterceptor);

    const newInterceptor = api.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (
          (config.method === "get" && !config.url.includes("branch_id")) ||
          (config.method === "delete" && !config.url.includes("branch_id"))
        ) {
          if (config.url.includes("?")) {
            config.url = config.url + `&branch_id=${branch_id}`;
          } else {
            config.url = config.url + `?branch_id=${branch_id}`;
          }
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    setInterceptor(newInterceptor);
  };

  return {
    branch,
    content,
    branches,
    setBranch,
    branch_id,
    getBranches,
    selectBranch,
    branchInterceptor,
    handleSetBranch,
  };
};

export default useBranch;
