import moment from "moment";
import React from "react";

const PlannerStartEarly = ({ start_date, handleCancel, handleStartEarly }) => {

  return (
    <div>
      <p>
        Elegiste empezar tu Workout Planner el{" "}
        <span className="text-primary">{moment(start_date).utc().format("DD MMMM YYYY")}</span>.
      </p>
      <p>¿Deseas empezarlo ahora?</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">No, solo quiero ver</button>
        </div>
        <div className="col-6">
          <button onClick={handleStartEarly} className="btn w-100 btn-primary">Sí, Empezar Ahora</button>
        </div>
      </div>
    </div>
  );
};

export default PlannerStartEarly;