import React, { useContext, useEffect, useState } from "react";
import { TestimonialsContext } from "../../context/TestimonialsContext";
import TestimonialCarousel from "../testimonials/TestimonialCarousel";
import TestimonialCard from "../testimonials/TestimonialCard";

const LandingTestimonials = () => {
  const [currentTestimonials, setCurrentTestimonials] = useState(null);
  const { testimonials, getTestimonials } = useContext(TestimonialsContext);

  useEffect(() => {
    getTestimonials();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (Array.isArray(testimonials) && !Array.isArray(currentTestimonials)) {
      getTestimonialsComponents();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonials]);

  const getTestimonialsComponents = () => {
    const testimonialComponents = testimonials.map((testimonial) => (
      <TestimonialCard
        key={testimonial.testimonial_id}
        testimonial={testimonial}
      />
    ));
    setCurrentTestimonials(testimonialComponents);
  };

  return (
    <div id="testimonials" className="container-fluid">
      <h2 className="h1 text-center mb-xl-5">Testimonios</h2>

      <div className="mw-1600 m-auto">
        <TestimonialCarousel items={currentTestimonials} automatic />
      </div>
    </div>
  );
};

export default LandingTestimonials;
