
import {
  SET_QUOTE,
  CREATE_QUOTE,
  QUOTES_RECEIVED,
  SET_PROPERTY_QUOTE,
} from "../types/quotes";

const schema = {

}

const quotesReducer = (state, { type, payload }) => {
  switch (type) {
    case QUOTES_RECEIVED:
      return { ...state, quotes: payload };
    case SET_QUOTE:
      return { ...state, quote: payload };
    case CREATE_QUOTE:
      return { ...state, quote: schema };
    case SET_PROPERTY_QUOTE: {
      const { key, value } = payload;
      const quote = { ...state.quote };
      quote[key] = value;
      return { ...state, quote };
    }
    default:
      return { ...state};
  }
};

export default quotesReducer;
