import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const LeaderboardRow = ({ index, customer }) => {
  const { user } = useContext(AuthContext);

  return (
    <tr
      key={customer.customer_id}
      className={
        customer.customer_id === user.customer_id ? "bg-success" : ""
      }
    >
      <td>{index + 1}</td>
      <td>{customer.name}</td>
      <td>{customer.reservations}</td>
      <td>{customer.attended}</td>
    </tr>
  );
};

export default LeaderboardRow;
