import React, { useEffect } from "react";
import Navbar from "../components/global/Navbar";
import LandingPresencial from "./LandingPresencial";

const HomePresencial = () => {
  return (
    <div className="container-fluid px-0">
      <Navbar />
      <LandingPresencial default />
    </div>
  );
};

export default HomePresencial;
