import React, { useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import CustomerFreeTrial from "../customers/CustomerFreeTrial";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { navigate } from "@reach/router";
import useBranch from "../../hooks/useBranch";
import "./MonterreyLandingVideo.css";
import LandingNavbar from "./LandingNavbar";

const MonterreyLandingVideo = () => {
  const { branch, content } = useBranch();
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);

  const handleFreeTrial = () => {
    if (user === null) {
      return modalComponent("Comienza Gratis", <CustomerFreeTrial />);
    }
    navigate("/checkout/19271");
  };

  return (
    <div className="monterrey-landing__container container-fluid m-0 w-100 position-relative">
      <div className="pt-3 position-absolute d-flex flex-column start-0 end-0 container__content-container">
        <LandingNavbar />

        <div className="content-container__description ">
          <div className="d-flex flex-column align-items-start description__head position-relative">
            <h2 className="head__desc mb-0">
              {`ALCANZA TU MÁXIMO POTENCIAL\n
              `}
            </h2>
            <h2 className="head__desc">EVERYTHING YOU WANT IN A WORKOUT</h2>
            <h1 className="head__app-name">
              THE BODY METHOD
            </h1>
            <p className="head__city">
              MONTERREY
            </p>
          </div>

          <div className="description__content mt-3">
            <p className="content__title">ENTRENA INTELIGENTE</p>
            <p className="content__text">Haz que cada segundo cuente con nuestras rutinas divertidas y estratégicamente planeadas.</p>
          </div>

          <div className="description__bottom d-flex justify-content-center mt-4">
            <button 
              type="button"
              className="btn text-white bold border border-2 border-white px-4"
              style={{ borderRadius: '40px', height: '50px'}}
            >
              AGENDA CLASE DE PRUEBA
              <i className="fas fa-arrow-right ms-2"/>
            </button>
          </div>
        </div>
      </div>

      <img
        className="mw-100 w-100 container__video"
        data-object-fit="cover"
        src="/img/background-hero.png"
      />
    </div>
  );
};

export default MonterreyLandingVideo;
