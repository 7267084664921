import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import VideosReducer from "../reducers/VideosReducer";
import ProgramsService from "../services/ProgramsService";
import TagsService from "../services/TagsService";
import VideosService from "../services/VideosService";
import {
  SINGLE_VIDEO_RECIBIDO,
  PROGRAMS_RECIBIDOS,
  ELIMINAR_FAVORITO,
  AGREGAR_FAVORITO,
  VIDEOS_RECIBIDOS,
  PURCHASE_NEEDED,
  TAGS_RECIBIDOS,
  SET_COMPLETED,
  CLEAR_RELOAD,
  SET_NONCE,
  SET_MAX,
} from "../types/videos";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";
import { hideModal, randomString } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  reloadVideos: false,
  fetching: false,
  favoritos: null,
  programs: null,
  spinner: false,
  videos: null,
  video: null,
  nonce: null,
  max: null,
};

export const VideosContext = createContext(initialState);

export const VideosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VideosReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getTags = () => {
    dispatch({ type: TAGS_RECIBIDOS, payload: null });
    TagsService.getTags().then((res) => {
      const { tags } = res.data;
      dispatch({ type: TAGS_RECIBIDOS, payload: tags });
    });
  };
  const getPrograms = () => {
    ProgramsService.getPrograms().then((res) => {
      const { programs } = res.data;
      dispatch({ type: PROGRAMS_RECIBIDOS, payload: programs });
    });
  };

  const getVideos = (filters) => {
    const nonce = randomString(16);
    dispatch({ type: SET_NONCE, payload: nonce });
    dispatch({ type: SHOW_SPINNER });
    VideosService.getVideos(filters)
      .then((res) => {
        const { max, videos } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: SET_MAX, payload: max });
        dispatch({ type: VIDEOS_RECIBIDOS, payload: { videos, nonce } });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            dispatch({ type: PURCHASE_NEEDED });
          }
        }
      });
  };

  const getFavoritos = (filters) => {
    let nonce = randomString(16);
    dispatch({ type: SET_NONCE, payload: nonce });
    VideosService.getFavoritos(filters).then((res) => {
      const { favorites } = res.data;
      dispatch({
        type: VIDEOS_RECIBIDOS,
        payload: { videos: favorites, nonce },
      });
    });
  };

  const clearFavoritos = () => {
    let nonce = randomString(16);
    dispatch({ type: SET_NONCE, payload: nonce });
    dispatch({ type: VIDEOS_RECIBIDOS, payload: { videos: null, nonce } });
  };

  const getAllVideos = (query) => {
    let nonce = randomString(16);
    dispatch({ type: SET_NONCE, payload: nonce });
    VideosService.getAllVideos(query).then((res) => {
      const { videos } = res.data;
      dispatch({ type: VIDEOS_RECIBIDOS, payload: { videos, nonce } });
    });
  };

  const getSingleVideo = (video_id) => {
    VideosService.getSingleVideo(video_id)
      .then((res) => {
        const { video } = res.data;
        dispatch({ type: SINGLE_VIDEO_RECIBIDO, payload: video });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            alert("Lo sentimos, no hemos encontrado este video.");
          }
        }
      });
  };

  const clearReload = () => {
    dispatch({ type: CLEAR_RELOAD });
  };

  const agregarFavorito = (video_id) => {
    VideosService.postFavorito(video_id).then(() => {
      dispatch({ type: AGREGAR_FAVORITO, payload: video_id });
      success("¡Favorito agregado!");
      getSingleVideo(video_id);
    });
  };

  const eliminarFavorito = (video_id) => {
    VideosService.deleteFavorito(video_id).then(() => {
      dispatch({ type: ELIMINAR_FAVORITO, payload: video_id });
      success("¡Favorito eliminado!");
      getSingleVideo(video_id);
    });
  };

  const deleteVideo = (video_id) => {
    VideosService.deleteVideo(video_id).then(() => {
      navigate("/mytbm/admin/videos");
      success("¡Video eliminado con éxito!");
      hideModal();
      getAllVideos();
    });
  };

  const setVideo = (video) => {
    dispatch({ type: SINGLE_VIDEO_RECIBIDO, payload: video });
  };

  const clearVideos = () => {
    const nonce = null;
    dispatch({ type: SET_MAX, payload: null });
    dispatch({ type: SET_NONCE, payload: nonce });
    dispatch({
      type: VIDEOS_RECIBIDOS,
      payload: { videos: null, nonce },
    });
  };

  const completarVideo = (video_id) => {
    VideosService.completarVideo(video_id).then(() => {
      success("Video marcado como completado.");
      getSingleVideo(video_id);
    });
  };

  const eliminarCompletado = (video_id) => {
    VideosService.eliminarCompletado(video_id).then(() => {
      success("Video marcado como incompleto.");
      getSingleVideo(video_id);
    });
  };

  const setCompleted = (completed) => {
    dispatch({ type: SET_COMPLETED, payload: completed });
  };

  const saveRating = (video_rating) => {
    VideosService.postVideoRating(video_rating)
      .then(() => {
        success("Video calificado");
        clearModal();
      })
      .catch((error) => {
        alert(error);
        clearModal();
      });
  };

  const sendRateEmail = (video_id) => {
    VideosService.postVideoRatingEmail(video_id);
  };

  return (
    <VideosContext.Provider
      value={{
        ...state,
        getTags,
        setVideo,
        getVideos,
        saveRating,
        clearReload,
        setCompleted,
        getPrograms,
        clearVideos,
        deleteVideo,
        getAllVideos,
        getFavoritos,
        sendRateEmail,
        clearFavoritos,
        getSingleVideo,
        agregarFavorito,
        completarVideo,
        eliminarCompletado,
        eliminarFavorito,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};
