import React, { useEffect, useContext } from "react";
import { NutritionTipsContext } from "../../context/NutritionTipsContext";
import PlannerSingleTip from "./PlannerSingleTip";

const PlannerNutritionTips = ({ workout_plan }) => {
  const { nutrition_tips, getNutritionTips } = useContext(NutritionTipsContext);

  useEffect(() => {
    if (workout_plan && workout_plan !== null) {
      getNutritionTips(workout_plan.goal_id);
    }
  }, [workout_plan]);

  const renderTips = () => {
    if (Array.isArray(nutrition_tips)) {
      return nutrition_tips.map((tip, index) => (
        <PlannerSingleTip key={tip.nutrition_tip_id} tip={tip} index={index} />
      ));
    }
  };

  return (
    <div className="row">
      <div className="container-fluid p-md-5 px-3 py-5 bg-light">
        <h2>TIPS DE NUTRICIÓN </h2>
        <p className="bold">PRE Y POST WORKOUT DE ACUERDO A TU OBJETIVO</p>
        <p>
          Complementar una buena alimentación con tu plan de entrenamiento hará
          que veas y sientas muchos más resultados, vivir en balance es la clave
          de todo, te sugerimos:
        </p>
        <div className="row">{renderTips()}</div>
      </div>
    </div>
  );
};

export default PlannerNutritionTips;
