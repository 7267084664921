import React from "react";
import moment from "moment";

const WaitlistRow = ({ waitlist, handleCancel }) => {
  const renderInstructorName = (class_waitlist) => {
    if (class_waitlist.single_class !== null) {
      const instructors = class_waitlist.single_class.class_instructors;
      return instructors.map(({ instructor }) => instructor?.name).join(", ");
    }
  };

  return (
    <tr className="border-bottom">
      <td>{waitlist.single_class.class_type.name}</td>
      <td>{renderInstructorName(waitlist)}</td>
      <td>
        {moment(waitlist.single_class.class_date)
          .utc()
          .format("DD MMM YYYY HH:mm")}
      </td>
      <td>{moment(waitlist.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => handleCancel(waitlist)}
        >
          <i className="fa fa-times"></i>
        </button>
      </td>
    </tr>
  );
};

export default WaitlistRow;
