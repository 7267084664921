import React, { useContext } from "react";
import moment from "moment";
import { getTarget } from "../../utils/workout_planner";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";

const PlannerWorkoutDay = ({
  dayIndex,
  weekIndex,
  start_date,
  handleClick,
  workout_plan,
  completedDay,
  class_category,
  currentDateString,
}) => {
  const { customer_workout_plan } = useContext(CustomerWorkoutPlansContext);

  const target = getTarget(
    class_category.name,
    workout_plan.frequency,
    weekIndex,
    dayIndex
  );

  const isPastDate = moment()
    .startOf("day")
    .isAfter(moment(currentDateString, "DD / MMM"));

  const getCardClass = () => {
    if (completedDay) {
      return "bg-success text-dark";
    } else {
      if (
        isPastDate &&
        customer_workout_plan &&
        customer_workout_plan !== null
      ) {
        return "border-primary-light text-primary-light";
      } else {
        return "border-primary border-1 text-primary";
      }
    }
  };

  const renderDate = () => {
    if (
      moment(start_date).isValid() &&
      customer_workout_plan &&
      customer_workout_plan !== null
    ) {
      return (
        <span className="d-block">{currentDateString}</span>
      )
    }
  };

  return (
    <div
      onClick={() => {
        handleClick(weekIndex, dayIndex, currentDateString, completedDay);
      }}
      className="schedule-col text-center p-2"
    >
      <div className={`card small text-white py-4 p-2 ${getCardClass()}`}>
        <span className="bold">{class_category.name}</span>
        <span className="d-block">{target}</span>
        {renderDate()}
      </div>
    </div>
  );
};

export default PlannerWorkoutDay;
