import React from "react";
import Footer from "../components/global/Footer";
import LandingVideo from "../components/landing/LandingVideo";
import LandingAbout from "../components/landing/LandingAbout";
import LandingClassTypes from "../components/landing/LandingClassTypes";
import LandingQuote from "../components/landing/LandingQuote";
import LandingTestimonials from "../components/landing/LandingTestimonials";
import LandingPrograms from "../components/landing/LandingPrograms";

const LandingOnline = () => {
  return (
    <div id="landing">
      <LandingVideo />
      <LandingAbout />
      <LandingQuote />
      <LandingPrograms />
      <LandingTestimonials />
      <LandingClassTypes /> 
      <LandingQuote />     
      <Footer />
    </div>
  );
};

export default LandingOnline;
