import api from "./api";
import { getArgs } from "../utils";

const route = "/class_reservations";

const ReservationsService = {
  getMyReservations: (filters) => api.get(`${route}?${getArgs(filters)}`),
  postReservation: (class_reservation) =>
    api.post(route, { ...class_reservation }),
  putReservacion: (class_reservation) =>
    api.put(route, { ...class_reservation }),
  postVideoRating: (rating) => api.post(`${route}/rating`, { ...rating }),
  cancelReservation: (class_reservation_id) =>
    api.delete(`${route}/${class_reservation_id}`),
};

export default ReservationsService;
