import React, { useContext, useEffect } from "react";
import PanelTitle from "../components/global/PanelTitle";
import { NotificationTypesContext } from "../context/NotificationTypesContext";
import NotificationTypeRow from "../components/notifications/NotificationTypeRow";
import { CustomerNotificationTypesContext } from "../context/CustomerNotificationTypesContext";

const Notifications = () => {
  const { getCustomerNotificationTypes } = useContext(CustomerNotificationTypesContext);
  const { notification_types, getNotificationTypes } = useContext(
    NotificationTypesContext
  );

  useEffect(() => {
    getNotificationTypes();
    getCustomerNotificationTypes();
  }, []);

  const renderNotificationTypes = () => {
    if (Array.isArray(notification_types)) {
      return notification_types.map((notification_type) => (
        <NotificationTypeRow key={notification_type.notification_type_id} notification_type={notification_type} />
      ));
    }
  };

  return (
    <div className="container-fluid px-lg-5 py-lg-3">
      <PanelTitle title="Notificaciones" hideButton />
      <div className="row py-2 mb-3 bg-light bold border">
        <div className="col-8">
          Tipo de Notificación
        </div>
        <div className="col-1">
          Email
        </div>
        <div className="col-1">
          WhatsApp
        </div>
        <div className="col-2">
          Hora Preferida
        </div>
      </div>
      {renderNotificationTypes()}
    </div>
  );
};

export default Notifications;