import React from "react";
import "./MonterreyLandingSchedule.css";
import MonterreySchedule from "../monterrey-schedule/MonterreySchedule";

const MonterreyLandingSchedule = () => {
  return (
    <div className="container-fluid text-center pt-4 monterrey-schedule__container" >
      <h2 className="mt-3 text-black">HORARIOS</h2>
      <p className="mt-3 text-black">Resrva tus clases</p>
      <MonterreySchedule/>
    </div>
  );
};

export default MonterreyLandingSchedule;
