
import {
  SET_CANCELREASON,
  CREATE_CANCELREASON,
  CANCELREASONS_RECEIVED,
  SET_PROPERTY_CANCELREASON,
} from "../types/cancelReasons";

const schema = {

}

const cancelReasonsReducer = (state, { type, payload }) => {
  switch (type) {
    case CANCELREASONS_RECEIVED:
      return { ...state, cancelReasons: payload };
    case SET_CANCELREASON:
      return { ...state, cancelReason: payload };
    case CREATE_CANCELREASON:
      return { ...state, cancelReason: schema };
    case SET_PROPERTY_CANCELREASON: {
      const { key, value } = payload;
      const cancelReason = { ...state.cancelReason };
      cancelReason[key] = value;
      return { ...state, cancelReason };
    }
    default:
      return { ...state};
  }
};

export default cancelReasonsReducer;
