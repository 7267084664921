import React from "react";

const TrackMassTable = () => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light bold">
            <td>Escala de Grasa Corporal</td>
            <td>Valores</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nivel muy bajo</td>
            <td>{"< 17%"}</td>
          </tr>
          <tr>
            <td>Nivel bajo</td>
            <td>18% - 22%</td>
          </tr>
          <tr>
            <td>Nivel promedio</td>
            <td>23% - 27%</td>
          </tr>
          <tr>
            <td>Nivel alto</td>
            <td>28% - 33%</td>
          </tr>
          <tr>
            <td>Nivel muy alto</td>
            <td>{"> 34%"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TrackMassTable;
