import React from "react";
import useBranch from "../../hooks/useBranch";

const ScheduleTabs = () => {
  const { branch, branches, selectBranch } = useBranch();

  const renderButtons = () => {
    return branches?.map((branchData) => {
      const branch_id = branchData.branch_id;
      const name = branchData.name;

      return (
        <button
          key={branch_id}
          onClick={() => selectBranch(branch_id)}
          className={`btn btn-${
            branchData.branch_id === branch.branch_id
              ? "primary"
              : "outline-primary"
          }`}
        >
          {name}
        </button>
      );
    });
  };

  return <div className="btn-group w-100 my-3">{renderButtons()}</div>;
};

export default ScheduleTabs;
