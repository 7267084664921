import { Link } from "@reach/router";
import moment from "moment";
import React from "react";

const LastTrackCard = ({ track, tracks, user }) => {
  const renderValue = (key, suffix) => {
    let value = "N/D";
    if (track[key] && track[key] !== null && track[key] !== "") {
      value = track[key];
    }
    return (
      <p className="small mb-2">
        {value}
        {suffix}
      </p>
    );
  };

  const renderBirthdate = () => {
    if (user.birthdate !== null) {
      return <span>{moment().diff(moment(user.birthdate), "years")} años</span>;
    }
  };

  const renderLastWeight = () => {
    if (Array.isArray(tracks) && tracks.length > 1) {
      const prevLast = tracks[tracks.length - 2];
      if (prevLast && prevLast !== null) {
        if (prevLast.track_id !== track.track_id) {
          return prevLast.weight;
        }
      }
    }
    return "N/D";
  };

  const renderHeight = () => {
    if (user.height !== null) {
      return <span>{user.height} cm</span>;
    }
  };

  return (
    <div className="card shadow py-md-3 px-md-5 text-center mb-3">
      <span className="mb-0 d-inline-block m-auto h3 bg-light p-3 px-5 mb-4">
        {track.weight} kg
      </span>
      <div className="row">
        <div className="col-4">
          <label className="small bold d-block">Pecho</label>
          {renderValue("chest")}
          <label className="small bold d-block">Cintura</label>
          {renderValue("waist")}
        </div>
        <div className="col-4">
          <label className="small bold d-block">Abdomen</label>
          {renderValue("abs")}
          <label className="small bold d-block">Cadera</label>
          {renderValue("hips")}
        </div>
        <div className="col-4">
          <label className="small bold d-block">Brazo</label>
          {renderValue("arms")}
          <label className="small bold d-block">Muslo</label>
          {renderValue("thigh")}
        </div>
      </div>
      <div className="row my-3">
        <div className="col-4">
          <label className="d-block bold">Altura</label>
          {renderHeight()}
          <Link to="/mytbm/informacion" className="btn text-muted">
            <i className="fa fa-edit" />
          </Link>
        </div>
        <div className="col-4">
          <label className="d-block bold">Edad</label>
          {renderBirthdate()}
          <Link to="/mytbm/informacion" className="btn text-muted">
            <i className="fa fa-edit" />
          </Link>
        </div>
        <div className="col-4">
          <label className="d-block bold">Peso Anterior</label>
          {renderLastWeight()}
        </div>
      </div>
    </div>
  );
};

export default LastTrackCard;
