import React, { useEffect, useState } from "react";

const SearchableMultipleSelect = ({
  items,
  label,
  key_id,
  showAll,
  addItem,
  nameKey,
  itemName,
  selected,
  fetchItems,
  removeItem,
  hideCreate,
  hideSelected,
  CustomComponent,
}) => {
  const [query, setQuery] = useState("");
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (!showAll && query !== "") {
      fetchItems({ query });
    } else if (showAll) {
      fetchItems({ query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const renderItems = () => {
    if (Array.isArray(items) && (query !== "" || (showAll && focus))) {
      let itemsRender = [...items];
      if (Array.isArray(selected)) {
        const current = selected.map((item) => item[key_id]);
        itemsRender = itemsRender.filter(
          (item) => !current.includes(item[key_id])
        );
      }
      if (!showAll) {
        itemsRender = itemsRender.slice(0, 3);
      }
      itemsRender = itemsRender.map((item) => (
        <li
          key={item[key_id]}
          className="dropdown-item border small py-2 px-2 pointer"
          onClick={(e) => {
            e.stopPropagation();
            addItem(item);
            setFocus(false);
          }}
        >
          {CustomComponent && CustomComponent !== null ? (
            <CustomComponent item={item} />
          ) : (
            <span>{item[nameKey]}</span>
          )}
        </li>
      ));
      if (!hideCreate) {
        itemsRender.concat(
          <li
            key="nuevo"
            className="dropdown-item border small py-2 px-2 pointer"
            onClick={(e) => {
              e.stopPropagation();
              addItem({ [key_id]: "nuevo", name: query });
              setQuery("");
            }}
          >
            + Crear {itemName} "{query}"
          </li>
        );
      }
      return itemsRender;
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected) && !hideSelected) {
      return selected.map((item) => (
        <span
          key={item[key_id]}
          className="badge badge-pill bg-light border text-dark p-2 me-1"
        >
          {item.title} {item.last_name}{" "}
          <i
            className="fa fa-times small ms-1 pointer"
            onClick={() => removeItem(item)}
          ></i>
        </span>
      ));
    }
  };

  return (
    <div className="position-relative">
      <label className="mb-1">{label}</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Elegir..."
        onFocus={() => setFocus(true)}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="position-absolute z-index-1000 bg-white w-100">
        {renderItems()}
      </div>
      {!hideSelected && <div className="py-2">{renderSelected()}</div>}
    </div>
  );
};

export default SearchableMultipleSelect;
