import React from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const PanelClasses = () => {
  return (
    <div className="row mx-0 my-2">
      <div className="col-12 col-md-6 px-4">
        <Link
          to="/mytbm/online"
          className="text-center d-block no-decoration text-white"
        >
          <div
            className="card bg-image shadow-sm text-center program-card panel-card"
            style={{
              backgroundImage: `url("${S3_ENDPOINT}/tbm-online-thumbnail-2-min.jpg")`,
            }}
          >
            <div className="program-meta p-3 text-white text-shadow">
              <h4 className="program-title mb-2">TBM Online</h4>
              <button className="btn w-75 btn-outline-light">Ver Videos</button>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-md-6 px-4">
        <Link
          to="/mytbm/presencial"
          className="text-center d-block no-decoration text-white hover-shadow"
        >
          <div
            className="card bg-image shadow-sm text-center program-card panel-card"
            style={{
              backgroundImage: `url("${S3_ENDPOINT}/tbm-presencial-panel-home.jpg")`,
            }}
          >
            <div className="program-meta p-3 text-white text-shadow">
              <h4 className="program-title mb-2">TBM Presencial</h4>
              <button className="btn w-75 btn-outline-light">
                Reserva tus Clases
              </button>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PanelClasses;
