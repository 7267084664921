import React, { createContext, useReducer, useContext } from "react";
import NutritionTipsService from "../services/NutritionTipsService";
import NutritionTipsReducer from "../reducers/NutritionTipsReducer";
import {
  NUTRITIONTIPSS_RECEIVED,
  SET_NUTRITIONTIPS,
  CREATE_NUTRITIONTIPS,
  SET_PROPERTY_NUTRITIONTIPS,
} from "../types/nutrition_tips";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  nutrition_tips: null,
  nutrition_tip: null,
};

export const NutritionTipsContext = createContext(initialState);

export const NutritionTipsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NutritionTipsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getNutritionTips = (goal_id) => {
    NutritionTipsService.getNutritionTips(goal_id)
      .then((response) => {
        const { nutrition_tips } = response.data;
        dispatch({ type: NUTRITIONTIPSS_RECEIVED, payload: nutrition_tips });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleNutritionTips = (nutrition_tip_id) => {
    NutritionTipsService.getSingleNutritionTips(nutrition_tip_id)
      .then((response) => {
        const { nutrition_tip } = response.data;
        dispatch({ type: SET_NUTRITIONTIPS, payload: nutrition_tip });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setNutritionTips = (nutrition_tip) => {
    dispatch({ type: SET_NUTRITIONTIPS, payload: nutrition_tip });
  };

  const createNutritionTips = () => {
    dispatch({ type: CREATE_NUTRITIONTIPS });
  };

  const setPropertyNutritionTips = (key, value) => {
    dispatch({ type: SET_PROPERTY_NUTRITIONTIPS, payload: { key, value } });
  };

  const saveNutritionTips = (nutrition_tip, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = NutritionTipsService.putNutritionTips;
    if (isNaN(parseInt(nutrition_tip.nutrition_tip_id))) {
      service = NutritionTipsService.postNutritionTips;
    }
    service(nutrition_tip)
      .then(() => {
        success("NutritionTips saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteNutritionTips = (nutrition_tip_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    NutritionTipsService.deleteNutritionTips(nutrition_tip_id)
      .then(() => {
        success("NutritionTips deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <NutritionTipsContext.Provider
      value={{
        ...state,
        setNutritionTips,
        getNutritionTips,
        saveNutritionTips,
        deleteNutritionTips,
        createNutritionTips,
        getSingleNutritionTips,
        setPropertyNutritionTips,
      }}
    >
      {children}
    </NutritionTipsContext.Provider>
  );
};
