import React from "react";

const WhatsApp = () => {
  return (
    <a
      href="https://wa.me/528125159100"
      target="_blank"
      className="btn btn-success btn-whatsapp"
    >
      <div className="row align-items-center mx-0">
        <div className="col-2 px-0">
          <i className="fab fa-whatsapp"></i>
        </div>
        <div className="col-10 px-0">
          <span className="btn-whatsapp-text mx-2">Ayuda</span>
        </div>
      </div>
    </a>
  );
};
export default WhatsApp;
