import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_TRACK,
  CREATE_TRACK,
  TRACKS_RECEIVED,
  SET_PROPERTY_TRACK,
} from "../types/tracks";

const schema = {};

const tracksReducer = (state, { type, payload }) => {
  switch (type) {
    case TRACKS_RECEIVED:
      return { ...state, tracks: payload };
    case SET_TRACK:
      return { ...state, track: payload };
    case CREATE_TRACK:
      return { ...state, track: schema };
    case SET_PROPERTY_TRACK: {
      const { key, value } = payload;
      const track = { ...state.track };
      track[key] = value;
      return { ...state, track };
    }
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};

export default tracksReducer;
