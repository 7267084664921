import api from "./api";
import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCZQ8R2eCESbL9772fsfPWB5LwcikK8EaQ",
  authDomain: "tbm-web-app.firebaseapp.com",
  projectId: "tbm-web-app",
  storageBucket: "tbm-web-app.appspot.com",
  messagingSenderId: "465930806094",
  appId: "1:465930806094:web:fd7b93671d4963acbc341f",
};

// Initialize Firebase
if (firebase.apps.length < 1) {
  firebase.initializeApp(firebaseConfig);
}

const getToken = () => auth.currentUser.getIdToken(true);

const auth = firebase.auth();

const AuthService = {
  signIn: (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        const token = await getToken();
        api.defaults.headers.common["Authorization"] = token;
        return user;
      }),
  userLoggedIn: (success, error) =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          if (success) success(user);
        });
      } else {
        error();
      }
    }),
  resetAuthObserver: () => auth.onAuthStateChanged(() => {}),
  signOut: () => auth.signOut(),
  signUp: (correo, password) =>
    auth.createUserWithEmailAndPassword(correo, password),
  recoverPassword: (email) => auth.sendPasswordResetEmail(email),
  getToken: () => auth.currentUser.getIdToken(true),
  verifyEmail: () => auth.currentUser.sendEmailVerification(),
  updateEmail: (email) => auth.currentUser.updateEmail(email),
  setToken: (token) => (api.defaults.headers.common["Authorization"] = token),
};

export default AuthService;
