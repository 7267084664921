import api from "./api";

const route = "/customer";

const CustomerService = {
  getCurrentCustomer: () => api.get(route),
  getCustomer: (customer_id) => api.get(`${route}/admin/${customer_id}`),
  getAllCustomers: (query) =>
    api.get(
      `${route}/admin/all${
        query && query !== null && query !== "" ? `?query=${query}` : ""
      }`
    ),
  isLegacyCustomer: (email) => api.get(`${route}/legacy?email=${email}`),
  getCustomersByQuery: (query) => api.get(`${route}/query?query=${query}`),
  postCustomer: (name, last_name, email, phone) =>
    api.post(route, { name, last_name, email, phone }),
  putCustomer: (customer_id, name, last_name, phone) =>
    api.put(route, { customer_id, name, last_name, phone }),
  putCurrentCustomer: (customer) => api.put(route, { ...customer }),
  extenderAcceso: (
    customer_id,
    package_id,
    package_days,
    is_gift,
    payment_method_id,
    bill
  ) =>
    api.post(`${route}/giveAccess`, {
      customer_id,
      package_id,
      package_days,
      is_gift,
      payment_method_id,
      bill,
    }),
  revokeAccess: (purchase_id) =>
    api.put(`${route}/revokeAccess`, { purchase_id }),
  removeClasses: (customer_id, amount) =>
    api.put(`${route}/removeClasses`, { customer_id, amount }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
  signUp: (data) =>
    api.post(`${route}/signup`, data),
};

export default CustomerService;
