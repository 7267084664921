import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getValue } from "../../utils";

const TrackReminder = ({ handleCancel }) => {
  const { user, updateUsuario, setPropiedadUser } = useContext(AuthContext);

  const handleChange = (e) => {
    if (e.target.checked) {
      setPropiedadUser("track_frequency", e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUsuario(user, false);
    handleCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        Elige cada cuánto quieres recibir un recordatorio para actualizar tu
        progreso
      </p>
      <div className="row mb-4">
        <div className="col-12 col-md-4">
          <label>
            <input
              type="radio"
              value="week"
              className="me-2"
              onChange={handleChange}
              checked={getValue(user, "track_frequency") === "week"}
            />{" "}
            Semanal
          </label>
        </div>
        <div className="col-12 col-md-4">
          <label>
            <input
              type="radio"
              value="month"
              className="me-2"
              onChange={handleChange}
              checked={getValue(user, "track_frequency") === "month"}
            />{" "}
            Mensual
          </label>
        </div>
        <div className="col-12 col-md-4">
          <label>
            <input
              type="radio"
              value="quarter"
              className="me-2"
              onChange={handleChange}
              checked={getValue(user, "track_frequency") === "quarter"}
            />{" "}
            Trimestral
          </label>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default TrackReminder;
