export const handleCheckoutError = (error, callback) => {
  if (error.response) {
    const { data } = error.response;
    if (data) {
      if (data.error) {
        const { code, decline_code } = data.error;
        if (code && code !== null) {
          let message = "Lo sentimos. Tu tarjeta fue rechazada por decisión del banco, datos incorrectos, o por fondos insuficientes. Inténtalo de nuevo o prueba con otra forma de pago.";
          if (code === "card_declined") {
            if (decline_code === "insufficient_funds") {
              message =
                "Lo sentimos, tu tarjeta no tiene fondos suficientes. Inténtalo de nuevo o prueba con otra forma de pago.";
            } else {
              message =
                "Lo sentimos, tu compra ha sido rechazada por el banco. Ponte en contacto con tu banco o prueba con otro método de pago.";
            }
          } else if(code === "incorrect_cvc") {
            message = "Lo sentimos, el CVC de tu tarjeta es incorrecto. Revisa los datos ingresados e inténtalo de nuevo o prueba otro método de pago.";
          } else if (code === "expired_card") {
            message = "Lo sentimos, tu tarjeta ha expirado. Revisa los datos ingresados e inténtalo de nuevo o prueba otro método de pago.";
          } else if (code === "incorrect_number") {
            message = "Lo sentimos, el número de tu tarjeta es incorrecto. Revisa los datos ingresados e inténtalo de nuevo o prueba otro método de pago.";
          }
          return callback(message);
        }
      }
    }
    if (error.response.status === 412) {
      if (error.response.data) {
        if (error.response.data.message) {
          return callback(error.response.data.message);
        }
      }
      return callback("Lo sentimos. Este evento especial se ha llenado.");
    } else if (error.response.status === 409) {
      let message =
        "Lo sentimos, ya has alcanzado el límite de compras de este paquete.";
      if (error.response.data && error.response.data !== null) {
        if (
          error.response.data.message &&
          error.response.data.message !== null
        ) {
          message = error.response.data.message;
        }
      }
      return callback(message);
    } else if (error.response.status === 402) {
      return callback(
        "Lo sentimos. Tu tarjeta fue rechazada por fondos insuficientes. Inténtalo de nuevo o prueba con otra forma de pago."
      );
    }
  }
  callback("Intenta de nuevo o contáctanos para más información.");
};
