import React from "react";
import ResetButtons from "./ResetButtons";
import { S3_ENDPOINT } from "../../utils";

const Experiencia = ({ setAnswer, resetPlanner, goBack }) => {
  return (
    <div className="row align-items-center">
      <div
        className="col-12 col-md-6 bg-image"
        style={{
          backgroundImage: `url("${`${S3_ENDPOINT}/workout-planner-4.jpg`}")`,
        }}
      />
      <div className="col-12 col-md-6">
        <div className="p-md-5 p-3">
          <p>¿Qué nivel de experiencia fitness tienes?</p>
          <div className="row mt-4">
            <div className="col-12 col-md-6 my-2 text-center">
              <button
                className="btn btn-dark px-4 w-100"
                onClick={() => setAnswer("principiante")}
              >
                Principiante
              </button>
            </div>
            <div className="col-12 col-md-6 my-2 text-center">
              <button
                className="btn btn-dark px-4 w-100"
                onClick={() => setAnswer("intermedio")}
              >
                Intermedio-Avanzado
              </button>
            </div>
          </div>
          <ResetButtons resetAll={resetPlanner} goBack={goBack} />
        </div>
      </div>
    </div>
  );
};

export default Experiencia;
