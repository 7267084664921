import React, { useContext, useEffect } from "react";
import CustomerGoals from "../components/customerGoals/CustomerGoals";
import TrackMuscleTable from "../components/track/TrackMuscleTable";
import TrackMassTable from "../components/track/TrackMassTable";
import LastTrackCard from "../components/track/LastTrackCard";
import TrackReminder from "../components/track/TrackReminder";
import TracksChart from "../components/track/TracksChart";
import { TracksContext } from "../context/TracksContext";
import { ModalContext } from "../context/ModalContext";
import TrackCard from "../components/track/TrackCard";
import { AuthContext } from "../context/AuthContext";
import { navigate } from "@reach/router";
import { getLastTrack } from "../utils";

const Progreso = () => {
  const { user } = useContext(AuthContext);
  const { tracks, getTracks } = useContext(TracksContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTrack = () => {
    navigate("/mytbm/track/new");
  };

  const setupReminder = () => {
    modalComponent(
      "Recordatorios",
      <TrackReminder handleCancel={clearModal} />
    );
  };

  const renderTracks = () => {
    if (Array.isArray(tracks)) {
      if (tracks.length === 0) return <p>No has registrado tu progreso.</p>;
      return tracks.map((track) => (
        <TrackCard key={track.track_id} track={track} />
      ));
    }
  };

  const renderLast = () => {
    if (Array.isArray(tracks)) {
      const last_track = getLastTrack(tracks);
      if (last_track) {
        return <LastTrackCard tracks={tracks} track={last_track} user={user} />;
      }
    }
  };

  return (
    <div className="container-fluid px-4 mx-md-3 pe-md-5 py-3">
      <div className="row mx-0 align-items-center mb-3 pb-3 border-bottom">
        <div className="col col-md-6 px-0">
          <h1 className="h2 bold mb-0">Mi Progreso</h1>
        </div>
        <div className="col col-md-6 px-0 text-end">
          <button
            className="btn btn-outline-primary me-2"
            onClick={setupReminder}
          >
            <i className="fa fa-clock" />
          </button>
          <button className="btn btn-primary" onClick={addTrack}>
            + Agregar
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-xl-4">
          <TracksChart tracks={tracks} />
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          {renderLast()}
          <div className="card p-3 mb-3 no-scale shadow">
            <CustomerGoals />
          </div>
          <div className="card p-3 mb-3 shadow">
            <TrackMassTable />
          </div>
          <div className="card p-3 mb-3 shadow">
            <TrackMuscleTable />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4">{renderTracks()}</div>
      </div>
    </div>
  );
};

export default Progreso;
