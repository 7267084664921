import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { StreaksContext } from "../../context/StreaksContext";
import { S3_ENDPOINT } from "../../utils";

const StreakMonthUnlocked = () => {
  const { streaks } = useContext(StreaksContext);
  const { clearModal } = useContext(ModalContext);

  return (
    <div>
      <p>
        Al completar tu última clase haz logrado un nuevo streak{" "}
        <span className="text-primary">mensual.</span>
      </p>
      <p>Sigue cumpliendo con tu workout planner para obtener más beneficios</p>
      <p>Empuja tus límites, porque cada desafío es una oportunidad para reinventarte.</p>
      <img
        src={`${S3_ENDPOINT}/streak-${Math.ceil(streaks?.length / 4)}-${
          4
        }.png`}
        className="m-auto d-block"
        style={{
          width: 250,
          height: 250,
          objectFit: "contain",
        }}
      />
      <button onClick={clearModal} className="btn w-100 btn-primary">
        Continuar
      </button>
    </div>
  );
};

export default StreakMonthUnlocked;
