import React from "react";

const MonterreyScheduleHeader = ({ }) => {

  return (
    <div className="row text-black bold schedule__header justify-content-between mx-auto ">
      <div className="col">
        <p className="schedule__day-type">TREN SUPERIOR</p>
        <p className="schedule__day">Lunes</p>
      </div>
      <div className="col">
        <p className="schedule__day-type">TREN INFERIOR</p>
        <p className="schedule__day">Martes</p>
      </div>
      <div className="col">
        <p className="schedule__day-type">TREN SUPERIOR</p>
        <p className="schedule__day">Miércoles</p>
      </div>
      <div className="col">
        <p className="schedule__day-type">TREN INFERIOR</p>
        <p className="schedule__day">Jueves</p>
      </div>
      <div className="col">
        <p className="schedule__day-type">TREN SUPERIOR</p>
        <p className="schedule__day">Viernes</p>
      </div>
      <div className="col pe-0">
        <p className="schedule__day-type">FULL BODY</p>
        <p className="schedule__day">Sábado</p>
      </div>
    </div>
  );
};

export default MonterreyScheduleHeader;
