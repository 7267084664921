import React from "react";
import ResetButtons from "./ResetButtons";
import { S3_ENDPOINT } from "../../utils";

const Days = ({ setAnswer, resetPlanner, goBack }) => {
  return (
    <div className="row align-items-center">
      <div
        className="col-12 col-md-6 bg-image"
        style={{
          backgroundImage: `url("${`${S3_ENDPOINT}/workout-planner-3.jpeg`}")`,
        }}
      />

      <div className="col-12 col-md-6">
        <div className="p-md-5 p-3">
          <p>¿Cuántos días a la semana quieres entrenar?</p>
          <div className="row mt-4">
            <div className="col-12 col-md-4 my-2 text-center">
              <button
                className="btn btn-dark px-4 w-100"
                onClick={() => setAnswer("3")}
              >
                3
              </button>
            </div>
            <div className="col-12 col-md-4 my-2 text-center">
              <button
                className="btn btn-dark px-4 w-100"
                onClick={() => setAnswer("5")}
              >
                5
              </button>
            </div>
            <div className="col-12 col-md-4 my-2 text-center">
              <button
                className="btn btn-dark px-4 w-100"
                onClick={() => setAnswer("6")}
              >
                6
              </button>
            </div>
          </div>
          <ResetButtons resetAll={resetPlanner} goBack={goBack} />
        </div>
      </div>
    </div>
  );
};

export default Days;
