import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import { getLastTrack } from "../../utils";

const TracksChart = ({ tracks }) => {
  const renderLineChart = () => {
    if (Array.isArray(tracks)) {
      return (
        <Chart
          type="line"
          options={{
            type: "line",
            colors: ["#9d3355"],
            labels: tracks.map((track) =>
              moment(track.date).utc().format("DD/MM/YY")
            ),
            markers: {
              size: 7,
            },
          }}
          series={[
            {
              name: "Peso (kg)",
              data: tracks.map((track) => track.weight),
            },
          ]}
        />
      );
    }
  };

  const renderPieChart = () => {
    if (Array.isArray(tracks)) {
      const last_track = getLastTrack(tracks);
      if (last_track && last_track !== null) {
        const remainder =
          100 - parseInt(last_track.fat) - parseInt(last_track.muscle);
        return (
          <Chart
            type="pie"
            options={{
              chart: {
                width: 380,
                type: "pie",
              },
              colors: ["#9d3355", "#92B5D5", "#efeeea"],
              labels: ["Grasa (%)", "Músculo (%)", "Restante"],
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            series={[
              parseInt(last_track.fat),
              parseInt(last_track.muscle),
              parseInt(remainder),
            ]}
          />
        );
      }
    }
  };

  return (
    <div>
      <div className="card p-3 shadow mb-3">{renderLineChart()}</div>
      <div className="card p-3 shadow mb-3">{renderPieChart()}</div>
    </div>
  );
};

export default TracksChart;
