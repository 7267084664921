import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Page = ({
  title,
  children,
  fluid,
  className,
  verticalAlign,
  hideNavbar,
  hideFooter,
}) => {
  const verticalAlignClass = verticalAlign ? verticalAlign : 'align-items-center';

  return (
    <div className={`container-fluid px-0`}>
      {!hideNavbar && <Navbar />}
      <div className={`row page-content ${verticalAlignClass} mx-auto position-relative vw-100`}>
        <div className={` container${fluid ? "-fluid" : ""} px-4 pt-5 mx-auto`}>
          {title && title !== null && title !== "" && (
            <h1 className="my-3 pb-2 border-bottom">{title}</h1>
          )}
          {children}
        </div>
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Page;
