import React, { useState } from "react";
import OnlineLeaderboardRow from "./OnlineLeaderboardRow";

const OnlineLeaderboardTable = ({ query, customers, start_date, end_date }) => {
  const [sort, setSort] = useState("reservations");

  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      let customersRender = [...customers];
      customersRender = customersRender.sort((a, b) =>
        parseInt(a[sort]) > parseInt(b[sort]) ? -1 : 1
      );
      if (query && query !== "") {
        customersRender = customersRender.filter(
          ({ name, last_name, email }) =>
            String(name).includes(query) ||
            String(last_name).includes(query) ||
            String(email).includes(query)
        );
      }
      return customersRender.map((customer, index) => (
        <OnlineLeaderboardRow
          key={customer.customer_id}
          start_date={start_date}
          end_date={end_date}
          customer={customer}
          index={index}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light bold p-2">
            <th>
              <i className="fas fa-award"></i>
            </th>
            <th>Nombre</th>
            <th>Ciudad</th>
            <th>
              Reproducciones{" "}
              <button
                className="btn btn-sm px-0 py-0 ms-2"
                onClick={() => setSort("reservations")}
              >
                <i
                  className={`fa fa-chevron-${
                    sort === "reservations" ? "down" : "up"
                  }`}
                ></i>
              </button>
            </th>
            <th>Promedio Diario</th>
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default OnlineLeaderboardTable;
