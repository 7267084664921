import React, { createContext, useReducer, useContext } from "react";
import QuotesService from "../services/QuotesService";
import QuotesReducer from "../reducers/QuotesReducer";
import {
  QUOTES_RECEIVED,
  SET_QUOTE,
  CREATE_QUOTE,
  SET_PROPERTY_QUOTE,
} from "../types/quotes";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  quotes: null,
  quote: null,
};

export const QuotesContext = createContext(initialState);

export const QuotesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(QuotesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getQuotes = () => {
    QuotesService.getQuotes()
      .then((response) => {
        const { quotes } = response.data;
        dispatch({ type: QUOTES_RECEIVED, payload: quotes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleQuote = () => {
    QuotesService.getSingleQuote()
      .then((response) => {
        const { quote } = response.data;
        dispatch({ type: SET_QUOTE, payload: quote });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setQuote = (quote) => {
    dispatch({ type: SET_QUOTE, payload: quote });
  };

  const createQuote = () => {
    dispatch({ type: CREATE_QUOTE });
  };

  const setPropertyQuote = (key, value) => {
    dispatch({ type: SET_PROPERTY_QUOTE, payload: { key, value } });
  };

  const saveQuote = (quote, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = QuotesService.putQuote;
    if (isNaN(parseInt(quote.quote_id))) {
      service = QuotesService.postQuote;
    }
    service(quote)
      .then(() => {
        success("Quote saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteQuote = (quote_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    QuotesService.deleteQuote(quote_id)
      .then(() => {
        success("Quote deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <QuotesContext.Provider
      value={{
        ...state,
        setQuote,
        getQuotes,
        saveQuote,
        deleteQuote,
        createQuote,
        getSingleQuote,
        setPropertyQuote,
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
};
