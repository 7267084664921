
import React, { createContext, useReducer, useContext } from 'react';
import PaymentSourcesService from '../services/PaymentSourcesService';
import PaymentSourcesReducer from '../reducers/PaymentSourcesReducer';
import {
  PAYMENTSOURCES_RECEIVED,
  SET_PAYMENTSOURCE,
  CREATE_PAYMENTSOURCE,
  SET_PROPERTY_PAYMENTSOURCE,
} from "../types/PaymentSources";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  payment_sources: null,
  payment_source: null,
};

export const PaymentSourcesContext = createContext(initialState);

export const PaymentSourcesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaymentSourcesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPaymentSources = () => {
    PaymentSourcesService.getPaymentSources()
      .then((response) => {
        const { payment_sources } = response.data;
        dispatch({ type: PAYMENTSOURCES_RECEIVED, payload: payment_sources });
      })
      .catch((error) => {
        alert(error);
      });
  };

 
  const postStripePaymentSource = (payment_method, callback) => {
    return PaymentSourcesService.postStripePaymentSource(payment_method)
      .then((res) => {
        const payment_source = res.data.payment_source;
        success("Método de pago guardado.");
        if (typeof callback === "function") {
          callback(payment_source.payment_source_id);
        }
        getPaymentSources();
        clearModal();
      })
      .catch(err => {
        alert(err);
      });
  };

  const deletePaymentSource = (payment_source_id) => {
    PaymentSourcesService.deletePaymentSource(payment_source_id)
      .then(() => {
        success("Método de Pago eliminado con éxito.");
        getPaymentSources();
        clearModal();
      })
      .catch(() => {
        alert("Hubo un error al eliminar este método de pago.");
      });
  };

 

  return (
    <PaymentSourcesContext.Provider
    value={{
      ...state,
      getPaymentSources,
      postStripePaymentSource,
      deletePaymentSource,
    }}
  >
    {children}
  </PaymentSourcesContext.Provider>
);
};
