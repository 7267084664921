
import api from './api';

const route = '/testimonials';

export default {
  getTestimonials: () => api.get(route),
  getSingleTestimonial: (testimonial_id) => api.get(`${route}/${testimonial_id}`),
  postTestimonial: (testimonial) => api.post(route, { ...testimonial}),
  putTestimonial: (testimonial) => api.put(route, { ...testimonial}),
  deleteTestimonial: (Testimonial) => api.delete(`${route}/${Testimonial}`),
};
