
import React, { createContext, useReducer, useContext } from 'react';
import CustomerNotificationTypesService from '../services/CustomerNotificationTypesService';
import CustomerNotificationTypesReducer from '../reducers/CustomerNotificationTypesReducer';
import {
  CUSTOMERNOTIFICATIONTYPES_RECEIVED,
  SET_CUSTOMERNOTIFICATIONTYPE,
  CREATE_CUSTOMERNOTIFICATIONTYPE,
  SET_PROPERTY_CUSTOMERNOTIFICATIONTYPE,
} from "../types/customer_notification_types";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  customer_notification_types: null,
  customer_notification_type: null,
};

export const CustomerNotificationTypesContext = createContext(initialState);

export const CustomerNotificationTypesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerNotificationTypesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCustomerNotificationTypes = () => {
    CustomerNotificationTypesService.getCustomerNotificationTypes()
      .then((response) => {
        const { customer_notification_types } = response.data;
        dispatch({ type: CUSTOMERNOTIFICATIONTYPES_RECEIVED, payload: customer_notification_types });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCustomerNotificationType = (CustomerNotificationType_id) => {
    CustomerNotificationTypesService.getSingleCustomerNotificationType(CustomerNotificationType_id)
      .then((response) => {
        const { customer_notification_type } = response.data;
        dispatch({ type: SET_CUSTOMERNOTIFICATIONTYPE, payload: customer_notification_type });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCustomerNotificationType = (customer_notification_type) => {
    dispatch({ type: SET_CUSTOMERNOTIFICATIONTYPE, payload: customer_notification_type });
  };

  const createCustomerNotificationType = () => {
    dispatch({ type: CREATE_CUSTOMERNOTIFICATIONTYPE });
  };

  const setPropertyCustomerNotificationType = (key, value) => {
    dispatch({ type: SET_PROPERTY_CUSTOMERNOTIFICATIONTYPE, payload: { key, value } });
  };

  const saveCustomerNotificationType = (customer_notification_type, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = CustomerNotificationTypesService.putCustomerNotificationType;
      if(isNaN(parseInt(customer_notification_type.CustomerNotificationType_id))) {
        service = CustomerNotificationTypesService.postCustomerNotificationType;
      }
      service(customer_notification_type).then(() => {
        success("customer_notification_type saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCustomerNotificationType = (CustomerNotificationType_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    CustomerNotificationTypesService.deleteCustomerNotificationType(CustomerNotificationType_id).then(() => {
      success("customer_notification_type deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <CustomerNotificationTypesContext.Provider
    value={{
      ...state,
      setCustomerNotificationType,
      getCustomerNotificationTypes,
      saveCustomerNotificationType,
      deleteCustomerNotificationType,
      createCustomerNotificationType,
      getSingleCustomerNotificationType,
      setPropertyCustomerNotificationType,

    }}
  >
    {children}
  </CustomerNotificationTypesContext.Provider>
);
};
