import React from "react";
import { S3_ENDPOINT } from "../../utils";
import useBranch from "../../hooks/useBranch";

const LandingAboutDesktop = () => {
  const { content } = useBranch();

  return (
    <div className="container-fluid hide-mobile px-5 text-center">
      <div className="row align-items-center">
        <div className="col-12 col-lg-4">
          <img
            className="mw-100 w-100 d-block m-auto br-25 mb-3"
            src={`${S3_ENDPOINT}/${content.about.desktop.left}`}
            alt="Únete a esta comunidad"
          />
          <p>
            Únete a esta comunidad en donde nos inspiramos y empoderamos a la
            mujer a través del movimiento.
          </p>
          <p>
            Cambia tu vida de manera positiva con nuestro método fitness único
            respaldado por la ciencia.
          </p>
        </div>
        <div className="col-12 col-lg-4 h-100">
          <div
            id="center-image"
            className="bg-image br-25 p-3"
            style={{
              backgroundImage: `url('${S3_ENDPOINT}/${content.about.desktop.center}')`,
            }}
          >
            <div className="row align-items-center h-100">
              <div className="container text-white p-5">
                <h2 className="h1 display-5 futura text-sentence text-shadow">
                  Everything <br />
                  You Want <br />
                  in a Workout
                </h2>
                <p className="text-shadow bold">
                  Conecta contigo y siéntete viva a través del ejercicio.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <img
            style={{ marginTop: -24 }}
            className="mw-100 w-100 d-block br-25 mb-3"
            src={`${S3_ENDPOINT}/${content.about.desktop.right}`}
            alt="Organiza tus clases"
          />
          <p>
            Haz que cada segundo cuente con nuestras rutinas divertidas y
            estratégicamente planeadas.
          </p>
          <p>
            Organiza tus clases de acuerdo a tus objetivos, días de
            entrenamiento y experiencia fitness.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingAboutDesktop;