import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import PaqueteFeatures from "../paquetes/PaqueteFeatures";
import { features } from "../../utils/paquetes";
import { formatMonto, S3_ENDPOINT } from "../../utils";

const CheckoutClassPackage = ({ class_package_id }) => {
  const { descuento, class_package, getPaquete } = useContext(CheckoutContext);

  const onSale = () =>
    class_package !== null
      ? class_package.sale_price && class_package.sale_price !== null
      : false;

  useEffect(() => {
    getPaquete(class_package_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_package_id]);

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total = class_package.price;
      if (descuento.type !== "class_amount") {
        if (descuento.is_percent) {
          let porcentaje = parseFloat(1 - descuento.amount / 100);
          total = parseFloat(class_package.price) * porcentaje;
        } else {
          total = class_package.price - descuento.amount;
        }
        total = parseFloat(total).toFixed(2);
        return (
          <div className="container-fluid px-0">
            <h5>
              Total con Descuento: {"$"}
              {total} MXN {renderDiscountPeriod()}
            </h5>
          </div>
        );
      }
      return (
        <div className="container-fluid px-0">
          <h5>Recibe {descuento.amount} clases presenciales de regalo</h5>
        </div>
      );
    }
  };

  const renderDiscountPeriod = () => {
    if (descuento && descuento !== null) {
      if (descuento.first_invoice_only) {
        return `tu primer pago. Después $${formatMonto(
          class_package.price
        )} MXN ${renderPeriod()}`;
      }
    }
  };

  const renderPeriod = () => {
    if (class_package.subscription_period === "month") {
      return "por mes";
    }
  };

  const renderImage = () => {
    const thumbnail = class_package.thumbnail_file;
    if (thumbnail) {
      return (
        <img
          src={
            thumbnail.src
              ? thumbnail.src
              : `${S3_ENDPOINT}/${thumbnail.name}.${thumbnail.type}`
          }
          style={{ objectFit: "cover" }}
          className="class_package-img mb-3 w-100 h-auto "
          alt="package thumbnail"
        />
      );
    }
  };

  const renderDescription = () => {
    const htmlRegex = /<([a-z]+)\s*\/?[^>]*>/i;
    const isHTML = htmlRegex.test(class_package.description);

    if (isHTML) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: class_package.description }}
          style={{ overflowY: "auto", flex: 1 }}
        ></div>
      );
    } else {
      return <p>{class_package.description}</p>;
    }
  };

  const renderPresenciales = () => {
    if (class_package.class_amount !== null && class_package.class_amount > 0) {
      return (
        <div className="mt-3">
          <p className="mb-1">
            Acceso a {class_package.class_amount} clases presenciales con
            vigencia de {class_package.expiration_days} días
          </p>
          {class_package.include_online && (
            <p className="mb-1">
              Acceso a TBM Online ilimitado (clases en línea)
            </p>
          )}
        </div>
      );
    }
  };

  const renderclass_package = () => {
    if (class_package && class_package !== null) {
      const price =
        class_package.sale_price !== null && class_package.sale_price !== ""
          ? class_package.sale_price
          : class_package.price;
      return (
        <div>
          {renderImage()}
          <h3>{class_package.title}</h3>
          {renderPresenciales()}
          {class_package.is_special_event && <p>{class_package.description}</p>}
          {class_package.class_amount > 0 && (
            <ul style={{ listStyleType: "none" }} className="ps-0 ms-0">
              <li>
                <i className="fa fa-check me-1" />
                Reserva tus clases presenciales
              </li>
            </ul>
          )}
          {class_package.include_online && (
            <PaqueteFeatures features={features} />
          )}
          <h5>
            Total:
            {onSale() && (
              <span className="strike-through d-inline-block mx-1 text-danger">
                {"$"}
                {formatMonto(class_package.price)}
              </span>
            )}
            {"$"}
            {formatMonto(onSale() ? class_package.sale_price : price)}
            {" MXN"} {renderPeriod()}
          </h5>
          {renderResultadoDescuento()}
        </div>
      );
    }
  };

  return (
    <div>
      <h3>Estás Comprando</h3>
      <div className="mb-3 card no-scale">{renderclass_package()}</div>
    </div>
  );
};

export default CheckoutClassPackage;
