
import { getArgs } from '../utils';
import api from './api';

const route = '/playbacks';

export default {
  getPlaybacks: () => api.get(route),
  getPlaybacksByDate: (filters) => api.get(`${route}/date?${getArgs(filters)}`),
  getSinglePlayback: (playback_id) => api.get(`${route}/${playback_id}`),
  postPlayback: (playback) => api.post(route, { ...playback}),
  putPlayback: (playback) => api.put(route, { ...playback}),
  deletePlayback: (Playback) => api.delete(`${route}/${Playback}`),
};
