import React, { useState, useEffect, useContext } from "react";
import { VideosContext } from "../../context/VideosContext";
import ProgramCard from "../programs/ProgramCard";
import ItemCarousel from "../global/ItemCarousel";

const LandingPrograms = () => {
  const [components, setComponents] = useState([]);
  const { programs, getPrograms } = useContext(VideosContext);

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    if (Array.isArray(programs)) {
      handlePrograms();
    }
  }, [programs]);

  const handlePrograms = () => {
    let current = [];
    programs.forEach((program) =>
      current.push(<ProgramCard key={program.program_id} program={program} />)
    );
    setComponents(current);
  };

  const renderProgramas = () => {
    if (Array.isArray(programs)) {
      return programs.map((program) => (
        <ProgramCard key={program.program_id} program={program} />
      ));
    }
  };

  return (
    <div className="container-fluid text-center px-5 py-5 bg-light">
      <h2 className="font-header-secondary">Programas</h2>
      <p className="mb-5">
        Conoce nuestro método una combinación de Cardio + Fuerza + Barre y
        entrena inteligente!
      </p>
      <div className="mw-1600 m-auto my-3 mb-5 hide-mobile">
        <div className="row mb-4">{renderProgramas()}</div>
      </div>
      <div className="show-mobile">
        <ItemCarousel
          id="programs"
          wide
          dark
          automatic
          items={renderProgramas()}
        />
      </div>
      <p className="small text-muted mt-md-0 mt-4">
        *Cualquier membresía incluye acceso a todos nuestros programas
      </p>
    </div>
  );
};

export default LandingPrograms;
