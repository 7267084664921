import React from "react";
import { S3_ENDPOINT } from "../../utils";

const WorkoutPlannerCover = ({ onClick }) => {
  return (
    <div className="container-fluid px-0">
      <div className="row align-items-center">
        <div
          className="col-12 col-md-6 bg-image"
          style={{
            backgroundImage: `url("${`${S3_ENDPOINT}/workout-planner-1.jpg`}")`,
          }}
        />
        <div className="col-12 col-md-6">
          <div className="p-md-5 p-3">
            <h1>Workout Planner</h1>
            <h2>DREAM, PLAN, DO!</h2>
            <p>
              ¡Ayúdanos a personalizar tu entrenamiento de acuerdo a tus
              objetivos y necesidades contestando las siguientes preguntas para
              recibir nuestras recomendaciones sobre cómo organizar tus clases!
            </p>
            <button className="btn btn-primary" onClick={onClick}>
              Comenzar Ahora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkoutPlannerCover;
