
import api from './api';

const route = '/frequent_questions';

const FrequentQuestionsService = {
  getFrequentQuestions: () => api.get(route),
};

export default FrequentQuestionsService;
