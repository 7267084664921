import React from "react";
import PanelHeader from "../components/panel/PanelHeader";
import UserInformation from "./UserInformation";
import UserAddress from "./UserAddress";

const MiInformacion = () => {
  return (
    <div className="container px-4 px-md-0 py-4">
      <PanelHeader title="Mi Informacion" />
      <UserInformation />
      <UserAddress />
    </div>
  );
};

export default MiInformacion;
