import api from "./api";

const route = "/quotes";

export default {
  getQuotes: () => api.get(route),
  getSingleQuote: () => api.get(route),
  postQuote: (quote) => api.post(route, { ...quote }),
  putQuote: (quote) => api.put(route, { ...quote }),
  deleteQuote: (Quote) => api.delete(`${route}/${Quote}`),
};
