import React from "react";

const PlannerColorLegend = () => {
  return (
    <div className="container-fluid px-2 mb-2">
      <div className="row">
        <div className="col-3">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-light pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">Descanso</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-primary-light pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">No Completado</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-primary pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">Próximo</span>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-2 px-0">
              <div className="color-legend br-25 bg-success pe-0"></div>
            </div>
            <div className="col-10 ps-0">
              <span className="small">Completado</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlannerColorLegend;
