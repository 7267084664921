import React from "react";
import moment from "moment";

const WaitlistsCard = ({ waitlist, handleCancel }) => {
  const renderInstructorName = (class_waitlist) => {
    if (class_waitlist.single_class !== null) {
      const instructors = class_waitlist.single_class.class_instructors;
      if (instructors.length > 0) {
        if (instructors.length === 1) {
          return instructors[0].name;
        } else {
          return instructors.map(({ name }) => name).join(", ");
        }
      }
    }
  };

  const renderClassType = () => {
    const { single_class } = waitlist;
    if (single_class && single_class !== null) {
      const { class_type } = single_class;
      if (class_type && class_type !== null) {
        return class_type.name;
      }
    }
  };

  return (
    <div className="card px-0 py-0 no-scale mb-2">
      <div className="card-header">
        <div className="row align-items-center my-2">
          <div className="col-6">
            <h4 className="mb-0">{renderClassType()}</h4>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">
              {moment(waitlist.single_class.class_date)
                .utc()
                .format("DD MMM YY, HH:mm")}
            </p>
          </div>
        </div>
      </div>
      <div className="card-body">
        <p>{renderInstructorName(waitlist)}</p>
      </div>
      <div className="card-footer">
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => handleCancel(waitlist)}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    </div>
  );
};

export default WaitlistsCard;
