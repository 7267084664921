
import React, { createContext, useReducer, useContext } from 'react';
import GoalsService from '../services/GoalsService';
import GoalsReducer from '../reducers/GoalsReducer';
import {
  GOALS_RECEIVED,
  SET_GOAL,
  CREATE_GOAL,
  SET_PROPERTY_GOAL,
} from "../types/goals";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  goals: null,
  goal: null,
};

export const GoalsContext = createContext(initialState);

export const GoalsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GoalsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getGoals = () => {
    GoalsService.getGoals()
      .then((response) => {
        const { goals } = response.data;
        dispatch({ type: GOALS_RECEIVED, payload: goals });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleGoal = (goal_id) => {
    GoalsService.getSingleGoal(goal_id)
      .then((response) => {
        const { goal } = response.data;
        dispatch({ type: SET_GOAL, payload: goal });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setGoal = (goal) => {
    dispatch({ type: SET_GOAL, payload: goal });
  };

  const createGoal = () => {
    dispatch({ type: CREATE_GOAL });
  };

  const setPropertyGoal = (key, value) => {
    dispatch({ type: SET_PROPERTY_GOAL, payload: { key, value } });
  };

  const saveGoal = (goal, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = GoalsService.putGoal;
      if(isNaN(parseInt(goal.goal_id))) {
        service = GoalsService.postGoal;
      }
      service(goal).then(() => {
        success("Goal saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteGoal = (goal_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    GoalsService.deleteGoal(goal_id).then(() => {
      success("Goal deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <GoalsContext.Provider
    value={{
      ...state,
      setGoal,
      getGoals,
      saveGoal,
      deleteGoal,
      createGoal,
      getSingleGoal,
      setPropertyGoal,

    }}
  >
    {children}
  </GoalsContext.Provider>
);
};
