import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'

import './embla.css'
import { S3_ENDPOINT } from '../../utils'

const OPTIONS = { align: 'start' }

const ClassTypesCarousel = ({ class_types }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return(
    <>
      <section className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {class_types?.map((type) => {
              const src = type.file !== null
                ? `${S3_ENDPOINT}/${type.file.name}.${type.file.type}`
                : "";

              return (
                <div className="embla__slide" key={type.class_type_id}>
                  <div className="embla__slide__number" style={{
                    backgroundColor: '#FAFAFA'
                  }}>
                    <p className='embla__type-name'>{type.name}</p>
                    <img alt='class type image' className='embla__type-image' src={src}/>
                    <p className='embla__type-description gothic-font'>{type.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="embla__controls">
          <div className="embla__buttons">
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>

          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={'embla__dot'.concat(
                  index === selectedIndex ? ' embla__dot--selected' : ''
                )}
              />
            ))}
          </div>
        </div>

        <div className='row justify-content-center mx-auto class-types-cta__container'>
          <button 
            type="button"
            className="btn text-white bold fs-5 px-4 monterrey-degraded-btn"
            style={{ borderRadius: '40px', height: '50px', width: 'max-content'}}
          >
            COMIENZA YA
            <i className="fas fa-arrow-right ms-2"/>
          </button>
        </div>
      </section>
    </>
  )
}



export default ClassTypesCarousel;

