import React, { useContext, useEffect } from "react";
import SearchableMultipleSelect from "../common/SearchableMultipleSelect";
import { PropsContext } from "../../context/PropsContext";
import { FiltersContext } from "../../context/FiltersContext";
import { getValue } from "../../utils";

const FilterVideoProps = () => {
  const { props, getProps } = useContext(PropsContext);
  const filters = useContext(FiltersContext);
  const { setFilter } = filters;

  useEffect(() => {
    getProps();
  }, []);

  const getPropsRender = () => {
    if (Array.isArray(props)) {
      return [{ prop_id: null, name: "Sin Props" }, ...props];
    }
    return props;
  };

  const addProp = (prop) => {
    if (prop.prop_id === null) {
      return setFilter("props", null);
    }
    let currentProps = getValue(filters, "props");
    if (!Array.isArray(currentProps)) currentProps = [];
    currentProps.push(prop.prop_id);
    setFilter("props", currentProps);
  };

  return (
    <SearchableMultipleSelect
      showAll
      hideCreate
      hideSelected
      label="Props"
      nameKey="name"
      itemName="Prop"
      addItem={addProp}
      key_id={"prop_id"}
      fetchItems={getProps}
      items={getPropsRender()}
      selected={filters.props}
    />
  );
};

export default FilterVideoProps;