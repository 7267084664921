import api from "./api";
import { getArgs } from "../utils";

const route = "/workout_plans";

export default {
  getWorkoutPlans: () => api.get(route),
  getSingleWorkoutPlan: (workout_plan_id) =>
    api.get(`${route}/${workout_plan_id}`),
  getWorkoutPlanByQuery: (filters) =>
    api.get(`${route}/query/single?${getArgs(filters)}`),
  postWorkoutPlan: (workout_plan) => api.post(route, { ...workout_plan }),
  putWorkoutPlan: (workout_plan) => api.put(route, { ...workout_plan }),
  deleteWorkoutPlan: (workout_plan_id) =>
    api.delete(`${route}/${workout_plan_id}`),
};
