import React, { useContext, useEffect } from "react";
import { LocationsContext } from "../../context/LocationsContext";
import { AddressContext } from "../../context/AddressContext";
import SearchSelect from "../common/SearchSelect";
import { getValue } from "../../utils";

const AddressForm = ({
  title,
  saveAction,
  hideButtons,
  handleCancel,
  handleCallback,
}) => {
  const { address, saveAddress, setPropertyAddress } =
    useContext(AddressContext);

  const { cities, states, countries, getCities, getStates, getCountries } =
    useContext(LocationsContext);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (address.country_code !== null) {
      getStates(address.country_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.country_code]);

  useEffect(() => {
    if (address.state_code !== null && address.country_code !== null) {
      getCities(address.country_code, address.state_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.state_code, address.country_code]);

  const disableTextInputs =
    address.country_code === null ||
    address.state_code === null ||
    address.city === null;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (getValue(address, "city") === "") {
      return alert("Debes agregar tu ciudad.");
    }
    if (getValue(address, "state") === "") {
      return alert("Debes agregar tu estado.");
    }
    if (getValue(address, "country") === "") {
      return alert("Debes agregar tu país.");
    }
    if (typeof saveAction === "function") {
      return saveAction(address);
    }
    saveAddress(address, handleCallback);
  };

  const renderButtons = () => {
    if (!hideButtons) {
      return (
        <div className="row mt-3">
          <div className="col">
            <button type="submit" className="btn btn-primary w-100">
              Guardar
            </button>
          </div>
          {handleCancel && (
            <div className="col">
              <button
                type="button"
                onClick={handleCancel}
                className="btn text-muted w-100 px-0"
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      {title && <h2>{title}</h2>}{" "}
      <div className="col-md-4 col-12 mb-2">
        <label htmlFor="country" className="form-label">
          País <span className="small text-danger">* (requerido)</span>
        </label>
        {Array.isArray(countries) ? (
          <SearchSelect
            itemText="name"
            itemValue="iso2"
            items={countries}
            value={getValue(address.country)}
            modifier={(country) => {
              setPropertyAddress("country", country.label);
              setPropertyAddress("country_code", country.value);
            }}
          />
        ) : (
          <div className="spinner-border small d-block my-2" />
        )}
      </div>
      <div className="col-md-4 col-12 mb-2">
        <label htmlFor="state" className="form-label">
          Estado <span className="small text-danger">* (requerido)</span>
        </label>
        {Array.isArray(states) ? (
          <SearchSelect
            itemText="name"
            itemValue="iso2"
            items={states}
            value={getValue(address.state)}
            modifier={(state) => {
              setPropertyAddress("state", state.label);
              setPropertyAddress("state_code", state.value);
            }}
          />
        ) : address.country_code !== null ? (
          <div className="spinner-border small d-block my-2" />
        ) : (
          <p className="small mb-1">Por favor, elige tu país.</p>
        )}
      </div>
      <div className="col-12 col-md-4 mb-2">
        <label htmlFor="city" className="form-label">
          Ciudad <span className="small text-danger">* (requerido)</span>
        </label>
        {Array.isArray(cities) ? (
          <SearchSelect
            itemText="name"
            itemValue="iso2"
            items={cities}
            value={getValue(address.city)}
            modifier={(city) => {
              setPropertyAddress("city", city.label);
            }}
          />
        ) : address.state_code !== null ? (
          <div className="spinner-border small d-block my-2" />
        ) : (
          <p className="small mb-1">Por favor, elige tu estado.</p>
        )}
      </div>
      <div className="col-md-6 mb-2">
        <label htmlFor="street" className="form-label">
          Calle <span className="small">(opcional)</span>
        </label>
        <input
          type="text"
          id="text"
          name="street"
          className="form-control"
          disabled={disableTextInputs}
          value={getValue(address, "street")}
          onChange={(event) => setPropertyAddress("street", event.target.value)}
        />
      </div>
      <div className="col-md-3 col-6 mb-2">
        <label htmlFor="number" className="form-label">
          Número <span className="small">(opcional)</span>
        </label>
        <input
          type="text"
          id="number"
          name="number"
          className="form-control"
          disabled={disableTextInputs}
          value={getValue(address, "number")}
          onChange={(event) => setPropertyAddress("number", event.target.value)}
        />
      </div>
      <div className="col-md-3 col-6 mb-2">
        <label htmlFor="interiorNumber" className="form-label">
          Interior <span className="small">(opcional)</span>
        </label>
        <input
          type="text"
          id="interiorNumber"
          name="interiorNumber"
          className="form-control"
          disabled={disableTextInputs}
          value={getValue(address, "interior_number")}
          onChange={(event) =>
            setPropertyAddress("interior_number", event.target.value)
          }
        />
      </div>
      <div className="col-12 col-md-6 mb-2">
        <label htmlFor="neighborhood" className="form-label">
          Colonia <span className="small">(opcional)</span>
        </label>
        <input
          type="text"
          id="neighborhood"
          name="neighborhood"
          className="form-control"
          disabled={disableTextInputs}
          value={getValue(address, "neighborhood")}
          onChange={(event) =>
            setPropertyAddress("neighborhood", event.target.value)
          }
        />
      </div>
      <div className="col-md-4 col-12 mb-2">
        <label htmlFor="zip_code" className="form-label">
          Código Postal <span className="small">(opcional)</span>
        </label>
        <input
          type="text"
          id="zip_code"
          name="zip_code"
          className="form-control"
          disabled={disableTextInputs}
          value={getValue(address, "zip_code")}
          onChange={(event) =>
            setPropertyAddress("zip_code", event.target.value)
          }
        />
      </div>
      <div className="col-12">{renderButtons()}</div>
    </form>
  );
};

export default AddressForm;
