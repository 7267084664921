
import React, { createContext, useReducer, useContext } from 'react';
import WaitlistsService from '../services/WaitlistsService';
import WaitlistsReducer from '../reducers/WaitlistsReducer';
import {
  WAITLISTS_RECEIVED,
  SET_WAITLIST,
  CREATE_WAITLIST,
  SET_PROPERTY_WAITLIST,
} from "../types/waitlists";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  waitlists: null,
  waitlist: null,
};

export const WaitlistsContext = createContext(initialState);

export const WaitlistsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WaitlistsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getWaitlists = () => {
    WaitlistsService.getWaitlists()
      .then((response) => {
        const { waitlists } = response.data;
        dispatch({ type: WAITLISTS_RECEIVED, payload: waitlists });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleClassWaitlist = (single_class_id) => {
    WaitlistsService.getSingleClassWaitlist(single_class_id)
      .then((response) => {
        const { waitlist } = response.data;
        dispatch({ type: SET_WAITLIST, payload: waitlist });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setWaitlist = (waitlist) => {
    dispatch({ type: SET_WAITLIST, payload: waitlist });
  };

  const createWaitlist = () => {
    dispatch({ type: CREATE_WAITLIST });
  };

  const setPropertyWaitlist = (key, value) => {
    dispatch({ type: SET_PROPERTY_WAITLIST, payload: { key, value } });
  };

  const saveWaitlist = (waitlist, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = WaitlistsService.putWaitlist;
      if(isNaN(parseInt(waitlist.waitlist_id))) {
        service = WaitlistsService.postWaitlist;
      }
      service(waitlist).then(() => {
        success("Anotada en lista de espera.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteWaitlist = (waitlist_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    WaitlistsService.deleteWaitlist(waitlist_id).then(() => {
      success("Eliminada de lista de espera.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <WaitlistsContext.Provider
    value={{
      ...state,
      setWaitlist,
      getWaitlists,
      saveWaitlist,
      deleteWaitlist,
      createWaitlist,
      setPropertyWaitlist,
      getSingleClassWaitlist,
    }}
  >
    {children}
  </WaitlistsContext.Provider>
);
};
