import React, { useContext } from "react";
import { FiltersContext } from "../../context/FiltersContext";
import { getValue } from "../../utils";

const FilterOption = ({ id_key, label, options }) => {
  const filters = useContext(FiltersContext);
  const { setFilter } = filters;

  const getKey = (option, index) => {
    if (typeof option === "string") {
      return option;
    }
    if (typeof option === "object" && option !== null) {
      if (option[id_key]) {
        return option[id_key];
      }
    }
    return index;
  };

  const getName = (option, index) => {
    if (typeof option === "string") {
      return option;
    }
    if (typeof option === "object" && option !== null) {
      return option.name;
    }
    return index;
  };

  const handleChange = (e) => {
    let { value } = e.target;
    if (value === "") value = null;
    setFilter(id_key, value);
  };

  const renderOptions = () => {
    if (Array.isArray(options)) {
      return [
        <option key="0" value="">
          Todo
        </option>,
        ...options.map((current, index) => (
          <option key={getKey(current, index)} value={getKey(current, index)}>
            {getName(current, index)}
          </option>
        )),
      ];
    }
  };
  return (
    <div>
      <label className="mb-1">{label}</label>
      <select
        className="form-control"
        value={getValue(filters, id_key)}
        onChange={handleChange}
      >
        {renderOptions()}
      </select>
    </div>
  );
};

export default FilterOption;
