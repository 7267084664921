
import React, { createContext, useReducer, useContext } from 'react';
import AddressService from '../services/AddressService';
import AddressReducer from '../reducers/AddressReducer';
import {
  ADDRESSS_RECEIVED,
  SET_ADDRESS,
  CREATE_ADDRESS,
  SET_PROPERTY_ADDRESS,
} from "../types/addresses";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  addresses: null,
  address: null,
};

export const AddressContext = createContext(initialState);

export const AddressProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AddressReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAddress = () => {
    AddressService.getAddress()
      .then((response) => {
        const { addresses } = response.data;
        dispatch({ type: ADDRESSS_RECEIVED, payload: addresses });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleAddress = (address_id) => {
    AddressService.getSingleAddress(address_id)
      .then((response) => {
        const { address } = response.data;
        dispatch({ type: SET_ADDRESS, payload: address });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setAddress = (address) => {
    dispatch({ type: SET_ADDRESS, payload: address });
  };

  const createAddress = () => {
    dispatch({ type: CREATE_ADDRESS });
  };

  const setPropertyAddress = (key, value) => {
    dispatch({ type: SET_PROPERTY_ADDRESS, payload: { key, value } });
  };

  const saveAddress = (address, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = AddressService.putAddress;
      if(isNaN(parseInt(address.address_id))) {
        service = AddressService.postAddress;
      }
      service(address).then(() => {
        success("Dirección guardada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteAddress = (address_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    AddressService.deleteAddress(address_id).then(() => {
      success("Address deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <AddressContext.Provider
    value={{
      ...state,
      setAddress,
      getAddress,
      saveAddress,
      deleteAddress,
      createAddress,
      getSingleAddress,
      setPropertyAddress,

    }}
  >
    {children}
  </AddressContext.Provider>
);
};
