import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCardForm from "./StripeCardForm";
import useBranch from "../../hooks/useBranch";

const StripeForm = () => {
  const { branch } = useBranch();
  return (
    <div className="container-fluid position-relative h-100 px-0">
      <Elements stripe={loadStripe(branch.stripe_public)}>
        <StripeCardForm />
      </Elements>
    </div>
  );
};

export default StripeForm;
