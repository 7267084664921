import React from "react";
import WaitlistRow from "./WaitlistRow";

const WaitlistsTable = ({ waitlists, confirmCancel }) => {
  return (
    <div className="table-responsive hide-mobile">
      <table className="table">
        <thead>
          <tr className="bg-light border mx-0 py-2 bold hide-mobile">
            <td>Clase</td>
            <td>Coach</td>
            <td>Fecha y Hora</td>
            <td>Anotada en</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>
          {waitlists.map((waitlist) => (
            <WaitlistRow
              waitlist={waitlist}
              handleCancel={confirmCancel}
              key={waitlist.waitlist_id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WaitlistsTable;
