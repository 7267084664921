import React from "react";
import VideoImage from "../videos/VideoImage";

const PostVideo = ({ post, handleVideo }) => {
  const renderVideoTo = () => {
    if (post.video && post.video !== null) {
      return (
        <div
          onClick={() => handleVideo(post.video_id)}
          className="reply px-md-4 px-3 bg-white pb-md-3 pb-2 br-15 text-dark py-3 my-2"
        >
          <div className="row align-items-center">
            <div className="col-6 col-md-1 mb-2">
              <VideoImage video={post.video} />
            </div>
            <div className="col-12 col-md-11 mb-2">
              <p className="mb-0 bold">{post.video.name}</p>
              <span className="small">{post.video.description}</span>
            </div>
          </div>
        </div>
      );
    }
  };
  return <div>{renderVideoTo()}</div>;
};
export default PostVideo;
