
import React, { createContext, useReducer, useContext } from 'react';
import NotificationsService from '../services/NotificationsService';
import NotificationsReducer from '../reducers/NotificationsReducer';
import {
  NOTIFICATIONS_RECEIVED,
  SET_NOTIFICATION,
  CREATE_NOTIFICATION,
  SET_PROPERTY_NOTIFICATION,
} from "../types/notifications";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  notifications: null,
  notification: null,
};

export const NotificationsContext = createContext(initialState);

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getNotifications = () => {
    NotificationsService.getNotifications()
      .then((response) => {
        const { notifications } = response.data;
        dispatch({ type: NOTIFICATIONS_RECEIVED, payload: notifications });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleNotification = (notification_id) => {
    NotificationsService.getSingleNotification(notification_id)
      .then((response) => {
        const { notification } = response.data;
        dispatch({ type: SET_NOTIFICATION, payload: notification });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setNotification = (notification) => {
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  };

  const createNotification = () => {
    dispatch({ type: CREATE_NOTIFICATION });
  };

  const setPropertyNotification = (key, value) => {
    dispatch({ type: SET_PROPERTY_NOTIFICATION, payload: { key, value } });
  };

  const saveNotification = (notification, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = NotificationsService.putNotification;
      if(isNaN(parseInt(notification.notification_id))) {
        service = NotificationsService.postNotification;
      }
      service(notification).then(() => {
        success("Notification saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteNotification = (notification_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    NotificationsService.deleteNotification(notification_id).then(() => {
      success("Notification deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <NotificationsContext.Provider
    value={{
      ...state,
      setNotification,
      getNotifications,
      saveNotification,
      deleteNotification,
      createNotification,
      getSingleNotification,
      setPropertyNotification,

    }}
  >
    {children}
  </NotificationsContext.Provider>
);
};
