
import { getArgs } from '../utils';
import api from './api';

const route = '/available_classes';

const AvailableClassesService = {
  getAvailableClasses: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleAvailableClass: (availableClass_id) => api.get(`${route}/${availableClass_id}`),
  postAvailableClass: (availableClass) => api.post(route, { ...availableClass}),
  putAvailableClass: (availableClass) => api.put(route, { ...availableClass}),
  deleteAvailableClass: (AvailableClass) => api.delete(`${route}/${AvailableClass}`),
};

export default AvailableClassesService;
