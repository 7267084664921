import moment from "moment";
import React from "react";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";

const TrackCard = ({ track }) => {
  const renderFiles = () => {
    if (track.files.length > 0) {
      return track.files.map((file) => (
        <img
          key={file.file_id}
          style={{ width: 100, height: 100, objectFit: "cover" }}
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
        />
      ));
    }
  };

  const renderValue = (key, suffix) => {
    let value = "N/D";
    if (track[key] && track[key] !== null && track[key] !== "") {
      value = track[key];
    }
    return (
      <p className="small mb-2">
        {value}
        {suffix}
      </p>
    );
  };

  return (
    <div className="card shadow no-scale br-10 py-3 px-4 pb-4 mb-3">
      <div className="row">
        <div className="col-12 col-md-6">
          <div>
            <span className="small mb-0">
              {moment(track.date).utc().format("DD MMM YYYY")}
            </span>
            <Link
              to={`/mytbm/track/${track.track_id}`}
              className="btn btn-sm px-0 py-0 mx-3 text-muted"
            >
              <i className="fa fa-edit"></i>
            </Link>
          </div>
          <label className="bold">Comentario</label>
          <p className="small">{track.comment}</p>
          <div className="row">{renderFiles()}</div>
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-6">
              <label className="small bold d-block">Grasa</label>
              {renderValue("fat", "%")}
            </div>
            <div className="col-6">
              <label className="small bold d-block">Músculo</label>
              {renderValue("muscle", "%")}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className="small bold d-block">Pecho</label>
              {renderValue("chest")}
              <label className="small bold d-block">Cintura</label>
              {renderValue("waist")}
              <label className="small bold d-block">Abdomen</label>
              {renderValue("abs")}
            </div>
            <div className="col-6">
              <label className="small bold d-block">Cadera</label>
              {renderValue("hips")}
              <label className="small bold d-block">Brazo</label>
              {renderValue("arms")}
              <label className="small bold d-block">Muslo</label>
              {renderValue("thigh")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TrackCard;
