import moment from "moment";
import React from "react";

const PostMeta = ({ post, isMyPost }) => {
  const renderStaff = () => {
    if (post.customer.user && post.customer.user !== null) {
      return (
        <span className="text-capitalize">
          <i className="fa fa-user-shield me-2" /> Coach -{" "}
        </span>
      );
    }
  };

  const renderName = () => {
    let name = post.customer.name;
    if (post.customer.last_name !== null) {
      name += " " + post.customer.last_name;
    }
    return name;
  };

  const renderStreaks = () => {
    if (Array.isArray(post.customer.streaks)) {
      return post.customer.streaks.map((streak) => "🔥");
    }
  };

  return (
    <div className="row mb-1">
      <div className="col-6 col-md-8">
        <span className="small bold">
          {renderStaff()}
          {renderName()}
          <span
            className="ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Cada 🔥 representa una semana de Workout Planner completado"
          >
            {renderStreaks()}
          </span>
        </span>
      </div>
      <div className="col-6 col-md-4 text-end">
        <span className={`small ${isMyPost ? "" : "text-muted"}`}>
          {moment(post.createdAt).format("DD MMM YYYY HH:mm")}
        </span>
      </div>
    </div>
  );
};
export default PostMeta;
