import React, { useEffect, useState } from "react";

const TestimonialCarousel = ({ id, items, interval, automatic }) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (automatic) {
      let carouselInterval = setInterval(
        () => {
          setSelected((prevState) => {
            if (Array.isArray(items)) {
              if (prevState === items.length - 1) {
                return 0;
              }
              return prevState + 1;
            }
          });
        },
        interval ? interval : 5000
      );

      return () => {
        clearInterval(carouselInterval);
      };
    }
  }, [items, automatic]);

  const handlePrev = () => {
    if (selected === 0 && Array.isArray(items)) {
      return setSelected(items.length - 1);
    }
    setSelected(selected - 1);
  };

  const handleNext = () => {
    if (Array.isArray(items) && selected === items.length - 1) {
      return setSelected(0);
    }
    setSelected(selected + 1);
  };

  const renderItems = () => {
    if (Array.isArray(items)) {
      return items.map((item, index) => (
        <div
          key={index}
          className={`carousel-item ${selected === index ? "active" : ""}`}
        >
          {item}
        </div>
      ));
    }
  };

  const renderIndicators = () => {
    if (Array.isArray(items)) {
      return items.map((item, index) => (
        <button
          key={index}
          type="button"
          data-bs-target={`#${id}`}
          data-bs-slide-to="0"
          className={selected === index ? "active" : ""}
          aria-current="true"
          aria-label="Slide 1"
        ></button>
      ));
    }
  };
  return (
    <div id={id} className="carousel slide position-relative">
      <div className="carousel-indicators">{renderIndicators()}</div>
      <div className="carousel-inner">{renderItems()}</div>
      <button
        className="btn btn-light btn-prev shadow-sm"                
        data-bs-target={`#${id}`}
        data-bs-slide="prev"
        onClick={handlePrev}
        type="button"
      >
        <i className="fa fa-chevron-left" />
      </button>
      <button
        className="btn btn-light btn-next shadow-sm"        
        data-bs-target={`#${id}`}
        data-bs-slide="next"
        onClick={handleNext}
        type="button"
      >
        <i className="fa fa-chevron-right" />
      </button>
    </div>
  );
};

export default TestimonialCarousel;
