
import {
  SET_PAYMENTSOURCE,
  CREATE_PAYMENTSOURCE,
  PAYMENTSOURCES_RECEIVED,
  SET_PROPERTY_PAYMENTSOURCE,
} from "../types/PaymentSources";

const schema = {

}

const PaymentSourcesReducer = (state, { type, payload }) => {
  switch (type) {
    case PAYMENTSOURCES_RECEIVED:
      return { ...state, payment_sources: payload };
    case SET_PAYMENTSOURCE:
      return { ...state, payment_source: payload };
    case CREATE_PAYMENTSOURCE:
      return { ...state, payment_source: schema };
    case SET_PROPERTY_PAYMENTSOURCE: {
      const { key, value } = payload;
      const payment_source = { ...state.payment_source };
      payment_source[key] = value;
      return { ...state, payment_source };
    }
    default:
      return { ...state};
  }
};

export default PaymentSourcesReducer;
