import React, { createContext, useReducer, useContext } from "react";
import CustomerGoalsService from "../services/CustomerGoalsService";
import CustomerGoalsReducer from "../reducers/CustomerGoalsReducer";
import {
  CUSTOMERGOALS_RECEIVED,
  SET_CUSTOMERGOAL,
  CREATE_CUSTOMERGOAL,
  SET_PROPERTY_CUSTOMERGOAL,
} from "../types/customer_goals";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  customer_goals: null,
  customer_goal: null,
};

export const CustomerGoalsContext = createContext(initialState);

export const CustomerGoalsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerGoalsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCustomerGoals = () => {
    CustomerGoalsService.getCustomerGoals()
      .then((response) => {
        const { customer_goals } = response.data;
        dispatch({ type: CUSTOMERGOALS_RECEIVED, payload: customer_goals });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCustomerGoal = (customer_goal_id) => {
    CustomerGoalsService.getSingleCustomerGoal(customer_goal_id)
      .then((response) => {
        const { customer_goal } = response.data;
        dispatch({ type: SET_CUSTOMERGOAL, payload: customer_goal });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCustomerGoal = (customer_goal) => {
    dispatch({ type: SET_CUSTOMERGOAL, payload: customer_goal });
  };

  const createCustomerGoal = () => {
    dispatch({ type: CREATE_CUSTOMERGOAL });
  };

  const setPropertyCustomerGoal = (key, value) => {
    dispatch({ type: SET_PROPERTY_CUSTOMERGOAL, payload: { key, value } });
  };

  const saveCustomerGoal = (customer_goal, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = CustomerGoalsService.putCustomerGoal;
    if (isNaN(parseInt(customer_goal.customer_goal_id))) {
      service = CustomerGoalsService.postCustomerGoal;
    }
    service(customer_goal)
      .then(() => {
        success("Meta guardada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCustomerGoal = (customer_goal_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CustomerGoalsService.deleteCustomerGoal(customer_goal_id)
      .then(() => {
        success("Meta eliminada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <CustomerGoalsContext.Provider
      value={{
        ...state,
        setCustomerGoal,
        getCustomerGoals,
        saveCustomerGoal,
        deleteCustomerGoal,
        createCustomerGoal,
        getSingleCustomerGoal,
        setPropertyCustomerGoal,
      }}
    >
      {children}
    </CustomerGoalsContext.Provider>
  );
};
