import React, { createContext, useContext, useReducer } from "react";
import OrdenesReducer from "../reducers/OrdenesReducer";
import InvoicesService from "../services/InvoicesService";
import PurchasesService from "../services/PurchasesService";
import {
  HIDE_SPINNER,
  INVOICES_RECIBIDOS,
  ORDENES_RECIBIDAS,
  SET_ORDEN,
  SHOW_SPINNER,
} from "../types";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  purchases: null,
  purchase: null,
  invoices: null,
  spinner: false,
};

export const PurchasesContext = createContext(initialState);

export const PurchaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { clearModal, success } = useContext(ModalContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    alert(error);
  };

  const getPurchases = () => {
    PurchasesService.getPurchases()
      .then((res) => {
        const { purchases } = res.data;
        dispatch({ type: ORDENES_RECIBIDAS, payload: purchases });
      })
  };

  const getInvoices = () => {
    InvoicesService.getInvoices()
      .then((res) => {
        const { invoices } = res.data;
        dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
      })
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id)
      .then((res) => {
        const { purchase } = res.data;
        dispatch({ type: SET_ORDEN, payload: purchase });
      })
  };

  const clearPurchase = () => {
    dispatch({ type: SET_ORDEN, payload: null });
  }

  const cancelSubscription = (purchase_id, reason) => {
    dispatch({ type: SHOW_SPINNER });
    PurchasesService.cancelSubscription(purchase_id, reason)
      .then(() => {
        success("¡Suscripción cancelada!");
        dispatch({ type: HIDE_SPINNER });
        navigate("/mytbm/membresias");
        getPurchases();
        clearModal();
      })
      .catch(handleError);
  };

  const redeemCancelOffer = (purchase_id, cancel_reason, callback) => {
    dispatch({ type: SHOW_SPINNER });
    PurchasesService.redeemCancelOffer(purchase_id, cancel_reason)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const data = res.data;
        if (data && data !== null) {
          const { links } = data;
          if (Array.isArray(links)) {
            const approve_link = links.find((link) => link.rel === "approve");
            if (approve_link && approve_link !== null) {
              window.open(approve_link.href, "_blank");
            }
          }
        } else {
          navigate("/mytbm/membresias");
          success("¡Oferta canjeada!");
          getPurchases();
          clearModal();
        }
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch(handleError);
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getInvoices,
        getPurchase,
        getPurchases,
        clearPurchase,
        redeemCancelOffer,
        cancelSubscription,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
