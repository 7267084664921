import {
  SET_NUTRITIONTIPS,
  CREATE_NUTRITIONTIPS,
  NUTRITIONTIPSS_RECEIVED,
  SET_PROPERTY_NUTRITIONTIPS,
} from "../types/nutrition_tips";

const schema = {};

const nutrition_tipsReducer = (state, { type, payload }) => {
  switch (type) {
    case NUTRITIONTIPSS_RECEIVED:
      return { ...state, nutrition_tips: payload };
    case SET_NUTRITIONTIPS:
      return { ...state, nutrition_tip: payload };
    case CREATE_NUTRITIONTIPS:
      return { ...state, nutrition_tip: schema };
    case SET_PROPERTY_NUTRITIONTIPS: {
      const { key, value } = payload;
      const nutrition_tip = { ...state.nutrition_tip };
      nutrition_tip[key] = value;
      return { ...state, nutrition_tip };
    }
    default:
      return { ...state };
  }
};

export default nutrition_tipsReducer;
