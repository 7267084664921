import {
  AGREGAR_FAVORITO,
  APPEND_VIDEOS,
  CLEAR_RELOAD,
  CREATE_VIDEO,
  ELIMINAR_FAVORITO,
  FAVORITOS_RECIBIDOS,
  PROGRAMS_RECIBIDOS,
  PURCHASE_NEEDED,
  SET_COMPLETED,
  SET_NONCE,
  SET_PROPIEDAD_VIDEO,
  SINGLE_VIDEO_RECIBIDO,
  TAGS_RECIBIDOS,
  VIDEOS_RECIBIDOS,
} from "../types/videos";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";
import { SET_MAX } from "../types/videos";
const schema = {
  video_id: "nuevo",
  description: "",
  name: "",
  restricted: "",
  thumbnail: "",
  url: "",
  url_preview: "",
  instructor_id: "",
  video_type_id: "",
  video_type: "",
};

const VideosReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_MAX: {
      return { ...state, max: payload };
    }
    case SHOW_SPINNER: {
      return { ...state, spinner: true };
    }
    case HIDE_SPINNER: {
      return { ...state, spinner: false };
    }
    case CREATE_VIDEO: {
      return { ...state, video: schema };
    }
    case FAVORITOS_RECIBIDOS:
      return { ...state, favoritos: payload };
    case APPEND_VIDEOS: {
      let videos = state.videos;
      if (videos === null) videos = payload;
      else videos = [...videos, ...payload];
      return { ...state, videos };
    }
    case VIDEOS_RECIBIDOS: {
      const { videos, nonce } = payload;
      if (nonce === state.nonce) {
        return {
          ...state,
          videos,
          allVideos: videos,
          purchase_needed: false,
        };
      }
      return { ...state };
    }
    case SINGLE_VIDEO_RECIBIDO:
      return { ...state, video: payload };
    case AGREGAR_FAVORITO: {
      let videos = state.videos;
      if (Array.isArray(videos)) {
        videos = [...videos];
        const index = videos.findIndex(
          (video) => parseInt(video.video_id) === parseInt(payload)
        );
        if (index !== -1) {
          videos[index].favorites.push({ favorite: true });
        }
      }
      let favoritos = state.favoritos;
      if (Array.isArray(favoritos)) {
        favoritos = [...favoritos];
        const indexFavoritos = favoritos.findIndex(
          (video) => video.video_id === payload
        );
        if (indexFavoritos !== -1) {
          favoritos[indexFavoritos].favorite = 1;
        }
      }
      return { ...state, videos, favoritos, reloadVideos: true };
    }
    case ELIMINAR_FAVORITO: {
      let videos = state.videos;
      if (Array.isArray(videos)) {
        videos = [...videos];
        const index = videos.findIndex((video) => video.video_id === payload);
        if (index !== -1) {
          videos[index].favorite = 0;
        }
      }
      let favoritos = state.favoritos;
      if (Array.isArray(favoritos)) {
        favoritos = [...favoritos];
        const indexFavoritos = favoritos.findIndex(
          (video) => video.video_id === payload
        );
        if (indexFavoritos !== -1) {
          favoritos[indexFavoritos].favorite = 0;
        }
      }
      return { ...state, videos, favoritos, reloadVideos: true };
    }
    case SET_PROPIEDAD_VIDEO: {
      const { key, value } = payload;
      const video = { ...state.video };
      video[key] = value;
      return { ...state, video };
    }
    case PURCHASE_NEEDED:
      return { ...state, purchase_needed: true };
    case TAGS_RECIBIDOS:
      return { ...state, tags: payload };
    case PROGRAMS_RECIBIDOS:
      return { ...state, programs: payload };
    case SET_COMPLETED: {
      return { ...state, completed: payload };
    }
    case CLEAR_RELOAD:
      return { ...state, reloadVideos: false };
    case SET_NONCE:
      return { ...state, nonce: payload };
    default:
      return { ...state };
  }
};

export default VideosReducer;
