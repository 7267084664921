import React, { useRef, useState, useEffect, useContext } from "react";
import PostList from "./PostList";
import PostInput from "./PostInput";
import { ModalContext } from "../../context/ModalContext";
import ReportPost from "./ReportPost";
import { PostsContext } from "../../context/PostsContext";
import { AuthContext } from "../../context/AuthContext";

const Posts = ({
  id,
  max,
  posts,
  title,
  video_id,
  hideInput,
  hideVideo,
  fullHeight,
  setCustomer,
  handleVideo,
  hideReplies,
  handleThread,
  inputCallback,
}) => {
  const refContainer = useRef(null);

  const [page, setPage] = useState(1);
  const [replyTo, setReplyTo] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const { user } = useContext(AuthContext);
  const { reportPost } = useContext(PostsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    handleScrollBottom();
    window.onscroll = handleScroll;
    return () => {
      window.onscroll = () => {};
    };
  }, [posts]);

  const handleReport = (post) => {
    modalComponent(
      "Reportar Comentario",
      <ReportPost
        handleCancel={clearModal}
        handleReport={(comment) =>
          reportPost(post.post_id, user.customer_id, comment)
        }
      />
    );
  };

  const handleScrollBottom = () => {
    const container = refContainer.current;
    if (container && container !== null) {
      const top = firstLoad
        ? container.offsetHeight * 10
        : container.offsetHeight * posts.length * 2;
      setTimeout(() => {
        refContainer.current?.scrollTo({
          top,
        });
      }, 500);
    }
    if (firstLoad) setFirstLoad(false);
  };

  const handleScroll = () => {
    const container = refContainer.current;
    const scrollTop = container.scrollTop;
    if (scrollTop === 0 && Array.isArray(posts)) {
      if (posts.length < max) {
        setPage(page + 1);
      }
    }
  };

  return (
    <div id={id} className="position-relative">
      <h2 className="mb-2">{title}</h2>
      <div
        id="posts-content"
        ref={refContainer}
        onScroll={handleScroll}
        style={{ height: fullHeight ? "70vh" : "auto" }}
        className="pb-5 mb-5"
      >
        <PostList
          posts={posts}
          hideVideo={hideVideo}
          setReplyTo={setReplyTo}
          setCustomer={setCustomer}
          handleVideo={handleVideo}
          hideReplies={hideReplies}
          handleReport={handleReport}
          handleThread={handleThread}
        />
      </div>
      {!hideInput && (
        <PostInput
          replyTo={replyTo}
          video_id={video_id}
          inputCallback={inputCallback}
          clearReplyTo={() => setReplyTo(null)}
        />
      )}
    </div>
  );
};

export default Posts;
