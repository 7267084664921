import api from "./api";

const route = "/customer_goals";

export default {
  getCustomerGoals: () => api.get(route),
  getSingleCustomerGoal: (customer_goal_id) =>
    api.get(`${route}/${customer_goal_id}`),
  postCustomerGoal: (customerGoal) => api.post(route, { ...customerGoal }),
  putCustomerGoal: (customerGoal) => api.put(route, { ...customerGoal }),
  deleteCustomerGoal: (CustomerGoal) => api.delete(`${route}/${CustomerGoal}`),
};
